import { ref_Media, ref_MediaId } from "./ref_Media.bin";
import { ref_Periodicity, ref_PeriodicityId } from "./ref_Periodicity.bin";
import { Referentials, ReferentialsId } from "./Referentials.bin";
import { rid } from "./CommonTypes.bin";

export type ref_SupportsId = rid;

export class ref_Supports extends Referentials {
	"@rid"?: ref_SupportsId;
	Covers: Record<string, unknown>;
	Description: string;
	End: Date;
	Logo: string;
	Medias: ref_MediaId[];
	Name: string;
	Periodicity: ref_PeriodicityId;
	Start: Date;
	Website: string;
	ModelProperties?: Record<string, unknown>;

}