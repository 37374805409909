import * as React from "react";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { initMessageAgencyOptions } from "../../../../redux/messageEditorSlice";
import { GetEditorState, RootState, SetModel, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { initCampaignAgencyOptions } from "../../../../redux/campaignEditorSlice";


type MandataireEditorArgs = { model: "ref_Messages" | "ref_Campaigns" }

export function MandataireEditor({ model }: MandataireEditorArgs) {

    const dispatch = useDispatch();
    const Agency_Res = useSelector((root: RootState) => GetEditorState(model, root).get()?.Agency_Res);
    const options = useSelector((root: RootState) => GetEditorState(model, root).agencyOptions);
    const lockNext = useSelector((root: RootState) => GetEditorState(model, root).lockNext);

    React.useEffect(() => {
        if (options === undefined)
            dispatch(model == "ref_Campaigns" ? initCampaignAgencyOptions(undefined) : initMessageAgencyOptions(undefined));
    }, [options])

    const getElement = options => options?.find(o => o["@rid"] == Agency_Res);

    return <FavoriteVertexAutoComplete
            key={`MandataireEditor-${GetHashCode({ options, Agency_Res })}`}
            disableClearable
            disabled={lockNext}
            loading={lockNext}
            // enabledOnlyLoading={isLoading}
            label={TradProp("Agency_Res", ref_Messages)}
            options={options ?? []}
            defaultValue={getElement}
            onChange={async value => {
                if (Agency_Res != value["@rid"]) {
                    const data = GetEditorState(model, store.getState()).get();
                    data.Agency_Res = value["@rid"];
                    SetModel(model, data);
                }
                return value;
            }} />

}