
import { eIndicateurType, Indicateur, IndicateurInfo } from 'adwone-engine/index.bin';
import { EventEmitter } from 'events';
import { ToEKPIType } from 'hub-lib/models/KPIsManager.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { GetSubElement, hasOwnProperty, removeDiacritics } from 'hub-lib/tools.bin';
import { DateNoZone } from 'tools-lib';

export function IsDebugMode() {
    return localStorage.getItem("devMode") === "1";
}

export enum eRowEvent {
    sourceChanged = "sourceChanged",
    rowsAdded = "rowsAdded",
    loading = "loading"
}

export enum ePropertyEvent {
    error = "error",
}

class RowEventEmitter extends EventEmitter {
    emit(event: eRowEvent, ...args: any[]): boolean {
        return super.emit(event, ...args);
    }

    addListener(event: eRowEvent, listener: (...args: any[]) => void): any {
        return super.addListener(event, listener);
    }
}

class PropertyEventEmitter extends EventEmitter {
    emit(event: ePropertyEvent, ...args: any[]): boolean {
        return super.emit(event, ...args);
    }

    addListener(event: ePropertyEvent, listener: (...args: any[]) => void): any {
        return super.addListener(event, listener);
    }
}

export enum eColumnEvent {
    sourceChanged = "sourceChanged",
    loading = "loading"
}

class ColumnEventEmitter extends EventEmitter {
    emit(event: eColumnEvent, ...args: any[]): boolean {
        return super.emit(event, ...args);
    }

    addListener(event: eColumnEvent, listener: (...args: any[]) => void): any {
        return super.addListener(event, listener);
    }
}

export enum eSelectionEvent {
    selectionChanged = "selectionChanged"
}

class SelectionEventEmitter extends EventEmitter {
    emit(event: eSelectionEvent, ...args: any[]): boolean {
        return super.emit(event, ...args);
    }

    addListener(event: eSelectionEvent, listener: (...args: any[]) => void): any {
        return super.addListener(event, listener);
    }
}

export enum eComputeEvent {
    forceUpdate = "forceUpdate",
    reRender = "reRender"
}

class ComputeEventEmitter extends EventEmitter {
    emit(event: eComputeEvent, ...args: any[]): boolean {
        return super.emit(event, ...args);
    }

    addListener(event: eComputeEvent, listener: (...args: any[]) => void): any {
        return super.addListener(event, listener);
    }

    constructor() {
        super();
        this.setMaxListeners(100);
    }
}

export interface IItemRow<TRowData> {
    dataItem: TRowData;
    [prop: string]: any;
}

export class AdwRow<TRowData> implements IItemRow<TRowData> {

    id: any;
    selected: boolean;
    expanded: boolean;
    Selectable: boolean;
    dataItem: TRowData;
    [prop: number]: any;
    [prop: string]: any;

    Children: AdwRow<TRowData>[];

    event: ComputeEventEmitter;

    constructor() {
        this.Children = [];
        this.event = new ComputeEventEmitter();
    }
}

export class ADWColumn<TRowData> {

    getKPIValue: (row: TRowData) => Promise<any> = (rowData) =>
        Promise.resolve(rowData).then(e => {
            if (this.isKPI)
                e = (e as any).KPIs;

            let value: any = null;
            const indicateur = this.baseColumn;
            if (indicateur?.type == eIndicateurType.info) {
                const priorityField = (indicateur.options as IndicateurInfo['options'])?.priorityToField;
                if (priorityField) {
                    value = GetSubElement(e, priorityField);
                }
            }

            if (!value)
                value = GetSubElement(e, this.bindingPath?.toString());

            if (this.dataType == ePropType.Date && typeof value == 'string')
                value = DateNoZone(value);
            return value;
        })

    getValue: (row: TRowData) => any | Promise<any> = (rowData: TRowData) =>
        Promise.resolve(rowData).then(e => {
            if (this.isKPI)
                e = (e as any).KPIs;

            let value: any = null;
            const indicateur = this.baseColumn;
            if (indicateur?.type == eIndicateurType.info) {
                const priorityField = (indicateur.options as IndicateurInfo['options'])?.priorityToField;
                if (priorityField)
                    value = GetSubElement(e, priorityField);
            }

            if (!value)
                value = GetSubElement(e, this.bindingPath?.toString());

            if (this.dataType == ePropType.Date && typeof value == 'string')
                value = DateNoZone(value);
            return value;
        })

    autoTooltip: boolean = true;
    footerCellValue: (rows: TRowData[], value: number, currency?: string) => any = null;
    title: string;
    bindingPath: ((keyof TRowData) & string) | string;
    dataType: ePropType;
    noTemplate: boolean;
    editable: boolean;
    indexOrder: number = 0;
    width?: number | string;
    frozen?: 'left' | 'right';
    isKPI: boolean;
    valueIsArray?: boolean;
    options: any;
    baseColumn: Indicateur;

    _isEditable: () => boolean;

    cell: {
        isEditable?: (row: AdwRow<TRowData>) => boolean;
    } = {};

    field: string;
    indicateurHash: string;

    IsEditable() {
        return this._isEditable ? this._isEditable() : this.editable;
    }

    /**
     * Override cell content
     * cellValue, resultat du binding
     */
    cellValue: (cellValue: any, dataItem?: AdwRow<TRowData>) => any;

    cellContent?: (cell: { Formated: string, Value: any }, row: AdwRow<TRowData>) => any;

    onEdit(newValue: any, dataItem: AdwRow<TRowData>) {
        // nothing here
    }

    constructor(title: string, bindingPath: ((keyof TRowData) & string) | string, type: ePropType = ePropType.Any, editable: boolean = false) {

        this.bindingPath = bindingPath;
        this.title = title;
        this.dataType = type;
        this.editable = editable;

        const ind = new IndicateurInfo();
        ind.field = bindingPath?.toString();
        ind.name = title;
        ind.valueType = ToEKPIType(type);

        this.baseColumn = ind;
    }

    Children: ADWColumn<TRowData>[] = [];
}

export interface INode<TRowData> {
    Children?: TRowData[];
}

/**
 * Grille data générique
 */
export abstract class ADWGrid<TRowData extends { expanded?: boolean, selected?: boolean }> {

    static onErrorProperties: PropertyEventEmitter = new PropertyEventEmitter();
    onRowsChanged: RowEventEmitter = new EventEmitter();
    onColumnsChanged: ColumnEventEmitter = new EventEmitter();
    onSelectionChanged: SelectionEventEmitter = new EventEmitter();
    onColumnsReordered: EventEmitter = new EventEmitter();

    store: { [prop: string]: any[] } = {};

    abstract createData(): TRowData;

    abstract create(row: AdwRow<TRowData>);
    abstract update(row: AdwRow<TRowData>);
    abstract delete(row: AdwRow<TRowData>[]);

    /** ROWS */
    TotalRow: TRowData;
    private _rows: TRowData[] = [];
    get Rows(): TRowData[] {
        return this._rows;
    }

    set Rows(value: TRowData[]) {
        this._rows = value;
        this.onRowsChanged.emit(eRowEvent.sourceChanged, value);
    }

    protected filterColumns(value: ADWColumn<TRowData>[]) {
        return value;
    }

    /** COLUMNS */
    public _columns: ADWColumn<TRowData>[] = [];
    get Columns(): ADWColumn<TRowData>[] {
        return this._columns;
    }

    set Columns(value: ADWColumn<TRowData>[]) {
        this._columns = this.filterColumns(value);
        this.onColumnsChanged.emit(eColumnEvent.sourceChanged, value);
    }

    AddNewRow(): TRowData {
        let row: TRowData = this.createData();
        this.AddRows([row]);
        return row;
    }

    AddRows(rows: TRowData[]) {
        rows?.forEach(r => this.Rows.push(r));
        this.onRowsChanged.emit(eRowEvent.rowsAdded, rows);
    }

    /**
     * Fonction appelée dans le compute Row pour savoir si la row doit etre sélectionnée à l'affichage
     * @param row
     */
    protected isRowSelected(row: TRowData) {
        // implémentation de base, peut etre réécrit
        return false;
    }

    /**
     * Fonction appelée à la fin du compute Row
     * @param rows
     */
    protected endCompute(rows: AdwRow<TRowData>[]) {

    }

    async Compute(): Promise<{ rows: AdwRow<TRowData>[], totalRow: AdwRow<TRowData> }> {

        //let flatColumns = [...this.FlatColumns];
        let countId = 0;
        const recurse = async (rows: TRowData[]) => {
            if (!rows) return [];

            const adwRows: AdwRow<TRowData>[] = [];
            for (const row of rows) {

                const adwRow = new AdwRow<TRowData>();
                adwRow.id = `rowid-${countId++}`;
                await this.ComputeRow(adwRow, row);
                let listener = (e) => {
                    adwRow.event.emit(eComputeEvent.reRender, e)
                }
                adwRow.event.addListener(eComputeEvent.forceUpdate, listener);
                adwRows.push(adwRow);
            }

            // for (const c of this.FlatColumns) {
            //     await this.getFormatedCell(c, adwRows);
            // }

            return adwRows;
        }

        const rows = await recurse(this.Rows);
        const totalRow = this.TotalRow ? await recurse([this.TotalRow]) : null;

        if (IsDebugMode())
            console.log(rows);

        await this.endCompute(rows);
        return { rows, totalRow: totalRow?.[0] };
    }


    getFormatedCell = async (col: ADWColumn<TRowData>, rows: AdwRow<TRowData>[]) => {
        return undefined;
    }

    async ComputeRow(newRow: AdwRow<TRowData>, dataItem: TRowData) {
        newRow.selected = dataItem?.selected ?? this.isRowSelected(dataItem);
        newRow.expanded = dataItem?.expanded ?? false;
        newRow.dataItem = dataItem;
    }

    async ComputeCellValues(rows: AdwRow<TRowData>[]) {
        const time6311 = new Date().getTime();
        if (!rows) return;
        await Promise.all(rows.map(async row => {
            for (const c of this.FlatColumns) {
                try {
                    // Si la row a déjà une valeur pour cette colonne, on ne la recalcule pas
                    const bindingPath = c.bindingPath as string;
                    if (hasOwnProperty(row, bindingPath))
                        continue;

                    // Sinon on calcule la valeur qu'on stocke dans la row directement
                    const value = await c.getValue(row.dataItem);
                    row[bindingPath] = value;

                    // Si la colonne est un KPI et qu'on a déjà calculé la valeur, on ne la recalcule pas
                    const bindingPathCellValue = `${bindingPath}_cellValue`;
                    if (row.hasOwnProperty(bindingPathCellValue))
                        continue;

                    row[bindingPathCellValue] = c.isKPI ? await c.getKPIValue(row.dataItem) : value;

                    if (row[bindingPathCellValue] && c.dataType == ePropType.String && !c.cellValue)
                        row[bindingPathCellValue] = removeDiacritics(row[bindingPathCellValue]);

                    // newRow[(c.bindingPath as string) + `_formated`] = await this.getFormatedCell(c, newRow.dataItem);
                    // newRow[GetHashCode((c.bindingPath as string)) + `_formated`] = newRow[(c.bindingPath as string) + `_formated`]

                    if (!c.isKPI && c.cellValue) {
                        let resCustom = await c.cellValue(value, row);
                        if (typeof resCustom != "object" && typeof resCustom != "symbol") {
                            row[bindingPathCellValue] = resCustom;
                            if (typeof resCustom === "number") {
                                const round = 100000000;
                                row[bindingPathCellValue] = Math.round(resCustom * round) / round;
                            }
                            else if (typeof resCustom === "string") {
                                row[bindingPathCellValue] = removeDiacritics(resCustom);
                            }
                        }

                        if (Array.isArray(resCustom)) {
                            row[`${c.bindingPath}_cellValue_array`] = resCustom?.toString();
                        }
                    } else {
                        //ConsoleDebug(`no resCustom`, newRow[cellvalKey])
                    }
                } catch (error) {
                    row[c.bindingPath as string] = "error";
                    console.log(error);
                }
            }
        }));
        const _time6311 = new Date().getTime();
        console.log(`[ComputeCellValues] Elapsed ${_time6311 - time6311}ms`);
    }

    async GetStoredData(row: AdwRow<TRowData>, bindingPath: string) {
        return this.store[bindingPath];
    }

    FormaterStoredData(data: any): string {
        return data?.toString();
    }

    private get FlatColumns(): ADWColumn<TRowData>[] {

        let recurse = (cols: ADWColumn<TRowData>[]) => {
            if (!cols) return [];
            let copy = [...cols];
            cols.map(c => c.Children).forEach(c => copy.push(...recurse(c)));
            return copy;
        }

        return recurse(this.Columns);
    }

    onChange = (row: AdwRow<TRowData>, field: string, value?: any) => {
        // nothing here
        // can be overriden
    }

    onReorder(fields: string[]) {
        this.onColumnsReordered.emit("reorder", fields);
    }
}
