import * as React from 'react'
import { ACrossedTable, customWidths, TProps } from './CrossedTable.bin';

import { TreeList, TreeListTextFilter, filterBy, TreeListColumnProps, TreeListCell, TreeListHeaderCellProps, TreeListTextFilterProps } from '@progress/kendo-react-treelist';
import { Trad, TradProp } from 'trad-lib';
import { duplicate, propertyOf, toArray, Typed } from 'hub-lib/tools.bin';
import { eKPIType, IsNumber } from 'hub-lib/models/KPIsManager.bin';
import { Column, Row, Table } from 'adwone-engine/types.bin';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { mapRecurse, recurse } from 'tools-lib';
import { CellProps, ColumnBaseProps, TreeColumnBaseProps } from '@progress/kendo-react-data-tools';
import { SelectionComponent } from './SelectionComponent.bin';
import ReactDOM from 'react-dom';
import { EditCell } from '../Gantt/CustomGantt.bin';
import { ADWProperty } from 'hub-lib/types';
import { TooltipManager } from '../CustomTooltip';
import { store } from '../../redux/store';
import { selectItems } from '../../redux/gridSlice';
import { RowSize, styleGridContainer } from '../../styles/theme';
import { ConsoleDebug, PrintMesureTime, StartMeasureTime } from '../../utils/localstorage.bin';
import { FooterCellTotalElement } from '../VertexGrid/Generic/AdwTelerikGrid.bin';
import { Indicateur, IndicateurToString } from 'adwone-engine/index.bin';
import { removeSpaces } from '../VertexGrid/Generic/ModeleGrid/LightCell';
import { clear } from 'console';

let valueSuffix = "_value";

type BaseColumnProps = ColumnBaseProps;

let setLeftParent = (leftValue: number, nopadding: boolean = false) => (r: HTMLElement) => {
    if (r?.parentElement?.style) {
        if (nopadding)
            r.parentElement.style.paddingLeft = `0px`;
        r.parentElement.style.left = `${leftValue}px`;
        r.parentElement.style.zIndex = `5`
    }
}

type customCellCallBack<T> = (row: Row<T>) => { Value: any, Formated: string, AdditionalCSSClass?: string }
let customCell = (indc: Indicateur, callback: customCellCallBack<any>, id: any, type: eKPIType, tree: CrossedTableTelerikTree<any, any>, styles: React.CSSProperties = {}) => {
    return class MyCell extends React.Component<CellProps, any> {

        render() {
            if (!indc) return null;
            let className = "";
            if (IsNumber(type))
                className += " number-cell-data ";

            if (styles?.hasOwnProperty("left"))
                className += " k-grid-content-sticky ";

            const dataItem: RowWrapper<any> = this.props.dataItem;

            const cell = callback(dataItem);

            dataItem[id] = cell.Formated;
            dataItem[`${id}${valueSuffix}`] = cell.Value;

            if (dataItem.baseRow) {
                (dataItem.baseRow as any)[id] = cell.Formated;
                (dataItem.baseRow as any)[`${id}${valueSuffix}`] = cell.Value;
            }

            const indicClassName = ` column_${indc?.field} `
            className += indicClassName;


            const cellText = dataItem[id];

            let valueSpan = toArray(cell.Value).map(v => ({ value: v, formated: cellText }));
            if (Array.isArray(cell.Value)) {
                const strs = cellText?.split?.("|");
                if (strs?.length == cell.Value.length) {
                    valueSpan = cell.Value.map((v, i) => ({ value: v, formated: strs[i] }));
                }
            }


            return (
                <td
                    style={styles}
                    className={className}
                    onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: cellText })}
                    onClick={(e) => {
                        tree?.onRowClicked(dataItem);
                    }}>
                    {valueSpan.map((v, i) =>
                        <span key={`${id}_${v}_${i}`} className={`crossedtable-cell ${indicClassName} ValueCell_${removeSpaces(v.value)}`}>{v.formated}</span>)}
                </td>
            );
        }
    }
}

let customPivotCell = (indc: Indicateur, callback: customCellCallBack<any>, id: any, type: eKPIType, tree: CrossedTableTelerikTree<any, any>, styles: React.CSSProperties = {}) => {
    return class MyCell extends React.Component<CellProps, any> {

        render() {


            if (!indc) return null;
            let className = "";
            if (IsNumber(type))
                className += " number-cell-data ";

            if (styles?.hasOwnProperty("left"))
                className += " k-grid-content-sticky ";

            const dataItem: RowWrapper<any> = this.props.dataItem;

            const cell = callback(dataItem);

            dataItem[id] = cell.Formated;
            dataItem[`${id}${valueSuffix}`] = cell.Value;

            if (dataItem.baseRow) {
                (dataItem.baseRow as any)[id] = cell.Formated;
                (dataItem.baseRow as any)[`${id}${valueSuffix}`] = cell.Value;
            }

            const indicClassName = ` column_${indc?.field} `
            className += indicClassName;
            className += ` ${cell.AdditionalCSSClass}`;


            const cellText = dataItem[id];

            let valueSpan = toArray(cell.Value).map(v => ({ value: v, formated: cellText }));
            if (Array.isArray(cell.Value)) {
                const strs = cellText?.split?.("|");
                if (strs?.length == cell.Value.length) {
                    valueSpan = cell.Value.map((v, i) => ({ value: v, formated: strs[i] }));
                }
            }

            return (
                <td
                    style={styles}
                    className={className}
                    onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: cellText })}
                    onClick={(e) => {
                        tree?.onRowClicked(dataItem);
                    }}>
                    {valueSpan.map((v, i) =>
                        <span key={`${id}_${v}_${i}`} className={`crossedtable-cell ${indicClassName} ValueCell_${removeSpaces(v.value)}`}>{v.formated}</span>)}
                </td>
            );
        }
    }
}

export let minFrozenCell = 200;
export let minIndic = 150;
export let selectionCell = 31;
export let editCell = RowSize;
export let editColumn = editCell * 2;

type RowExtended<T> = {
    id: any;
    baseRow: Row<T>;
    level: number;
    isSelected?: boolean;
    [prop: string]: any;
}
export type RowWrapper<T> = Row<T> & RowExtended<T>;

type Editable<T> = {
    editable?: boolean;
    onEdit?: (d: T) => void;
    onCopy?: (d: T) => void;
    isEditable?: (d: T) => boolean;
}

class CTProps<TData> extends TProps<BaseColumnProps, TData> {
    frozenPosition?: number;
    customMessageHeight?: number | string;
    selectable?: boolean;
    // onSelectionChange?: (items: IItemRow<ref_Messages>[]) => any;
    expandAll?: boolean;
}

// type TCT<T> = T & (Editable<RowWrapper> | NotEditable);
type TCT<T, TData> = T & Editable<RowWrapper<TData>>;
export class CrossedTableTelerikTree<T, TData> extends ACrossedTable<TCT<CTProps<TData>, TData> & T, BaseColumnProps, TData> {

    tree: TreeList;

    constructor(props: TCT<T, TData> & T) {
        super(props);
    }

    onRowClicked(dataItem: RowWrapper<TData>) {
        // Can be override

        if (!this.props.selectable)
            return;

        let setSelection = (rows: RowWrapper<TData>[], isSelected: boolean) => {
            rows?.forEach(row => {
                if (!row) return;
                row.isSelected = isSelected;
                if (row.baseRow)
                    (row.baseRow as any).isSelected = isSelected;
                setSelection(row.Children as RowWrapper<TData>[], isSelected);
            });
        }

        setSelection(this.getComponentData(), false);
        setSelection([dataItem], true);

        dataItem.isSelected = true;
        if (dataItem.baseRow)
            (dataItem.baseRow as any).isSelected = true;

        this.onSelectionChange();
    }

    AddEditColumn() {

        const timeAddEditColumn = StartMeasureTime();

        const filterrow = document.getElementsByClassName('k-filter-row')[0];
        const table = this.getTableComponent();
        const colGrooup = table?.children?.[0];

        const comp = this.getTableComponent() as any;
        const exist = comp.addEdit || colGrooup?.lastElementChild?.id === 'edit_col';

        if (!colGrooup.childElementCount)
            return;

        const thead = table.children[1];
        const tbody = table.children[2];

        const col = document.createElement('col');
        col.id = "edit_col";
        col.style.width = `${editColumn}px`;

        if (exist)
            colGrooup.removeChild(colGrooup.lastChild)
        colGrooup.appendChild(col);

        const header = document.createElement('th') as any;
        const trHeaders = thead.children[0];
        header.colSpan = 1;
        header.rowSpan = 1;
        header.className = 'edit-column-cell k-header k-grid-header-sticky';
        header.style.right = '0px';
        header.style.top = '0px';

        const filtercell = document.createElement('th') as any;
        filtercell.style.right = '0px';
        if (filterrow) {
            let top = (filterrow.children[filterrow.childElementCount - 1] as any).style.top
            filtercell.style.top = top;
            filtercell.style.zIndex = 5;
            if (exist)
                filterrow.removeChild(filterrow.lastChild)
            filterrow.appendChild(filtercell);
        }

        /** Gantt only */
        this.drawHeader(trHeaders);

        if (exist)
            trHeaders.removeChild(trHeaders.lastChild)
        trHeaders.appendChild(header);

        Array.from(tbody.children).forEach((tr, i) => {
            const className = 'edit-column-cell k-grid-content-sticky k-text-nowrap';

            // optimisation
            if (exist && tr.lastElementChild.className == className)
                return;

            const td = document.createElement('td') as any;

            td.className = className;
            td.style.right = '0px';

            /** Gantt only */
            this.drawTr(tr);

            const newValueFlag = `editable-${this.props.editable === true}-index-${i}`;
            (td as HTMLTableCellElement).setAttribute("flagEditCell", newValueFlag);

            tr.appendChild(td);
            ReactDOM.render(
                <EditCell
                    dataItem={this.getFlatElementIndex(i)}
                    onEdit={this.props.onEdit}
                    onCopy={this.props.onCopy}
                    isEditable={this.props.editable && this.props.isEditable} />, td);

        });

        comp.addEdit = true;
        PrintMesureTime(timeAddEditColumn, "AddEditColumn");
    }

    getComponent() {
        return this.tree;
    }

    getTableComponent() {
        return document.getElementsByClassName('treelist_telerik')[0].children[0];
    }

    getFlatElementIndex(n: number, rows?: RowWrapper<TData>[]) {
        const data = rows ?? this.tree.props.data as RowWrapper<TData>[];
        let flat: RowWrapper<TData>[] = [];
        const recurseFunc = (d: RowWrapper<TData>[]) => {
            d?.forEach(c => {
                if (!c) return;
                flat.push(c);
                if ((c.baseRow as any).expanded)
                    recurseFunc((c as any).Children);
            })
        }
        recurseFunc(data);
        return flat[n];
    }

    drawTr = (tr: Element) => {
        ConsoleDebug('drawTr')
    }

    drawHeader = (tr: Element) => {
        ConsoleDebug('drawHeader')
    }

    /**
     * Hierarchical columns
     * @param id
     * @param header
     * @param children
     */
    createColumnParent = (id: any, header: string, children: JSX.Element[]) => {
        return {
            id,
            field: header,
            width: minFrozenCell,
            children,
            expandable: true
        }
    }

    /**
     * KPIs columns
     * @param id
     * @param header
     * @param width
     * @param calculateCellValue
     * @param type
     */
    createColumnIndicateur = (indc: Indicateur, nbVentils: number, index: number, calculateCellValue: (row: Row<TData>) => { Formated: string, Value: any }, type?: eKPIType) => {
        const id = `col_${indc.field}_${index}_$field`;
        const { frozenPosition, selectable } = this.props;
        const frozen = index < frozenPosition;

        let left = undefined;
        if (frozen) {
            left = 0;
            const frozenVentilations = this.state.crossedTable?.rowventils ?? [];
            const frozenIndicateurs = (this.state.crossedTable?.selectedIndicateurs ?? []).slice(0, index);;

            frozenVentilations?.forEach((v, i) => {
                const frozenVentilId = `frozen-${i}`
                const custom = Number(customWidths?.[frozenVentilId]?.toString()?.replace("px", ""));
                // console.log(`[createColumnIndicateur] frozen custom`, custom, frozenVentilId, customWidths?.[frozenVentilId], customWidths)
                left += !isNaN(custom) ? custom : minFrozenCell;
            })

            frozenIndicateurs?.forEach((ind, idx) => {
                const idFroz = `col_${ind.field}_${idx}_$field`;
                const custom = Number(customWidths?.[idFroz]?.toString()?.replace("px", ""));
                // console.log(`[search]`, idFroz, custom, customWidths)
                left += !isNaN(custom) ? custom : minIndic;
            })

            //left += (index * minIndic + (selectable ? selectionCell : 0));
            left += (selectable ? selectionCell : 0);
            // console.log(`[createColumnIndicateur] frozen`, indc.name, left)
        }

        const header = indc.name;
        return Typed<TreeColumnBaseProps & { frozen: boolean }>({
            className: `${frozen ? "k-grid-content-sticky" : ""} k-text-wrap`,
            field: id,
            title: Trad(indc.name),
            width: minIndic,
            frozen,
            cell: customCell(indc, calculateCellValue, id, type, this, frozen ? { left } : {}),
            headerCell: (props: TreeListHeaderCellProps) => {
                return <div
                    {...(frozen ? { ref: setLeftParent(left) } : {})}
                    style={{
                        maxWidth: minIndic - 9,
                        overflow: "hidden",
                        display: "flex",
                        height: RowSize,
                        alignItems: "center"
                    }}
                    onMouseOver={e => TooltipManager.Push({ target: e.target, text: Trad(header) })}>
                    <span className="header-indic">{Trad(header)}</span>
                </div>
            }
        });
    }

    // Recursive function to create columns for each selected columns
    // If selected Kpis = Brut U + Brut %Vertical
    // If selected Columns = Media + Support
    // Then final columns will look like
    // ------------------------------------------------------------------------------
    // | Media > Support                                                            |
    // ------------------------------------------------------------------------------
    // | Presse                                           | Digital                 |
    // ------------------------------------------------------------------------------
    // | Elle                   | L'EQUIPE                | Siteinternet.com        |
    // ------------------------------------------------------------------------------
    // | Brut U | Brut %Vertical| Brut U | Brut %Vertical | Brut U | Brut %Vertical |
    // ------------------------------------------------------------------------------
    createPivotColumnIndicateur = (column: Column, currentIndicateur: string, isGrandTotalCol: boolean): BaseColumnProps => {

        // Field is combination of aggregated column labels and selected KPIs
        let field = currentIndicateur;
        const id = `col_${column?.Indicateur?.field}_${column.Cell?.Value}_${field}_$field`;

        if (!column.Children || column.Children.length == 0) {
            field += IndicateurToString(column.Indicateur);
        } else
            field += (column.Indicateur ? `{${column.Cell?.Value}}` : "");

        if (column?.Indicateur?.optionsBase?.direction)
            field = `{${column?.Indicateur?.optionsBase?.direction}}${field}`;

        console.log(`[createPivotColumnIndicateur]`, field)

        const ret = Typed<TreeColumnBaseProps>({
            id,
            className: 'k-text-wrap',
            field: field,
            width: minIndic,
            title: column.Cell?.Formated,
            // cell: new function to render pivot cell because accessor different ?
            ...(!column.Children || column.Children.length == 0 ? {
                cell: customPivotCell(column.Indicateur, (row) => {
                    const cell = row.ValuesTotal.find(v => v.IndicateurSignatureFull == field);
                    let AdditionalCSSClass = "";

                    if (row.Children && row.Children.length > 0)
                        AdditionalCSSClass = "pivot-grid-total-cell";

                    if (row.Dimension.name === "Total")
                        AdditionalCSSClass = "pivot-grid-grand-total-cell";

                    if (isGrandTotalCol)
                        AdditionalCSSClass += " pivot-grid-grand-total-col-cell";

                    //console.log(`[CELL]`, column.Indicateur?.name, column.Indicateur, cell, row.ValuesTotal, field)

                    return { Formated: cell?.Formated ?? "not found", Value: cell?.Value, AdditionalCSSClass };
                }, id, column.Indicateur?.valueType, this)

            } : {}),
            headerCell: (props) => {
                return <div
                    style={{
                        maxWidth: minIndic - 9,
                        overflow: "hidden",
                        display: "flex",
                        height: RowSize,
                        alignItems: "center",
                    }}
                    className={isGrandTotalCol ? "pivot-grid-grand-total-col-cell" : ""}
                    onMouseOver={e => TooltipManager.Push({ target: e.target, text: column.Cell?.Formated })}>
                    <span className="header-indic">{column.Cell?.Formated}</span>
                </div>
            },
        });

        // Recursion
        if (column.Children && column.Children.length > 0)
            ret.children = column.Children.map(c => this.createPivotColumnIndicateur(c, field, isGrandTotalCol))

        return ret;
    }

    textFilter = (props: TreeListTextFilterProps) => {
        let left = 0;

        for (let i = 0; this.frozenKey(i) !== props.field; i++) {
            left += minFrozenCell;
        }

        return <div ref={setLeftParent(left)}><TreeListTextFilter {...props} /></div>;
    }


    /**
     * first columns, (frozen)
     * @param rowventils
     */
    private createColumnsFrozen(rowventils: (ADWProperty | Indicateur)[]): TreeListColumnProps[] {
        let left = 0;
        if (this.props.selectable) left += selectionCell;
        // Detect if we are in a pivot configuration
        // and create a single merged frozen column
        if (this.state?.crossedTable?.colventils && this.state?.crossedTable?.colventils.length > 0) {
            const singleMergedColLabel = rowventils?.map(r => r['name'] ?? TradProp(r.field)).join(" > ");

            return [{
                cell: (props) => {
                    const isTotal = props.dataItem.Dimension.name === "Total";
                    let additionalCssClass = "";

                    if (props.dataItem.Children && props.dataItem.Children.length > 0)
                        additionalCssClass = "pivot-grid-total-cell";

                    if (isTotal)
                        additionalCssClass = "pivot-grid-grand-total-row-header";

                    return <td
                        className={`${props.className} ${additionalCssClass}`}
                        style={{ ...props.style, left: left }}
                        colSpan={props.colSpan}
                        onClick={(e) => {
                            if ((e.target as any).tagName != "SPAN")
                                this.onRowClicked(props.dataItem);
                        }}>
                        <TreeListCell {...props} style={{ display: "contents" }} />
                    </td>
                },
                headerCell: (props) => {
                    return <span style={{ fontWeight: "bold" }} className="k-link"
                        ref={setLeftParent(left)}>{singleMergedColLabel}</span>
                },
                field: this.frozenKey(0),
                title: singleMergedColLabel,
                expandable: true,
                width: minFrozenCell,
                filter: this.textFilter,
                className: 'k-grid-content-sticky k-text-wrap',
            }];
        }

        return rowventils?.map((rowCol, i) => {
            const headerLabel = rowCol['name'] ?? TradProp(rowCol.field);
            let leftValue = left + (i) * minFrozenCell;
            return {
                cell: (props) => {
                    return <td
                        className={props.className}
                        style={{ ...props.style, left: leftValue }}
                        colSpan={props.colSpan}
                        onClick={(e) => {
                            if ((e.target as any).tagName != "SPAN")
                                this.onRowClicked(props.dataItem);
                        }}>
                        <TreeListCell {...props} style={{ display: "contents" }} />
                    </td>
                },
                headerCell: (props) => {
                    return <span className="k-link"
                        ref={setLeftParent(leftValue)}>{headerLabel}</span>
                },
                field: this.frozenKey(i),
                title: headerLabel,
                expandable: true,
                width: minFrozenCell,
                filter: this.textFilter,
                className: 'k-grid-content-sticky k-text-wrap',

            };
        });
    }

    /**
     * When click on row expand
     * @param e
     */
    onExpandChange = (e: { dataItem: any }) => {
        let row: RowWrapper<TData> = e.dataItem;
        (row.baseRow as any).expanded = !(row.baseRow as any).expanded;
        this.forceUpdate();
    }

    myOrderBy(rows: Row<TData>[], prop: string, order: "asc" | "desc") {

        if (!prop || !order)
            return rows;

        if (!rows) return rows;
        rows.forEach(r => r.Children = this.myOrderBy(r.Children, prop, order));

        let field = `${prop}${valueSuffix}`;
        let ordered = [...rows].sort((a, b) => {
            let valA = (a as any)[field] ?? (a as any)[prop];
            let valB = (b as any)[field] ?? (b as any)[prop];

            if (typeof valA === "string" && typeof valB === "string")
                return valA.localeCompare(valB);

            return valA - valB;
        });

        if (order == "desc") ordered = ordered.reverse();
        return ordered
    }

    sortData(rows: Row<TData>[]) {
        this.sort?.forEach(s => rows = this.myOrderBy(rows, s.field, s.dir));
        return rows;
    }

    onSortChange = (rows: any, e: any) => {
        this.sort = e.sort
        this.forceUpdate()
    }

    /**
     * search in tab
     * @param rows
     */
    myFilterData = (rows: RowWrapper<TData>[]) => {
        let filtered: RowWrapper<TData>[] = filterBy(rows, this.filter, propertyOf<RowWrapper<TData>>("Children") as any);

        let recurseDates = (row: RowWrapper<TData>) => {
            row.Start = new Date(row.Start);
            row.End = new Date(row.End);
            row?.Children?.forEach(c => recurseDates(c as RowWrapper<TData>));
        }

        filtered?.forEach(f => recurseDates(f));
        return filtered;
    }

    protected frozenKey(nb: number) {
        return `frozen-${nb}`;
    }

    /**
     * Change Rows to RowWrappers
     * @param rows
     */
    protected AdaptRows(rows: Row<TData>[]): RowWrapper<TData>[] {
        let id = 0;
        let maxLevel = 0;
        let pivot = this.state?.crossedTable?.colventils && this.state?.crossedTable?.colventils.length > 0;
        let results = mapRecurse<Row<TData>, RowWrapper<TData>>(
            rows,
            propertyOf<RowWrapper<TData>>("Children") as any,
            (row, level, parent) => {

                if (level > maxLevel)
                    maxLevel = level;

                if (pivot)
                    level = 1;

                let key = this.frozenKey(level - 1)

                let value = row.Formated ?? row.Value;
                (row as any)[key] = value;

                let newRow: RowWrapper<TData> = {
                    id: id++,
                    ...new Row(),
                    ...row,
                    baseRow: row,
                    level,
                    [key]: value // -1 because of total first row
                };

                if (!pivot) {
                    parent && Object.keys(parent)
                        .filter(k => k.startsWith(`frozen-`))
                        .forEach(k => {
                            newRow[k] = parent[k];
                            (newRow.baseRow as any)[k] = parent[k]
                        });
                }

                if (this.props.expandAll)
                    if (!newRow.baseRow.hasOwnProperty("expanded")) {
                        (newRow.baseRow as any).expanded = true;
                        newRow.expanded = true;
                    }

                return newRow;
            });

        if (!pivot) {
            recurse<RowWrapper<TData>>(results, propertyOf<RowWrapper<TData>>("Children") as any,
                (row, level) => {
                    while (level <= maxLevel) {
                        row[this.frozenKey(level)] = "Total";
                        level++;
                    }
                });
        }
        return results;
    }

    onFilterChange = (rows: any, e: any) => {
        this.filter = e.filter
        this.forceUpdate()
    }

    sort: any[] = [];
    filter: FilterDescriptor[] = [];

    beforeLoading = (table: Table<TData>) => {
        if (!table) return;
        const rows: (Row<TData> & { expanded: boolean })[] = table?.Rows as any ?? [];
        recurse(rows, "Children", (e, deepth) => {
            if (deepth < 2)
                e.expanded = true;
        })
    }

    getComponentData = () => {
        return this.tree.props.data;
    }

    private recurseAllSelected: (rows: RowWrapper<TData>[]) => any = (rows) => {

        let selectedItems: any[] = [];

        rows?.forEach(e => {
            if (e?.isSelected) {
                selectedItems.push(e);
            }

            selectedItems = selectedItems.concat(this.recurseAllSelected(e?.Children as any));
        });

        return selectedItems;
    }

    onSelectionChange = () => {
        const selectedItems = this.recurseAllSelected(this.getComponentData());
        store.dispatch(selectItems(selectedItems));
        //this.props.onSelectionChange?.(selectedItems);
        this.forceUpdate();
    }

    createTreeList = (rows: Row<TData>[], columns: any[], rowventils: (ADWProperty | Indicateur)[]) => {
        const { editable, selectable, customMessageHeight } = this.props;
        let tableWidth = 0;
        let colFrozen: TreeListColumnProps[] = this.createColumnsFrozen(rowventils);
        let allCol: TreeListColumnProps[] = colFrozen?.concat(columns);
        if (allCol && selectable)
            allCol = [this.CreateSelectionColumn(), ...allCol];

        // re set width
        allCol?.forEach(c => {
            if (c?.field && customWidths?.[c.field])
                c.width = customWidths[c.field];
        });

        // const flatColumns = [...(colFrozen ?? [])];
        const flatColumns = [];
        recurse(allCol, "children", (c) => {
            if (!c.children?.length)
                flatColumns.push(c)
        });

        flatColumns?.forEach(c => {
            if (c.width) {
                if (typeof c.width === 'number') {
                    tableWidth += Number(c.width);
                    c.width = `${c.width}px`;

                    console.log(`[createTreeList] flatColumns number`, c.title, c.width)

                } else {
                    tableWidth += Number(c.width.replace('px', ''));

                    console.log(`[createTreeList] flatColumns px`, c.title, c.width)
                }
            }
        });

        console.log(`[createTreeList]`, colFrozen, flatColumns, tableWidth)
        let data = this.myFilterData(this.AdaptRows(this.sortData(rows)));
        if (editable)
            tableWidth += editColumn;
        const crossedTableHeight = customMessageHeight ?? styleGridContainer.messages.height
    
        return (<div>
            <TreeList key={`treelist-v-${this.state.version}`}
                onColumnResize={e => this.onResizeColumns(e.columns)}
                ref={(tree: TreeList) => {
                    if (!tree) return;
                    this.tree = tree;

                    if (this.props.editable) {
                        this.AddEditColumn();
                    }

                    this.customizeHeaderFilters();
                }}
                resizable={true}
                className='treelist_telerik'
                style={{ ...styleGridContainer.messages, overflow: 'auto', height: crossedTableHeight }}
                tableProps={{ style: { width: `${tableWidth}px`, minWidth: `${tableWidth}px` } }}
                rowHeight={RowSize}
                expandField={'expanded'}
                subItemsField={propertyOf<Row<TData>>("Children")}
                sortable={true}
                sort={this.sort}
                filter={this.filter}
                onSortChange={(e) => this.onSortChange(rows, e)}
                onFilterChange={(e) => this.onFilterChange(rows, e)}
                selectedField="isSelected"
                data={data}
                onExpandChange={this.onExpandChange}
                columns={allCol}
            />
            <FooterCellTotalElement count={rows.map(r => r.CountData ?? 0).reduceRight((acc, cur) => acc + cur, 0)} />
        </div>
        );
    }

    protected CreateSelectionColumn(): TreeListColumnProps {
        return {
            headerCell: () => <div ref={setLeftParent(0, true)} style={{ textAlign: "center" }} className="selection-header-grid">
                <SelectionComponent
                    hideTd={true}
                    dataItem={this.getComponentData()?.[0]}
                    onSelectionChange={this.onSelectionChange} /></div>,
            cell: (props) => <SelectionComponent
                dataItem={props.dataItem}
                onSelectionChange={this.onSelectionChange} />,
            field: "isSelected",
            title: " ",
            width: selectionCell,
        };
    }
}

