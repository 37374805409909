
import * as React from "react";
import { Grid } from "@material-ui/core";
import { JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory"
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { setMessage } from "../../../../redux/messageEditorSlice";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { MessageAggregator } from "hub-lib/aggregator/MessageAggregator";
import { KPIsManagerCache, PerformanceKPIs } from "hub-lib/models/KPIsManager.bin";
import { CPMFeesGridComponent } from "./CPMFeesGrid";
import { ref_KPIs } from "hub-lib/models/orientdb/ref_KPIs.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { LockedPricing } from "./LockedPricing";

export function CPMFeesEditor() {
    const discounts = useSelector((root: RootState) => root.messageEditor.get().Discounts, JSONEqualityComparer);
    const [feesType, setFeesType] = React.useState<ref_DiscountTypes>();
    const [feesClasses, setFeesClasses] = React.useState<ref_DiscountClasses[]>();
    const lnkHaskpis = useSelector((root: RootState) => root.messageEditor.kpis);
    const kpiChoicesCPM = lnkHaskpis?.filter(kpi => PerformanceKPIs.includes(kpi.Name));
    const messageKpis = useSelector((root: RootState) => root.messageEditor.data?.KPIs);
    const campaignKpis = useSelector((root: RootState) => root.messageEditor.campaign?.KPIs);
    const [objectTypePerformance, setObjectTypePerformance] = React.useState<string>();

    React.useEffect(() => {
        if (!feesType)
            (AggregatorFactory.GetInstance().Get(ref_Messages) as MessageAggregator).getDiscountTypes()
                .then(types => setFeesType(types.find(t => t.Name === "Frais")));
        if (!feesClasses && feesType)
            (AggregatorFactory.GetInstance().Get(ref_Messages) as MessageAggregator).getDiscountClasses(c => c.DiscountType == feesType["@rid"])
                .then(classes => setFeesClasses(classes))
        if (!objectTypePerformance)
            KPIsManagerCache.GetInstance(ref_Messages.name).HasPerformances()
                .then(isMessage => setObjectTypePerformance(isMessage ? ref_Messages.name : ref_Campaigns.name));
    })

    if (!feesType || !feesClasses || !kpiChoicesCPM || !objectTypePerformance)
        return <></>

    const objectKpis = objectTypePerformance == ref_Messages.name ? messageKpis : campaignKpis;

    return <Grid container className='block-container' style={{ position: 'relative' }}>
        <LockedPricing>
            <CPMFeesGridComponent
                discounts={discounts}
                classes={feesClasses}
                choices={kpiChoicesCPM}
                discountType={feesType}
                objectKpis={objectKpis}
                onChange={(discounts) => {
                    const newMessage = store.getState().messageEditor.get();
                    newMessage.Discounts = discounts;
                    store.dispatch(setMessage(newMessage))
                }} />
        </LockedPricing>
    </Grid>
}