import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { clearEmptyValues, duplicate, GetHashCode } from "hub-lib/tools.bin";
import React, { useEffect, useState } from "react";
import { TradClassName } from "trad-lib";
import { VertexAutocomplete } from "adwone-lib/index";
import { DateRangepicker } from "../../../ConfigurableComponents/DateRangepicker";
import { Element } from "../../Generic/Common.bin";
import { distinct } from "hub-lib/tools.bin";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { onChange, TreeProps } from "../../../../redux/referentialTreeSlice";

async function searchHierarchy(className : string, params?: { parents?: string[], children?: string[] }): Promise<IRid[]> {
    let res = await Client.searchVertex(className, { properties: ["@rid", "Name"], Active: true, ...params });
    return res?.data?.results;
}

export function TreeFiltersComponent(props : TreeProps) {

    const [Parents, setParents] = useState<IRid[]>(undefined);
    const [Children, setChildren] = useState<IRid[]>(undefined);

    const config = duplicate(useSelector((root: RootState) => root.referentialTree[props.filterName]));
    const dispatch = useDispatch();

    const onConfChange = (newConfig: any) => {
        dispatch(onChange({ [props.filterName]: clearEmptyValues(newConfig) }));
    }

    useEffect(() => {
        if (!Parents)
            LoadParents();
        if (!Children)
            LoadChildren();
    })

    const LoadParents = async (addedRids?: string[], changeConf : boolean = false) => {
        let params = undefined;
        if (addedRids?.length > 0)
            params = { children: addedRids };
        searchHierarchy(props.hierarchyClassName[0], params).then(res => {
            if (!params)
                setParents(res);
            else if (!config["@rid"]?.length)
                config["@rid"] = res.map(ag => ag["@rid"]);
            else
                config["@rid"] = distinct([...config["@rid"], ...res.map(ag => ag["@rid"])], (r: string) => r);
            if (changeConf)
                onConfChange(config);
        });
    }

    const LoadChildren = async (deletedRids?: string[], changeConf : boolean = false) => {
        let params = undefined;
        if (config["@rid"]?.length > 0)
            params = { parents: config["@rid"] };

        const search: Promise<IRid[]>[] = [searchHierarchy(props.hierarchyClassName[1], params)];
        if (deletedRids?.length > 0)
            search.push(searchHierarchy(props.hierarchyClassName[1], { parents: deletedRids }));
        Promise.all(search).then(res => {
            setChildren(res[0]);
            config.children = config.children?.filter(rid => res[0].find(r => r["@rid"] == rid)
                && (res.length == 1 || !res[1].find(r => r["@rid"] == rid)));
            if (changeConf)
                onConfChange(config);
        });
    }

    return (
        <>
            <Element className="adw-row">
                <DateRangepicker
                    defaultStart={config.Start ?? null}
                    defaultStop={config.End ?? null}
                    handleChangeDate={(e: { start: Date, end: Date }) => {
                        if (e.end) {
                            config.Start = e.start;
                            config.End = e.end;
                            onConfChange(config);
                        }
                    }}
                />
            </Element>
            <Element className="adw-row">
                <VertexAutocomplete
                    key={`Parents-${props.hierarchyClassName[0]}-${GetHashCode(Parents)}-${GetHashCode(config?.["@rid"])}`}
                    multiple={true}
                    options={Parents ?? []}
                    label={TradClassName(props.hierarchyClassName[0])}
                    defaultValue={(options) => config?.["@rid"]?.map(v => options.find(o => o["@rid"] == v))}
                    onChange={async (value: IRid[]) => {
                        const valueRids = value?.map(r => r["@rid"]);
                        const deletedRids = config["@rid"]?.filter(rid => !valueRids.find(vr => vr == rid));
                        config["@rid"] = valueRids;
                        LoadChildren(deletedRids, true);                        
                    }} />
            </Element>
            <Element className="adw-row">
                <VertexAutocomplete
                    key={`Children-${props.hierarchyClassName[1]}-${GetHashCode(Children)}-${GetHashCode(config?.children)}`}
                    multiple={true}
                    options={Children ?? []}
                    label={TradClassName(props.hierarchyClassName[1])}
                    defaultValue={(options) => config?.children?.map(v => options.find(o => o["@rid"] == v))}
                    onChange={async (value: IRid[]) => {
                        const valueRids = value?.map(r => r["@rid"]);
                        const addedRids = valueRids?.filter(rid => !config?.children?.find(vr => vr == rid));
                        config.children = valueRids;
                        LoadParents(addedRids, true);                      
                    }} />
            </Element>
        </>
    )
}