import { rid } from "../../models/orientdb/CommonTypes.bin";
import { User } from "../../models/orientdb/User.bin";
import { ref_AdvertiserGroupsId } from "../../models/orientdb/ref_AdvertiserGroups.bin";
import { ref_AdvertisersId } from "../../models/orientdb/ref_Advertisers.bin";
import { ref_BrandsId } from "../../models/orientdb/ref_Brands.bin";
import { ref_BroadcastAreasId } from "../../models/orientdb/ref_BroadcastAreas.bin";
import { ref_CompaniesId } from "../../models/orientdb/ref_Companies.bin";
import { ref_CurrenciesId } from "../../models/orientdb/ref_Currencies.bin";
import { ref_DepartmentsId } from "../../models/orientdb/ref_Departments.bin";
import { ref_KPIsId } from "../../models/orientdb/ref_KPIs.bin";
import { ref_MediaId } from "../../models/orientdb/ref_Media.bin";
import { ref_ProductsId } from "../../models/orientdb/ref_Products.bin";
import { ref_PropertyId } from "../../models/orientdb/ref_Property.bin";
import { ref_SupportsId } from "../../models/orientdb/ref_Supports.bin";
import { ref_Discount } from "../../models/types/vertex.bin";
import { IAdvertiserHierarchy, IAttachmentsOwner, IDateRangeModel, IMediaCompanyModel, IMediaModel, IUpsertInfo, kpis } from "../../types";
import { ref_Imports, ImportElementStatus } from "../admin/ref_Imports.bin";
import { ref_Agreements } from "./ref_Agreements.bin";
import { ref_CampaignsId } from "./ref_Campaigns.bin";
import { ref_AgreementsId } from "./ref_GlobalAgreements.bin";
import { ref_Groups } from "./ref_Groups.bin";
import { ref_Attachments } from "./ref_Attachments.bin";

export type ref_MessagesId = rid;

export enum eStatusType {
	None = "None",
	Simulated = "Simulated",
	Opted = "Opted",
	Confirmed = "Confirmed",
	Cancelled = "Cancelled"
}

export enum eStateType {
	None = "None",
	Bound = "Bound",
	Confirmed = "Confirmed",
	Billed = "Billed",
	Cancelled = "Cancelled"
}

export class NegotiatedKPIs {
	Net: number;
	Rate: number;
}

export class FactInfo {
	Diff?: boolean;
	LastUpdate: Date;
}
export class Deversement {
	Id?: string;
	FromMAP?: boolean;
	Estimate: string;
	InfoFO: FactInfo = new FactInfo();
	InfoCO: FactInfo = new FactInfo();
	Fees?: { [key: string]: Deversement };
}
export class ref_Messages implements IUpsertInfo, IDateRangeModel, IMediaModel, IMediaCompanyModel, IAdvertiserHierarchy, IAttachmentsOwner {
	"@rid"?: ref_MessagesId;
	AdvCompany_Com?: rid;
	AdvCompany_Fin?: rid;
	Agency_Res?: ref_CompaniesId;
	Agency_Fact?: ref_CompaniesId;
	AdditionalPlacement: string;
	Brand?: ref_BrandsId;
	BroadcastArea: ref_BroadcastAreasId;
	Advertiser: ref_AdvertisersId;
	Product?: ref_ProductsId;
	TechDeliveryTimeLimit: Date;
	Support: ref_SupportsId;
	Campaign: ref_CampaignsId;
	Format: ref_PropertyId;
	Media: ref_MediaId;
	Status: eStatusType = eStatusType.None;
	State: eStateType = eStateType.None;
	ConfirmationTimeLimit: Date;
	ConfirmationComments: string;
	TechDeliveryComments: string;
	CancellationTimeLimit: Date;
	AdditionalFormat: string;
	AdvertiserGroup?: ref_AdvertiserGroupsId;
	Group?: ref_Groups["@rid"];
	Currency: ref_CurrenciesId;
	ReturnedCurrency: ref_CurrenciesId;
	ReturnedCurrencyRate: number;
	CancellationComments: string;
	Placement: ref_PropertyId;
	Start: Date;
	End: Date;
	Active: boolean;
	KPIs: kpis = new kpis();
	ModelProperties: { [key: string]: any } = {};
	Discounts: ref_Discount[];
	BarterPercents: ref_Discount[];
	SmartBarter?: boolean;
	ApplyComBarter?: boolean;
	ParentId?: any;
	Created_by: User["@rid"];
	Updated_by: User["@rid"];
	Created_at?: Date;
	Updated_at?: Date;
	Version: number;
	Source: "ADWONE" | "MAP";
	GlobalAgreement?: ref_AgreementsId;
	GlobalAgreementLastUpdate?: Date;
	Agreement?: ref_AgreementsId;
	AgreementLastUpdate?: Date;
	DiscountMode?: "cascade" | "cumul" | "mixed" = "cascade";
	Deversement?: Deversement = new Deversement();
	PaidPlacement: boolean;
	IsGrossCPM: boolean;
	KpiCPM: ref_KPIsId;
	Department?: ref_DepartmentsId;
	Import?: {
		Data: { [key: string]: any };
		Id: ref_Imports["@rid"];
		Status: ImportElementStatus;
	}
	PricingLocked?: boolean;
	Invalide?: boolean;

	/**
	 * @description called in volumeUpdate / used in MessageManager to prevent further update
	 */
	upsertedAgreement?: ref_Agreements;

	Attachments?: { [key: string]: ref_Attachments } = {}; // not in DB !!!
}

export class ref_MessagesNegotiated extends ref_Messages {
	KPIs: kpis & { NetNego: number }
}
