
import { TextField } from '@material-ui/core';
import { KPIsManagerCache, lnk_HasPropertyTypeExtended, ValueChoicesTypeSort } from 'hub-lib/models/KPIsManager.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { GetHashCode, propertyOf } from 'hub-lib/tools.bin';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { Trad, TradProp, TradValue } from 'trad-lib';
import { GetEditorState, RootState, SetModelSync, store } from '../../../../redux/store';
import { AdwAutocomplete } from "adwone-lib/index";
import { changeEndValue } from './DiffusionEditor';

type ModelPropertiesEditorArgs = { property: string, label?: string, model: "ref_Messages" | "ref_Campaigns" }
export function ModelPropertiesChoicesEditor({ property, model, label }: ModelPropertiesEditorArgs) {

    const propertyValue = useSelector((root: RootState) => GetEditorState(model, root).data.ModelProperties?.[property]);
    const [valueChoices, setValueChoices] = React.useState<lnk_HasPropertyTypeExtended["ValueChoices"]>();

    React.useEffect(() => {
        if (!valueChoices) {
            Promise.resolve().then(async () => {
                const data = GetEditorState(model, store.getState()).get();
                const properties: lnk_HasPropertyTypeExtended[] = await KPIsManagerCache.GetInstance(model).GetLnkHasProperties(data.Media);

                setValueChoices(properties?.find(p => p.Id == property).ValueChoices.sort(ValueChoicesTypeSort));
            })
        }
    })

    const value = valueChoices?.find(v => v.rid == propertyValue);
    return <AdwAutocomplete
        key={`${value}-${GetHashCode(valueChoices)}`}
        loading={!valueChoices}
        disabled={!valueChoices}
        options={valueChoices ?? []}
        getOptionLabel={(v => v["@class"] ? TradValue(v["@class"], "Name", v.value) : v.value)}
        value={value}
        onChange={async (event: any, value: any) => {
            const data = GetEditorState(model, store.getState()).get();
            if (!data.ModelProperties)
                data.ModelProperties = {};
            data.ModelProperties[property] = value?.rid;
            console.log("ModelPropertiesChoicesEditor", property, value?.rid);
            if (property == "Periodicity")
                await changeEndValue(model, data);
            SetModelSync(model, data);
        }}
        renderInput={(params: any) => <TextField {...params} variant="outlined" label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.${property}` as any, ref_Messages)} />}
    />
}