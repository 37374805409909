import * as React from "react";
import { SortDescriptor } from "@progress/kendo-data-query";
import { AdwRow, TooltipManager } from "adwone-lib/index";
import Loader from "../../../layout/Loader";
import { Client } from "hub-lib/client/client.bin";
import { GetSort } from "format-lib/index.bin";
import { Notify, NotifyError } from "../../../../utils/Notify.bin";
import { Trad } from "trad-lib";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { CustomAddButtons } from '../CustomAddButtons.bin';
import { propertyOf, Typed, ClearEmptyValues, duplicate, compareObjects, serializeObject, clone, SanitizeDuplication, distinct, GetHashCode } from "hub-lib/tools.bin";
import { CrossedTableTelerikTree, RowWrapper } from "../../../crossedTable/CrossedTableTelerikTree.bin";
import { CrossedTableConfig } from "../../../crossedTable/CrossedTableConf.bin";
import { GanttTelerik } from "../../../crossedTable/GanttTelerik.bin";
import { ACrossedTable } from "../../../crossedTable/CrossedTable.bin";
import { dicoViewModeIcons, ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import history from '../../../../utils/history'
import { IsIndicateurReturned } from "adwone-engine/index.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { lnk_ChangeRate } from "hub-lib/dto/client/lnk_ChangeRate.bin";
import { SchedulerConfigDialog } from "../../Messages/SchedulerConfig/SchedulerConfigDialog";
import { GroupComponent } from "../../../crossedTable/scheduler/GroupComponent";
import { HistoryStateArg } from "hub-lib/types";
import { recurse } from "tools-lib";
import { ToolbarSelectedItems } from "./ToolbarSelectedItems";
import { GetEditorState, getScheduler, getSchedulerSelector, getTable, RootState, SetMode, store } from "../../../../redux/store";
import { clearGridFilter, initializeSchedulerTemplate, selectItems, setData, setGridFilter, setSort } from "../../../../redux/gridSlice";
import { ConfigurationPanel } from "../../Messages/ConfigurationPanel";
import { ConfigurationPanelContainer } from "../ToolbarAdw";
import { useSelector } from "react-redux";
import { eDialogMode } from "../../../ConfigurableComponents/GenericDialog.bin";
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { GetDefaultColumnConfiguration } from "./MessagesGridCreator";
import { CustomIconButton } from "../CustomIconButton";
import CsvFile from "../../../../utils/csv-file.bin";
import { ErrorBoundary } from "../../../../ErrorBoundary.bin";
import { MessagesToolbar } from "../../Messages/MessagesToolbar";
import { disableRepeat, setMessageSync } from "../../../../redux/messageEditorSlice";
import { MenuItemFilters } from "../../Filters/MenuItemFilters";
import { TableComponent } from "../../Messages/MenuItems/TableComponent";
import { ExportConfiguration } from "../../Messages/MenuItems/ExportConfiguration";
import { getIcon } from "../../../ConfigurableComponents/IconManager.bin";
import { IconButton } from "@material-ui/core";
import { Dashboard } from "../../../Dashboard/Dashboard";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { ModeleGrid } from "./ModeleGrid";
import { DataGrid } from "./DataGrid";
import { PDFExportEvent } from "../CustomPDFExport";
import { IRid } from "hub-lib/models/IRid.bin";
import { setTable } from "../../../../redux/gridConfigurationsSlice";
import { CopyBody } from "hub-lib/models/types.bin";
import { AutoResizeUpdater } from "./AutoResizeUpdater";
import { FooterCellOptions } from "./FooterCellOptions";
import { ConsoleDebug } from "../../../../utils/localstorage.bin";
import { AggregateExport, SanitizeFilter, TableExport } from "hub-lib/models/external.bin";
import { eAction, logUserArg } from "hub-lib/models/requests.bin";
import { EngineTools } from "adwone-engine/EngineTools";
import { GetSchedulerStyledPropertiesMemo } from "../../Messages/SchedulerConfig/SchedulerModeleCreator/SchedulerModeleCreator";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory";

let timeout: any = null;
type TState<T extends IRid> = {
    //editDiffusion: boolean;
    elementEdited?: T;
    grid?: DataGrid<T>;
    sort?: SortDescriptor[];
    medias?: ref_Media[];
    selectedMedia?: ref_Media;
    importRid?: string;
    openExport: boolean;
    //template?: ref_SchedulerConfigurations;
    ridsToSelect?: T["@rid"][];
    initialized?: boolean;
}

type DataGridComponentProps<T> = {
    loadMedias?: boolean,
    genre: string,
    editProperties?: string[],
    objectType: new () => T,
    createGrid: (params) => Promise<DataGrid<T>> | DataGrid<T>,
    getDialog?: (props: DataGridComponentProps<T>, state: TState<T>, onValidate: (T, duplicateParams?: any) => void, handleClose: () => void) => JSX.Element | Element | null
}

let ctComponent: ACrossedTable<any, any, any> = null;

export function DataGridComponent<T extends IRid>(props: DataGridComponentProps<T>) {

    const { objectType, getDialog, genre, createGrid, loadMedias, editProperties } = props;
    const [refresh, setRefresh] = React.useState(false);
    const historyState = history.location.state as HistoryStateArg;
    const projectInitilized = useSelector((root: RootState) => root.project.isInitialized);
    const repeat = useSelector((root: RootState) => root.messageEditor.repeat);
    const dialogMode = useSelector((root: RootState) => GetEditorState(objectType.name, root).mode);
    const filters = duplicate(useSelector((root: RootState) => root.project.filters, compareObjects));
    const autoResize = useSelector(getSchedulerSelector(objectType.name))?.resizePeriod;
    const modeleTable: ref_TableConfigurations = clone(useSelector((root: RootState) => root.columsConfigurations.configurations?.[objectType.name]?.table, compareObjects));
    const [loading, setLoading] = React.useState(false);
    const isMounted = React.useRef(false);

    if (filters?.Start) filters.Start = new Date(filters.Start);
    if (filters?.End) filters.End = new Date(filters.End);

    const [state, _setState] = React.useState<TState<T>>({
        //editDiffusion: false,
        openExport: false,
        importRid: undefined,
        initialized: false
    });

    const { grid, importRid } = state;

    React.useEffect(() => {
        /** Init grid scheduler */
        if (!isMounted.current)
            store.dispatch(initializeSchedulerTemplate(getScheduler(objectType.name)));
        isMounted.current = true;
    });

    React.useEffect(() => {
        if (!modeleTable)
            GetDefaultColumnConfiguration(objectType.name)
                .then(conf => store.dispatch(setTable(conf)));
    });

    /**
     * Initialize (Component Did Mount)
     */
    React.useEffect(() => {
        if (!state.initialized)
            initializeState().then(newState => setState(newState));
    });

    /**
     * Trigger 1ere initialization
     */
    React.useEffect(() => {
        if (projectInitilized && state.initialized && filters)
            redraw()
    }, [state.initialized, projectInitilized]);

    /**
     * Trigger changement de configuration
     */
    React.useEffect(() => {
        if (state.initialized) onConfChange(filters);
    }, [serializeObject(filters)]);


    React.useEffect(() => {
        if (state.initialized && state.grid.props) {
            state.grid.props.configuration = { ...new ref_TableConfigurations, ...(modeleTable ?? {}) };
            refreshContent();
        }
    }, [serializeObject(modeleTable), Boolean(state?.initialized && state?.grid?.props)]);

    const setState = (newState: Partial<TState<T>>) => _setState({ ...state, ...newState });

    const onConfChange = (conf: Partial<Filter>) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // désélectionn sur changement de conf
            // todo: pour le moment le tableau n'est pas capable de comparer le selectedItems de redux avec les nouvelles instances de rows
            store.dispatch(selectItems([]));
            if (state.grid) {
                const newParams: any = ClearEmptyValues(conf);
                const vertexParams = state.grid.props.vertexParams;
                for (let [key2] of Object.entries(new Filter()))
                    delete vertexParams[key2]
                state.grid.props.vertexParams = { ...vertexParams, ...newParams };
                refreshContent(false);
            }
        }, 500)
    }

    const initializeState = async () => {
        await DiscountManager.Load();
        const newState: Partial<TState<T>> = {
            initialized: true,
            //template: SchedulerStorage.get(),
            medias: loadMedias ? await Client.searchVertexTyped<ref_Media>(ref_Media) : null
        };

        if (historyState?.type == "import")
            Object.assign(newState, Typed<Partial<TState<T>>>({
                importRid: historyState.importRid
            }));

        return newState;
    }

    const initGrid = async () => {
        const vertexParams = (historyState?.type == "import") ?
            { Source: ["ADWONE"], CacheInfos: { Key: historyState.importRid, Type: "Import" } } :
            duplicate(filters);

        const newGrid = await createGrid(vertexParams);
        return newGrid;
    }

    const redraw = async () => {
        const indicateurs = await AggregatorFactory.GetInstance().Get(objectType.name)?.Provide() ?? [];
        setState({
            grid: await initGrid(),
            sort: GetSort<T>(objectType)?.map((s) => {
                const ind = indicateurs.find(i => i.indicateur.field == s.field && !i.indicateur.options)?.indicateur;
                return ({ field: `_computed.${GetHashCode(JSON.stringify({ "field": ind.field, "type": ind.type, "valueType": ind.valueType }))}`, dir: s.dir })
            })
        })
    }

    const selectRid = (_rids: T["@rid"] | T["@rid"][]) => {
        if (!Array.isArray(_rids))
            _rids = [_rids];

        if (modeleTable.ViewMode === "Table") {
            _rids.forEach(rid => grid.elementsToSelect.push(rid));
        }
        else {
            setState({ ridsToSelect: _rids });
        }
    }

    const onValidateDialog = async (data: T, duplicateParams?: any) => {
        // let start = m?.Start?.toLocaleDateString(GetCurrentLocale())
        // let end = m?.End?.toLocaleDateString(GetCurrentLocale())
        // let support = await getName(ref_Supports.name, m.Support)
        // let format = await getName(ref_Property.name, m.Format)
        // let placement = m.Placement ? await getName(ref_Property.name, m.Placement) : ""

        Notify(Trad(`${dialogMode}_in_progress`), "info");
        try {
            let notifType = "";
            if (dialogMode == eDialogMode.duplicate) {
                const results = (await Client.copyVertex(objectType.name, { ...(data as CopyBody), ...duplicateParams }, false))?.data?.results;
                let newElement: T = null;
                if (Array.isArray(results))
                    newElement = (results as T[])?.[0];
                else
                    newElement = results as T;
                notifType = Trad(`creation_succes_${genre}`);
                if (!repeat) {
                    selectRid(newElement["@rid"]);
                    SetMode(objectType.name, undefined);
                }
            }
            else if (data["@rid"]) {
                //  update
                await Client.updateVertex(objectType.name, data, false);
                notifType = Trad(`modify_success_${genre}`);
                if (!repeat) {
                    selectRid(data["@rid"]);
                    SetMode(objectType.name, undefined);
                }
            } else {
                // creation
                delete data["Deversement"];
                delete data["Volume"];
                const newElement: T = (await Client.createVertex(objectType.name, data, false))?.data?.results;
                notifType = Trad(`creation_succes_${genre}`);
                if (!repeat) {
                    selectRid(newElement["@rid"]);
                    SetMode(objectType.name, undefined);
                }
            }

            // if (editDiffusion)
            //     setState({ editDiffusion: false });
            //Notify(`${Trad("message")} : ${start} - ${end} ${support} ${format} ${placement} ${notifType}`, "success");
            Notify(`${Trad(objectType.name)} :  ${notifType}`, "success");
            if (!repeat) {
                store.dispatch(setMessageSync(null));
                refreshContent(false);
                setRefresh(false);
            } else {
                setRefresh(true);
                return true;
            }
        } catch (e: any) {
            NotifyError(e?.response?.data)
            return true
        }
    }

    const refreshContent = async (updateColumns: boolean = true) => {
        console.log(`[refreshContent]`, grid?.props?.configuration?.ViewMode)
        switch (grid?.props?.configuration?.ViewMode) {
            case "Table":
                if (updateColumns) grid.Initialize();
                else await grid.UpdateRows();
                break;
            case "Dashboard":
                await grid.UpdateRows();
                break;
            default:
                store.dispatch(clearGridFilter());
                onReferenceCrossedTable();
                SetMode(objectType.name, undefined);
                break;
        }
    }

    const onEdit = async (row: T) => {
        const [data] = await Client.searchVertexTyped(objectType, { "@rid": row["@rid"], properties: ["*", ...(editProperties ?? [])] });
        setState({ elementEdited: data })
        SetMode(objectType.name, eDialogMode.modify);
    }

    //const onEditDiffusion = (row: ref_Messages) => setState({ messageEdited: row, editDiffusion: true })
    const onDuplicate = async (row: T) => {
        const [data] = await Client.searchVertexTyped(objectType, { "@rid": row["@rid"], properties: ["*", ...(editProperties ?? [])] });

        const toDuplicate = clone(data);
        //SanitizeDuplication(toDuplicate);
        setState({ elementEdited: toDuplicate })
        SetMode(objectType.name, eDialogMode.duplicate);
    }

    const generateConfigCrossedTable = async () => {
        // TODO: Legacy
        if (!modeleTable.CrossedtableConfig?.rowventils) modeleTable.CrossedtableConfig = {
            rowventils: [{
                field: propertyOf<T>("@rid"),
                type: "@rid",
                linkedClass: objectType.name,
            }]
        };

        const confCT = { ...new CrossedTableConfig(), ...filters };
        confCT.rowventils = modeleTable.CrossedtableConfig.rowventils;
        confCT.hideDetailsRows = false;
        const conf: ref_TableConfigurations = modeleTable ?? await GetDefaultColumnConfiguration(objectType.name);

        ConsoleDebug("[DataGridComponent]", conf);

        /** Check si on a droit aux devises restituées, si non, alors on les fitre */
        let cols = RightManager.hasRight(lnk_ChangeRate.name, eRights.read) ? conf.Columns : conf.Columns.filter(c => !IsIndicateurReturned(c));
        if (/*conf.HidedColumns && */conf.ViewMode === "Scheduler") {
            cols = cols.filter(c => !c.isSchedulerHidden);
        }
        confCT.selectedIndicateurs = cols;

        const copy = JSON.parse(JSON.stringify(confCT));
        if (copy.Start) copy.Start = new Date(copy.Start);
        if (copy.End) copy.End = new Date(copy.End);
        return copy;
    }

    const getFrozenNumber = () => {
        const { FrozenPosition, Columns, ViewMode } = modeleTable ?? {};

        const cols = Columns.slice(0, FrozenPosition);
        if (ViewMode === "Scheduler") {
            const hidden = cols.filter(c => c.isSchedulerHidden);
            return FrozenPosition - hidden.length;
        }

        return FrozenPosition;
    }

    if (!state.grid)
        return <Loader text={Trad("loading_grid")} />;

    if (!filters)
        return <Loader text={Trad("loading_configuration")} />;

    let { ViewMode } = modeleTable ?? {};

    const confComponent = <ConfigurationPanel
        elements={[(RightManager.hasRight(objectType.name, eRights.create) && {
            type: "component",
            component: <CustomAddButtons
                tooltip={Trad(`create_${objectType.name}`)}
                className="no-shadow no-radius custom_btn_primary no-shadow"
                isIcon
                disableMoreIcon
                options={state.medias}
                //addNew={() => setState({ editMode: true, messageEdited: undefined, mode: eDialogMode.create })}
                onChanged={(media) => {
                    setState({ selectedMedia: media, elementEdited: undefined });
                    SetMode(objectType.name, eDialogMode.create);
                }
                } />
        }), {
            type: "icon",
            title: () => Trad(`${ViewMode}_view`.toLocaleLowerCase()),
            icon: dicoViewModeIcons[ViewMode] ?? "settings",
            element: <TableComponent
                objectType={objectType}
                grid={grid as any} />
        },
        MenuItemFilters(objectType.name),
        (RightManager.hasRight(eFunctions.ExcelTemplate, eRights.read) && ViewMode === "Scheduler" && {
            type: "icon",
            title: () => Trad("configure_scheduler"),
            icon: "palette",
            element: <SchedulerConfigDialog objectType={objectType} />
        }), (ViewMode !== "Dashboard" ? {
            type: "icon",
            title: () => Trad("export"),
            icon: "download",
            element: <>
                <div className="view-icons" style={{ display: "flex" }}>
                    <CustomIconButton
                        onMouseOver={e => TooltipManager.Push({ target: e.target, text: Trad("CSV") })}
                        // className="custom_btn_primary"
                        disabled={!RightManager.hasRight(objectType.name, eRights.export)}
                        onClick={async () => {
                            const arg: TableExport = {
                                type: "table",
                                document: objectType.name,
                                filter: filters,
                                columnsGeneration: "fromData",
                                columns: modeleTable.Columns,
                            }
                            return Client.downloadExport('csv', arg, Trad(objectType.name));
                        }}><CsvFile /></CustomIconButton>
                </div>
                <ExportConfiguration
                    grid={grid as any}
                    filters={filters}
                    generateConfigCrossedTable={generateConfigCrossedTable}
                    open={state.openExport}
                    close={() => setState({ openExport: false })}
                />
            </>
        } : {
            type: "component",
            component: <IconButton
                onMouseOver={(ev) => TooltipManager.Push({ target: ev.target, text: Trad("export") })}
                className={"icon-panel-item navigation no-radius"}
                key="element-download"
                aria-label="edit"
                onClick={() => PDFExportEvent.emit("export")} >
                <div className="icons-panel-badge-container">
                    {getIcon("download")}
                </div>
            </IconButton>
        })]} />

    const UpdateFromServer = async () => {
        const document = new AggregateExport();
        const logArg: Partial<logUserArg> = { Category: 'crossed_table', Action: eAction.Read, Informations: {} };
        logArg.Informations.document = document;
        try {
            document.document = objectType.name;
            document.dimensions = clone(modeleTable?.CrossedtableConfig?.rowventils);
            document.filter = clone(filters ?? {});
            document.columns = modeleTable.Columns;
            document.hideDetailsRows = false;

            if (!document.filter.properties) document.filter.properties = [];
            if (!document.filter.properties.includes('Start')) document.filter.properties.push('Start');
            if (!document.filter.properties.includes('End')) document.filter.properties.push('End');

            document.Start = filters.Start;
            document.End = filters.End;

            if (modeleTable.ViewMode === "Scheduler") {
                const confScheduler = getScheduler(objectType.name);
                const properties = EngineTools.BuildPropertiesFromLabelBuilders([confScheduler.LabelBuild, confScheduler.LabelBuildBottom, confScheduler.LabelBuildTop].flat());
                const { advStyles } = await GetSchedulerStyledPropertiesMemo(objectType.name);
                properties.push(...advStyles.map(s => s.key));
                properties.push(confScheduler.BarProperty);
                document.filter.properties = distinct(document.filter.properties.concat(properties).filter(Boolean));
                document.columns = modeleTable.Columns.filter(c => !c.isSchedulerHidden);
            }
            const _body = clone(document);
            await SanitizeFilter(_body.document, _body.filter);
            const resp = await Client.aggregate<T>(_body);
            const { table } = resp.data;

            const data = table.Rows.flatMap(r => r.Data);
            store.dispatch(setData(data as any));
            return table;

        } catch (error) {
            let errorTitle = 'Error crossed table';
            Client.log({ ...logArg, Status: "fail", Informations: { ...logArg.Informations, error } });
            console.log(errorTitle);
            console.log(error);
        }
    }

    const onReferenceCrossedTable = async () => {
        if (!ctComponent)
            return;

        try {
            setLoading(true);

            console.log("[UpdateFromServer] onReferenceCrossedTable", ctComponent, objectType.name)
            const confCT = await generateConfigCrossedTable();
            const res = await UpdateFromServer();
            await ctComponent.Load(res, confCT)//, res.Columns.filter(c => !c.IsRow));

            const { ridsToSelect } = state;
            if (ridsToSelect?.length) {
                const selectedRows = [];
                recurse(res.Rows, "Children", (e) => {
                    if (e.Data?.length == 1 && ridsToSelect.includes(e.Data[0]["@rid"])) {
                        (e as RowWrapper<T>).isSelected = true;
                        selectedRows.push(e);
                    }
                });
                ctComponent.forceUpdate();

                store.dispatch(selectItems(selectedRows));
                setState({ ridsToSelect: null })
            }
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const handleClose = () => {
        SetMode(objectType.name, undefined);
        if (refresh) {
            refreshContent(false);
            setRefresh(false);
        }
        store.dispatch(disableRepeat());
    }

    return (
        <>
            <div id={'anchor-popup'}></div>
            <div style={{ width: '100%' }}>
                {dialogMode &&
                    getDialog(props, state, onValidateDialog, handleClose)}
                <div>
                    <MessagesToolbar>

                        {/** Toolbar qui apparait si des éléments sont sélectionnés */}
                        <ToolbarSelectedItems
                            grid={grid as any}
                            onRemove={() => refreshContent(false)}
                            onChange={(rids) => {
                                selectRid(rids);
                                refreshContent(false);
                            }} />
                        <ErrorBoundary>
                            <ConfigurationPanelContainer>
                                {!Boolean(importRid) && confComponent}
                            </ConfigurationPanelContainer>
                        </ErrorBoundary>
                    </MessagesToolbar>

                    {(ViewMode === "Table" || !ViewMode) && <ModeleGrid
                        groupable={false}
                        gridHeight={`calc(100vh - 140px)`}
                        footer
                        onEdit={(row) => onEdit(row.dataItem)}
                        onDuplicate={(row) => onDuplicate(row.dataItem)}
                        sort={state.sort}
                        grid={state.grid}
                        isCopyDisable={!RightManager.hasRight(objectType.name, [eRights.create, eRights.update]) || Boolean(importRid)}
                        uneditable={!RightManager.hasRight(objectType.name, eRights.update)}
                        selectionChange={(_selectedRows) => store.dispatch(selectItems(_selectedRows))}
                        // lock MAP message edition
                        commandCellArgs={{ isEditable: (r: AdwRow<T>) => r?.dataItem?.['Source'] !== "MAP" && RightManager.hasRight(Boolean(importRid) ? eFunctions.cache : objectType.name, eRights.update) }}
                        isSelectable={(r: AdwRow<T>) => r?.dataItem?.['Source'] !== "MAP"}
                        hideToolbar
                        selectable
                        onRowInitialized={(rows) => {
                            const selectedRows = rows.filter(r => r.selected);
                            if (selectedRows.length)
                                store.dispatch(selectItems(selectedRows));
                        }}
                        headerFiltersChanged={(adapted, base) => store.dispatch(setGridFilter({ adapted, base }))}
                        onSortChanged={sort => store.dispatch(setSort(sort))}
                    />}

                    {(ViewMode === "CrossedTable" || ViewMode === "Scheduler") &&
                        <div>
                            <div className="clearfix">
                                {ViewMode === "CrossedTable" &&
                                    <CrossedTableTelerikTree
                                        onRef={ref => { ctComponent = ref; }}
                                        customMessageHeight={`calc((100vh - 140px) - 25px)`}
                                        editable={RightManager.hasRight(objectType.name, eRights.update)}
                                        frozenPosition={grid.props.configuration?.FrozenPosition}
                                        onEdit={(row) => onEdit(row.Data[0] as any)}
                                        onCopy={(row) => onDuplicate(row.Data[0] as any)}
                                        isEditable={(row) => !row?.Children?.length}
                                        // expandAll
                                        selectable />}
                                {ViewMode === "Scheduler" &&
                                    <>
                                        <AutoResizeUpdater autoResize={autoResize} refreshContent={refreshContent} />
                                        <GanttTelerik
                                            onRef={ref => { ctComponent = ref; }}
                                            autoResize={autoResize}
                                            height={`calc((100vh - 140px) - 25px)`}
                                            loaderHeight={`calc(100vh - 140px)`}
                                            frozenPosition={getFrozenNumber()}
                                            editable={RightManager.hasRight(objectType.name, eRights.update)}
                                            onEdit={row => onEdit(row.Data[0] as any)}
                                            onCopy={row => onDuplicate(row.Data[0] as any)}
                                            isEditable={row => !row?.Children?.length}
                                            // expandAll
                                            selectable
                                            renderGroup={row => <GroupComponent objectType={objectType.name} row={row} />}
                                            renderMessage={row => <GroupComponent objectType={objectType.name} row={row} />}
                                        />
                                    </>}
                            </div>
                            {loading && <Loader time />}
                        </div>
                    }

                    {ViewMode === "Dashboard" && <Dashboard objectType={objectType} />}
                    <FooterCellOptions grid={grid} />
                </div>
            </div>
        </>
    )
}
