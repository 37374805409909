
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { extractSub, GetHashCode, JSONEqualityComparer } from "hub-lib/tools.bin";
import * as React from "react"
import { useDispatch, useSelector } from "react-redux";
import { Trad, TradProp } from "trad-lib";
import { lockNext, setMessage, unlockNext } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { getFormatOffer, getFormatPriceExistsComponent, getLabelFormat } from "./OfferTools";
import { WarningMM } from "../../Generic/ModelForm/WarningMM";
import { vw_mm_HasFormat } from "hub-lib/models/orientdb/vw_mm_HasFormat.bin";
import { vw_tsm_HasFormat } from "hub-lib/models/orientdb/vw_tsm_HasFormat.bin";
import { CheckElement } from "../../Generic/ModelForm/CheckElement";
import { FormatExtended } from "hub-lib/dto/referential/FormatExtended.bin";
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";

let formatRidGlobal: IRid["@rid"] = undefined;

export function FormatEditor() {

    const dispatch = useDispatch();
    const { Media, BroadcastArea, Format, ModelProperties } = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ["Media", "BroadcastArea", "Format", "ModelProperties"]) ?? {}, JSONEqualityComparer);
    // important de garder ce selector pour refresh le composant quand les labels de prix quand les offres sont Sets
    const offerHash = GetHashCode(useSelector((root: RootState) => root.messageEditor.offers)?.map(o => o["@rid"]));
    const [formatRid, setFormatRid] = React.useState(formatRidGlobal);
    const [currencies, setCurrencies] = React.useState<ref_Currencies[]>(null);

    React.useEffect(() => {
        if (!formatRid)
            Client.searchVertexTyped<ref_PropertyType>(ref_PropertyType, { Type: "Format" })
                .then(([_formatRid]) => setFormatRid(_formatRid["@rid"]));
        if (!currencies)
            KPIsManagerCache.GetCurrencies().then(c => setCurrencies(c));
    })

    if (!formatRid || !currencies)
        return <></>

    return <WarningMM ids={[
        { id: Format, linkClass: vw_mm_HasFormat.name },
        { id: Format, linkClass: vw_tsm_HasFormat.name }]}>
        <FavoriteVertexAutoComplete
            key={`FavoriteVertexAutoComplete-formats_${offerHash}`}
            type={FormatExtended.name}
            refreshOnPropChanged
            disableClearable
            disabled={!Media || !BroadcastArea}
            label={TradProp("Format", ref_Messages) + " *"}
            params={{
                Active: true,
                PropertyType: [formatRid],
                Medias: [Media],
                BroadcastAreas: BroadcastArea,
                properties: ["@rid", "Name", "LinkedProperties", "@class"]
            }}
            defaultValue={(options: FormatExtended[]) => CheckElement(options?.find(o => o.Format == Format
                && ((!ModelProperties["Couleur"] && !o.Couleur) || (ModelProperties["Couleur"] == o.Couleur))
                && ((!ModelProperties["Implantation"] && !o.Implantation) || (ModelProperties["Implantation"] == o.Implantation))
            ), Format, ref_Property.name)}
            leftColumns={[getFormatPriceExistsComponent]}
            getCategory={(o: FormatExtended) => getFormatOffer(o?.Format, o?.Couleur) ? Trad("other_with_price") : Trad("other_with_no_price")}
            SortCategories={[Trad("other_with_price"), Trad("other_with_no_price")]}
            getOptionLabel={ (f) => getLabelFormat(f, currencies)}
            Sort={(a, b, groupA, groupB) => {
                const tradFav = Trad("favorite");
                if (groupA === tradFav && groupB === tradFav) {
                    const offerA = getFormatOffer(a?.Format, a?.Couleur);
                    const offerB = getFormatOffer(b?.Format, b?.Couleur);
                    if (offerA && !offerB) return -1;
                    if (!offerA && offerB) return 1;
                    return 0;
                }
                return undefined;
            }}
            onChange={async (format: FormatExtended) => {
                dispatch(lockNext());
                const message = store.getState().messageEditor.get();
                message.Format = format.Format;
                if (format.Couleur)
                    message.ModelProperties["Couleur"] = format.Couleur;
                else
                    delete message.ModelProperties["Couleur"];
                if (format.Implantation)
                    message.ModelProperties["Implantation"] = format.Implantation;
                else
                    delete message.ModelProperties["Implantation"];
                dispatch(setMessage(message));
                dispatch(unlockNext());
            }} />
        {/*<FavoriteVertexAutoComplete
            key={`FavoriteVertexAutoComplete-formats_${offerHash}`}
            type={ref_Property.name}
            refreshOnPropChanged
            disableClearable
            disabled={!Media || !BroadcastArea}
            label={TradProp("Format", ref_Messages) + " *"}
            params={{
                Active: true,
                PropertyType: [formatRid],
                Medias: [Media],
                BroadcastAreas: BroadcastArea
            }}
            defaultValue={(options: IRid[]) => CheckElement(options?.find(o => o["@rid"] == Format), Format, ref_Property.name)}
            leftColumns={[getFormatPriceExistsComponent]}
            getCategory={(o: IRid) => getFormatOffer(o?.["@rid"]) ? Trad("other_with_price") : Trad("other_with_no_price")}
            SortCategories={[Trad("other_with_price"), Trad("other_with_no_price")]}
            getOptionLabel={getLabelFormat}
            Sort={(a, b, groupA, groupB) => {
                const tradFav = Trad("favorite");
                if (groupA === tradFav && groupB === tradFav) {
                    const offerA = getFormatOffer(a?.["@rid"]);
                    const offerB = getFormatOffer(b?.["@rid"]);
                    if (offerA && !offerB) return -1;
                    if (!offerA && offerB) return 1;
                    return 0;
                }
                return undefined;
            }}
            onChange={async (format: IRid) => {
                dispatch(lockNext());
                const message = store.getState().messageEditor.get();
                message.Format = format["@rid"];
                dispatch(setMessage(message));
                dispatch(unlockNext());
            }} />*/}
    </WarningMM>
}