import * as React from "react";

import { AdwTelerikGrid } from "../../Generic/AdwTelerikGrid.bin";
import { VertexGrid } from "../../Generic/VertexGrid.bin";
import { ADWColumn, AdwRow, IItemRow } from "adwone-lib/index";
import Loader from "../../../layout/Loader";
import { Trad, TradClassName, TradProp } from "trad-lib";
import { BagesUsage } from "./BadgesUsage.bin";
import { Client } from "hub-lib/client/client.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { GetSubElement, propertyOf } from "hub-lib/tools.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { eCompare } from "hub-lib/operators.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { getIcon } from "adwone-lib/index";
import { TooltipManager } from "../../../CustomTooltip";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";
import { CustomIconButton } from "../../Generic/CustomIconButton";
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, SelectedItemsContainer, ToolbarAdw, ToolbarContainer } from "../../Generic/ToolbarAdw";
import { ConfigurationPanel } from "../../Messages/ConfigurationPanel";
import { styleGridContainer } from "../../../../styles/theme";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";
import { eDialogMode, GenericDialog } from "../../../ConfigurableComponents/GenericDialog.bin";
import { ReferentialForm } from "./ReferentialForm.bin";
import { useEffect, useState } from "react";
import { ReferentialElement } from "../../../layout/SuperAdmin/Referential.bin";
import { ref_AdvertisingCompanyRole } from "hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { SupportExtended } from "hub-lib/dto/referential/SupportExtended.bin";
import { GetOrder } from "format-lib/index.bin";
import { lnk_AdvertisingCompanySupport } from "hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin";
import { createLinkColumn } from "./ReferentialTools";

class GridProps {
    vertex: any;
    vertexName: string;
    title: string;
    propertyType?: string
    extendedProperties?: string[];
}
export function ReferentialGrid(props: GridProps) {

    const [SelectedItems, setSelectedItems] = useState<IItemRow<ReferentialElement>[]>([]);
    const [TotalItems, setTotalItems] = useState<number>(0);
    const [ReferentialGrid, setReferentialGrid] = useState<VertexGrid<ReferentialElement>>(undefined);
    const [TelerikGrid, setTelerikGrid] = useState<AdwTelerikGrid<ReferentialElement>>(undefined);
    const [EditItem, setEditItem] = useState<IRid>();
    const [Mode, setMode] = useState<eDialogMode>(undefined);
    const [Roles, setRoles] = useState<ref_AdvertisingCompanyRole[]>(undefined);
    const [PropertyTypes, setPropertyTypes] = useState<ref_PropertyType[]>();
    const clntForm = props.extendedProperties?.includes("ClntData");
    useEffect(() => {
        if (ReferentialGrid)
            return;
        if (!Roles && props.vertexName == ref_Supports.name) {
            Client.searchVertexTyped(ref_AdvertisingCompanyRole).then(res => setRoles(res));
            return;
        }
        if (props.propertyType && !PropertyTypes) {
            const propertyTypes: string[] = [props.propertyType];
            if (props.propertyType == "Emplacement")
                propertyTypes.push("BroadcastPlacement");
            Client.searchVertexTyped(ref_PropertyType, { "Type": propertyTypes }).then(res => setPropertyTypes(res))
            return;
        }

        let columns: ADWColumn<any>[] = [];
        let hiddenProperties: string[] = [];
        let properties: string[] = [];
        let order: string[] = [];
        let vertexParams: any = { Active: true }

        columns.push(new ADWColumn<any>(TradProp("@rid"), "@rid", ePropType.String));

        if (props.vertexName == ref_Supports.name) {
            hiddenProperties = ["Covers", "Logo", "Description", "Medias", "lnkAdvertisingCompanies", "lnkBroadcastAreas", "lnkCurrencies", "Customers"];
            properties.push(`Medias.Name as MediasName`);
            const comRole = Roles.find(r => r.Name == "Commercial")?.['@rid'];
            const finRole = Roles.find(r => r.Name == "Financial")?.['@rid'];
            columns.push(createLinkColumn<SupportExtended>(Trad("property_" + "Medias"), "Medias"));
            columns.push(createLinkColumn<SupportExtended>(TradProp("AdvCompany_Com", ref_Messages), "lnkAdvertisingCompanies", "inName", (e: lnk_AdvertisingCompanySupport) => e.Roles?.includes(comRole), "AdvCompany_Com"));
            columns.push(createLinkColumn<SupportExtended>(TradProp("AdvCompany_Fin", ref_Messages), "lnkAdvertisingCompanies", "inName", (e: lnk_AdvertisingCompanySupport) => e.Roles?.includes(finRole), "AdvCompany_Fin"));
            columns.push(createLinkColumn<SupportExtended>(TradProp("BroadcastArea", ref_Messages), "lnkBroadcastAreas", "outName"));
            columns.push(createLinkColumn<SupportExtended>(TradClassName(ref_Currencies.name), "lnkCurrencies", "outName"));
            order = [...GetOrder<ref_Supports>(ref_Supports), "AdvCompany_Com", "AdvCompany_Fin", "lnkBroadcastAreas", "lnkCurrencies", "Created_by", "Created_at", "Updated_by", "Updated_at", "Website"];
            vertexParams["options"] = {
                lnkCurrencies: true,
                lnkBroadcastAreas: true,
                lnkAdvertisingCompanies: true,
                traverselnk: true,
                current: true
            }
        }
        else {
            hiddenProperties = ["Country", "City", "Siren", "Gln", "Siret", "ZipCode", "Tva", "Adress", "AllBroadcastAreas", "Customers"];
            if (props.vertexName !== ref_Currencies.name)
                hiddenProperties.push("Code");
            if (props.propertyType) {
                columns.push(createLinkColumn<ref_Property>(Trad("property_" + "Medias"), "Medias"));
                hiddenProperties = [...hiddenProperties, ...["Medias", "BroadcastAreas", "PropertyType"]];
                properties.push(`Medias.Name as MediasName`);
                vertexParams = {
                    ...vertexParams, "_operators": [{
                        property: "PropertyType",
                        value: PropertyTypes.find(t => t.Type == props.propertyType)["@rid"],
                        compare: eCompare.Contains
                    }]
                }
            }
            order = ["Label", "Name", "Code", "Medias", "Start", "End", "Created_by", "Created_at", "Updated_by", "Updated_at"];
        }
        vertexParams["properties"] = ["*", ...properties]

        setReferentialGrid(new VertexGrid<ReferentialElement>({
            objectPrototype: props.vertex,
            devMode: false,
            columns,
            width: {
                "Name": 300
            },
            vertexParams: vertexParams,
            order: order,
            hiddenProperties
        }));
    })

    if (!ReferentialGrid)
        return <Loader />;

    const gridComponent = <AdwTelerikGrid
        onRef={ref => setTelerikGrid(ref)}
        onEdit={(row: AdwRow<ReferentialElement>) => {
            setEditItem(row.dataItem);
            setMode(eDialogMode.modify);
        }}
        onDuplicate={(row: AdwRow<ReferentialElement>) => {
            setEditItem(row.dataItem);
            setMode(eDialogMode.duplicate);
        }}
        grid={ReferentialGrid}
        gridHeight={styleGridContainer.referential.height}
        selectionChange={(rows) => setSelectedItems(rows)}
        onRowInitialized={(rows) => setTotalItems(rows?.length)}
        selectable
        isCopyDisable={!RightManager.hasRight(eFunctions[props.vertexName], [eRights.create, eRights.update])}
        commandCellArgs={{ isEditable: RightManager.hasRight(eFunctions[props.vertexName], eRights.update) || clntForm }}
        hideToolbar
    />

    const confComponent = <ConfigurationPanel
        elements={[{
            type: "component",
            component: <CustomIconButton
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("create") })}
                disabled={!RightManager.hasRight(eFunctions[props.vertexName], eRights.create)}
                className="custom_btn_primary no-radius no-shadow icon-panel-item"
                onClick={() => {
                    setEditItem(undefined);
                    setMode(eDialogMode.create);
                }}>
                {getIcon("plus")}
            </CustomIconButton>
        }, {
            type: "icon",
            title: () => Trad("export"),
            icon: "download",
            element: <>
                <div className="adw-row">
                    <CustomButton
                        Label={Trad("CSV")}
                        style={{ float: "right" }}
                        className="custom_btn_primary"
                        onClick={() => ReferentialGrid.exportExcel("csv")} />
                </div></>
        }
        ]} />

    return (
        <div style={{ width: '100%' }}>
            {Mode &&
                <GenericDialog
                    open={true}
                    dialogTitle={`${Trad(Mode)} ${props.propertyType ?? TradClassName(props.vertexName)}`}
                    iconsTitle={<div className="usage-icons-title">
                        {Mode === eDialogMode.modify && !clntForm && <BagesUsage vertexName={props.vertexName} value={EditItem["@rid"]} />}
                    </div>}>
                    <div className='adw-form-dialog-container'>
                        <ReferentialForm
                            onSave={async (el, mode) => {
                                setMode(undefined);
                                ReferentialGrid.elementsToSelect = [el["@rid"]];
                                await ReferentialGrid.UpdateRows();
                            }}
                            mode={Mode}
                            onCancel={() => setMode(undefined)}
                            selectedVertex={props.propertyType ?? props.vertexName}
                            selectedItem={EditItem}
                            extendedProperties={props.extendedProperties} />
                    </div>
                </GenericDialog>
            }
            <ToolbarAdw hasSelectedItems={SelectedItems?.length > 0} count={TotalItems}>
                <ToolbarContainer>
                    {SelectedItems?.length > 0 &&
                        <SelectedItemsContainer>
                            <CustomButton
                                Label={Trad("delete")}
                                disabled={!RightManager.hasRight(eFunctions[props.vertexName], eRights.delete)}
                                style={{ float: 'right' }}
                                className="custom_btn_danger"
                                startIcon={getIcon("delete")}
                                onClick={() => {
                                    TelerikGrid.baseToggleRemoveDialog();
                                    setSelectedItems([]);
                                }} />
                        </SelectedItemsContainer>
                    }
                    {SelectedItems.length <= 0 && <BreadcrumbsCustomContainer>
                        <BreadcrumbsCustom
                            hasSelectedItems={SelectedItems.length > 0}
                            elements={[
                                { text: Trad("home"), href: "/" },
                                { text: Trad("referential") },
                                { text: props.title },
                            ]} />
                    </BreadcrumbsCustomContainer>}
                </ToolbarContainer>
                <ConfigurationPanelContainer>
                    {confComponent}
                </ConfigurationPanelContainer>
            </ToolbarAdw>
            {gridComponent}
        </div>
    )
}