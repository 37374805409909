import { configureStore } from '@reduxjs/toolkit'
// import adminReducer from '../reducers/adminReducer'
import authReducer from '../reducers/authReducer'
import importsReducer from '../reducers/importsReducer'
import searchReducer from '../reducers/searchReducer'
import supportReducer from '../reducers/supportReducer'
import { gridReducer } from './gridSlice'
import { linkMgrReducer } from './linkMgrSlice'
import { storageReducer } from './storageReducer'
import { tooltipReducer } from './tooltipSlice'
import { referentialTreeReducer } from './referentialTreeSlice'
import { messageEditorReducer, setMessage, setMessageMode, setMessagePublications, setMessageSync } from './messageEditorSlice'
import { treeReducer } from './treeSlice'
import { backgroundReducer } from './backgroundSlice'
import { projectReducer } from './projectSlice'
import { connectedStatusReducer } from './connectedStatusSlice'
import { agreementEditorReducer } from './agreementEditorSlice'
import { percentageSimilarityReducer } from './percentageSimilaritySlice'
import { tcdReducer } from './tcdSlice'
import { supplierReducer } from './supplierSlice'
import { campaignEditorReducer, setCampaign, setCampaignMode, setCampaignPublications, setCampaignSync } from './campaignEditorSlice'
import { ref_Publications } from 'hub-lib/dto/client/ref_Publications.bin'
import { gridConfigurationsReducer } from './gridConfigurationsSlice'
import { eDialogMode } from '../components/ConfigurableComponents/GenericDialog.bin'

export const store = configureStore({
  reducer: {
    tcd: tcdReducer,
    tooltip: tooltipReducer,
    auth: authReducer,
    grid: gridReducer,
    verticesStorage: storageReducer,
    linkMgr: linkMgrReducer,
    tree: treeReducer,
    globalSearch: searchReducer,
    support: supportReducer,
    // admin: adminReducer,
    imports: importsReducer,
    referentialTree: referentialTreeReducer,
    campaignEditor: campaignEditorReducer,
    messageEditor: messageEditorReducer,
    agreementEditor: agreementEditorReducer,
    background: backgroundReducer,
    project: projectReducer,
    columsConfigurations: gridConfigurationsReducer,
    connectedStatus: connectedStatusReducer,
    similarityPercentage: percentageSimilarityReducer,
    supplier: supplierReducer,

  },
  devTools: process.env.NODE_ENV !== 'production' && {
    name: "App",
    trace: true
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const GetEditorState = (modelName: string, root: RootState) => {
  return modelName == "ref_Messages" ? root.messageEditor : root.campaignEditor;
}

export const SetMode = (modelName: string, mode: eDialogMode) => {
  return modelName == "ref_Messages" ? store.dispatch(setMessageMode(mode)) : store.dispatch(setCampaignMode(mode));
}

export const SetModel = (modelName: string, data: any) => {
  return modelName == "ref_Messages" ? store.dispatch(setMessage(data)) : store.dispatch(setCampaign(data));
}

export const SetModelSync = (modelName: string, data: any) => {
  return modelName == "ref_Messages" ? store.dispatch(setMessageSync(data)) : store.dispatch(setCampaignSync(data));
}

export const SetPublications = (modelName: string, data: ref_Publications[]) => {
  return modelName == "ref_Messages" ? store.dispatch(setMessagePublications(data)) : store.dispatch(setCampaignPublications(data));
}

export const getScheduler = (modelName: string) => {
  return getSchedulerSelector(modelName)(store.getState());
}

export const getSchedulerSelector = (modelName: string) => {
  return (state: RootState) => state.columsConfigurations?.configurations?.[modelName]?.scheduler;
}

export const getTable = (modelName: string) => {
  return getTableSelector(modelName)(store.getState());
}

export const getTableSelector = (modelName: string) => {
  return (state: RootState) => state.columsConfigurations?.configurations?.[modelName]?.table;
}

export const getExportSelector = (modelName: string) => {
  return (state: RootState) => state.columsConfigurations?.configurations?.[modelName]?.export;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

