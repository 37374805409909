
import * as React from "react";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { GetHashCode, clone, propertyOf, removeDiacritics } from "hub-lib/tools.bin";
import { Notify } from "../../../../../utils/Notify.bin";
import { Trad } from "trad-lib";
import { eDirection, Indicateur, IndicateurToString } from "adwone-engine/index.bin";
import { CustomIconButton } from "../../../Generic/CustomIconButton";
import { getMultiSelectTreeValue, MultiSelectTree, MultiSelectTreeChangeEvent, MultiSelectTreeExpandEvent } from "@progress/kendo-react-dropdowns";
import { eColumnType } from "hub-lib/models/types.bin";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { GetDragCell } from "../../../Messages/DiscountCategoryArray.bin";
import { getter } from "@progress/kendo-data-query";
import { ContainerComponent } from "../../../Generic/ContainerComponent";
import { filterBy } from "@progress/kendo-react-data-tools";
import { getIcon } from "adwone-lib/components/IconManager";
import { dragCol } from "../../../Messages/DiscountEditor/DiscountEditor.bin";
import { RowSize } from "../../../../../styles/theme";
import { ModeleCreatorBaseProps } from "../../../ModeleCreator/ModeleCreatorBase";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { AggregatorFactory, IndicateurOption } from "hub-lib/aggregator/AggregatorFactory";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { TooltipManager } from "adwone-lib";
import { IsNumber } from "hub-lib/models/KPIsManager.bin";

export class TProps extends ModeleCreatorBaseProps<ref_TableConfigurations> {
    hideViewSelector?: boolean;
}

let timeoutTriggerChanged = undefined;

type KPIsPickerProps = { onChange: (configuration: ref_TableConfigurations) => void, value?: any[], isPivotGridEditor?: boolean }

/**
 * Composant de création d'un modèle de tableau (sélection des indicateurs)
 */
export function KPIsPicker({ onChange, value, isPivotGridEditor }: KPIsPickerProps) {
    const configuration = clone(useSelector((root: RootState) => root.tcd.pivotGridConfigToShow));
    const [columnEditorConfiguration, setColumnEditorConfiguration] = React.useState<ref_TableConfigurations>({ ...new ref_TableConfigurations(), Columns: value, Default: configuration.Default });

    React.useEffect(() => {
        setColumnEditorConfiguration({ ...columnEditorConfiguration, Columns: value, Default: configuration?.Default ?? false });
    }, [GetHashCode(value), configuration?.Default])

    return <ColumnEditor
        onChange={onChange}
        configuration={columnEditorConfiguration}
        isPivotGridEditor={isPivotGridEditor}
    />
}

export class ColumnEditorProps {
    configuration: ref_TableConfigurations;
    onChange: (configuration: ref_TableConfigurations) => void;
    isPivotGridEditor?: boolean;
    //grid: VertexGrid<any>;
}

class ColumnTreeData {
    key: string;
    value: eColumnType | Indicateur;
    label: string;
    filterLabel: string;
    items: ColumnTreeData[];
}
const dataItemKey = "key";
const checkField = "checked";
const checkIndeterminateField = "checkIndeterminate";
const subItemsField = "items";
const expandField = "expanded";
const textField = "label";

const fields = {
    dataItemKey,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField,
};

export class ColumnEditorState {
    flatData: Indicateur[];
    data: ColumnTreeData[];
    version: number;
    filter: any;
    activeItem: any;
    expanded: any[];
    value: any[];
    // showAll: Boolean
}

function ColumnEditor(props: ColumnEditorProps) {

    const _el = React.useRef<HTMLDivElement>();
    const { configuration } = props;
    const [state, setState] = React.useState<ColumnEditorState>({
        flatData: undefined,
        version: 0,
        data: undefined,
        filter: undefined,
        activeItem: undefined,
        expanded: [],
        // showAll: false,
        value: props.configuration?.Columns?.map(c => {
            /** Supprime le nom pour checker l'égalité car la langue a pu changer */
            return { key: IndicateurToString(c), value: c };
        }) ?? []
    });

    React.useEffect(() => {
        if (props.isPivotGridEditor) {
            setState({
                ...state, value: props.configuration?.Columns?.map(c => {
                    /** Supprime le nom pour checker l'égalité car la langue a pu changer */
                    return { key: IndicateurToString(c), value: c };
                }) ?? []
            })
        }
    }, [props.isPivotGridEditor, GetHashCode(props.configuration?.Columns)])

    React.useEffect(() => {
        if (!state.data)
            Promise.resolve().then(async () => {

                const subElement = (option: IndicateurOption, suffix: eDirection) => {
                    const fullLabel = Trad(option.indicateur.name) + ` ${suffix}`;
                    const shortLabel = suffix;
                    const optionsBase = { ...option.indicateur.optionsBase, direction: suffix }

                    if (suffix === eDirection["%Evolution"] || suffix === eDirection.EvolutionU) {
                        optionsBase.evolOptions = { type: "year", offset: 1 }
                    }

                    const subIndicateur: Indicateur = {
                        ...clone(option.indicateur),
                        name: fullLabel,
                        optionsBase: optionsBase
                    };
                    return ({
                        key: IndicateurToString(subIndicateur),
                        value: subIndicateur,
                        items: [],
                        label: shortLabel,
                        filterLabel: fullLabel
                    })
                }

                const options = (await AggregatorFactory.GetInstance().Get(ref_Messages).Provide())
                    .filter(i => ![eColumnType.Barter, eColumnType.Property].includes(i.columnType) || IsNumber(i.indicateur.valueType));
                const data: ColumnTreeData[] = [];
                for (const option of options) {
                    const group = {
                        key: IndicateurToString(option.indicateur),
                        value: option.indicateur,
                        items: [
                            subElement(option, eDirection.U),
                            subElement(option, eDirection["%VerticalTotal"]),
                            subElement(option, eDirection["%Vertical"]),
                            subElement(option, eDirection["%HorizontalTotal"]),
                            subElement(option, eDirection["%Horizontal"]),
                            subElement(option, eDirection.EvolutionU),
                            subElement(option, eDirection["%Evolution"])
                        ],
                        label: Trad(option.indicateur.name),
                        filterLabel: removeDiacritics(Trad(option.indicateur.name))
                    };
                    data.push(group);
                }
                setState({ ...state, data, flatData: data.flatMap(d => ([...(d.items ?? []).map(i => i.value as Indicateur)])) });
            });
    }, [Boolean(state.data)]);


    const filterChange = (event) => {
        event.filter.field = "filterLabel";
        if (event.filter.value.length > 2)
            setState({ ...state, filter: event.filter })
        else
            setState({ ...state, filter: undefined })
    }

    const expandedState = (
        item: unknown,
        dataItemKey: string,
        expanded: unknown[]
    ) => {
        const nextExpanded = expanded.slice();
        const keyGetter = getter(dataItemKey);
        const itemKey = keyGetter(item);
        const index = expanded.findIndex((currentKey) => {
            return currentKey === itemKey;
        });
        index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

        return nextExpanded;
    };

    const onChange = (event: MultiSelectTreeChangeEvent) => {
        const { configuration } = props;
        let newValue = getMultiSelectTreeValue(state.data, { ...fields, ...event, value: state.value });
        let finalValue = state.value;

        // We remove base groups
        newValue = newValue.filter(nv => nv.value.optionsBase)

        // Means user selected a group
        // If no subitems from the group are already selected in the state, we select the first subitem
        // If some subitems are already selected from the group in the state, we remove all of them
        if (event && event.items && event.items.length > 0 && event.items[0].items && event.items[0].items.length > 0) {
            const selectedGroup = event.items[0];

            // Check if some subitems from the group are already selected in the state
            const subItemsFromGroup = state.value.filter(v => v.value.field === selectedGroup.value.field);

            if (subItemsFromGroup.length === 0) {
                // Select the first subitem
                finalValue = finalValue.concat(selectedGroup.items[0]);
            }
            else {
                // Remove all subitems from the group
                finalValue = finalValue.filter(v => v.value.field !== selectedGroup.value.field);
            }
        }
        else if (event.items && event.items.length > 0) {
            // We check if the item we are trying to add is already in the list
            const item = event.items[0];
            const itemIndex = finalValue.findIndex(v => v.key === item.key);

            if (itemIndex === -1) {
                finalValue = finalValue.concat(event.items);
            }
            else {
                finalValue = finalValue.filter(v => v.key !== item.key);
            }
        }


        configuration.Columns = finalValue.map(v => v.value);

        console.log(`[ColumnEditor][onChange]`, configuration)
        props.onChange?.(configuration);

        setState({ ...state, value: finalValue });
    }

    const onExpandChange = (event: MultiSelectTreeExpandEvent) =>
        setState({ ...state, expanded: expandedState(event.item, "key", state.expanded) });

    const reorder = (dataItem: any) => {
        if (!state.activeItem || (state.activeItem === dataItem)) return;

        const reorderedData = configuration.Columns.slice();
        const prevIndex = reorderedData.findIndex((p: any) => (p === state.activeItem));
        const nextIndex = reorderedData.findIndex((p: any) => (p === dataItem));
        reorderedData.splice(prevIndex, 1);
        reorderedData.splice(nextIndex, 0, state.activeItem);

        if (JSON.stringify(configuration.Columns) != JSON.stringify(reorderedData)) {
            configuration.Columns = reorderedData;
            //this.forceUpdate();
            // TODO: force update ?
            clearTimeout(timeoutTriggerChanged);
            timeoutTriggerChanged = setTimeout(() => {
                props.onChange?.(props.configuration);
            }, 2000);
        }
    }

    const dragStart = (dataItem: any) => {
        if (state.activeItem != dataItem)
            setState({ ...state, activeItem: dataItem });
    }

    const onRemove = async (col: Indicateur) => {
        if (configuration.Default)
            return Notify(Trad("cannot_change_default_conf"), "error");

        configuration.Columns = configuration.Columns.filter(cc => IndicateurToString(cc) !== IndicateurToString(col));
        props.onChange?.(configuration);
        setState({ ...state, value: state.value.filter(v => v.key != IndicateurToString(col)) })
    }

    const processMultiSelectTreeData = (tree, options): ColumnTreeData[] => {
        const {
            subItemsField = "items",
            checkField = "checked",
            checkIndeterminateField = "checkIndeterminate",
            expandField = "expanded",
            dataItemKey,
            value,
            filter,
            expanded,
        } = options;


        const filtering = Boolean(filter && filter.value);

        for (const group of tree) {
            group[expandField] = expanded.find(key => key == group.key) != undefined;
            let checked = 0;
            if (group[subItemsField])
                for (const indicateur of group[subItemsField]) {
                    indicateur[checkField] = value.find(v => v.key == indicateur.key) != undefined;
                    if (indicateur[checkField])
                        checked++;
                }

            group[checkField] = checked > 0 && checked == group[subItemsField].length;
            group[checkIndeterminateField] = !group[checkField] && checked > 0;

            if (checked > 0) {
                group[checkField] = true;
                group.label = `${group.filterLabel} (${checked})`
            }
            else {
                group[checkField] = false;
                group.label = group.filterLabel;
            }
        }
        if (filtering) {
            filter.value = removeDiacritics(filter.value);
            const filterTree = filterBy(tree, [filter], subItemsField);
            for (const group of filterTree)
                group[expandField] = true;
            return filterTree;
        }
        return tree;
    }


    const { data, filter, expanded, value, flatData } = state;

    console.log(`configuration?.Columns`, configuration?.Columns);
    console.log(`configuration?.FrozenPosition`, configuration.FrozenPosition);

    const treeData = data ? processMultiSelectTreeData(data, { expanded, value, filter, ...fields }) : [];

    return (
        <div>
            {!configuration?.Default &&
                <div ref={(el) => (_el.current = el)} className="configuration-container" style={{ position: "relative" }}>
                    <ContainerComponent title={Trad("kpis")}
                        className="configuration-multiselect margin-top margin-bottom">
                        {data &&
                            <MultiSelectTree
                                key={`${state.version}-Autocomplete`}
                                data={treeData}
                                placeholder={Trad("add_columns")}
                                dataItemKey={dataItemKey}
                                textField={textField}
                                subItemsField={subItemsField}
                                checkField={checkField}
                                //checkIndeterminateField={checkIndeterminateField}
                                expandField={expandField}
                                filterable
                                value={value}
                                onChange={onChange}
                                onExpandChange={onExpandChange}
                                onFilterChange={filterChange}
                                tags={value.length ? [{ text: `${value.length} ${Trad('columns')}`, data: [] }] : []}
                                style={{ width: "100%" }}
                                popupSettings={{ appendTo: _el.current }}
                            />}
                    </ContainerComponent>
                </div>
            }

            <div className='ColumnEditor-column-container' style={{ width: '100%', marginTop: configuration.ViewMode === "Scheduler" ? 0 : 12 }}>

                {/** Affichage des indicateurs déjà dans la configuration */}
                {configuration?.Columns &&
                    <Grid data={configuration.Columns}

                        // onItemChange={this.itemChange}
                        className={"modelecreator-tab"}>
                        <GridColumn title="" width={dragCol} cell={GetDragCell(reorder, dragStart)} />
                        <GridColumn field={propertyOf<Indicateur>('name')} />
                        <GridColumn
                            cell={(c) => {
                                const currentSignature = IndicateurToString(c.dataItem);
                                const found = !flatData || flatData?.some(d => IndicateurToString(d) == currentSignature);
                                return (<>
                                    <td className="td-command-cell">
                                        {!found &&
                                            <div style={{ position: 'absolute', right: 30, color: '#F4611D' }}
                                                onMouseOver={e => TooltipManager.Push({ target: e.target, text: Trad(`column_not_found`) })}>
                                                {getIcon("warning")}
                                            </div>}
                                        <CustomIconButton onClick={() => onRemove(c.dataItem)}>
                                            {getIcon("close")}
                                        </CustomIconButton>
                                    </td>
                                </>)
                            }}
                            width={RowSize}
                        />
                    </Grid>}
            </div>

        </div >);
}