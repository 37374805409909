import { eKPIType, KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { clone, GetHashCode, propertiesOf, propertyOf } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { Trad, TradClassName, TradComposed, TradProp } from "trad-lib";
import { RootState } from "../../redux/store";
import { ControllableChart } from "../graphs/ControllableChart.bin";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory"
import React, { useState } from "react";
import { Indicateur, IndicateurKPI } from "adwone-engine/index.bin";
import Loader from "../layout/Loader";
import { filter } from "jszip";
import { IDateRangeModel, IMediaCompanyModel, IMediaModel } from "hub-lib/types";

const Title = ({ label }: { label: string }) => {
    return <h3 style={{ position: "absolute", top: 0, left: 0, zIndex: 1, margin: 14 }}>{label}</h3>;
};

export type ChartProps<T> = {
    objectType: new () => T;
}
export function TopSupportsChart<T extends IMediaModel>({ objectType }: ChartProps<T>) {
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const modele = useSelector(
        (root: RootState) => root.project.dashboardModele
    );
    const { currency, indicateur } = modele;
    const filters = { ...tabFilters, ...modele?.filters };

    return (
        <div className="dashboard-block">
            <ControllableChart
                key={"top-supports-" + GetHashCode({ filters, currency })}
                multiple
                vertexAutocomplete={[
                    {
                        label: TradClassName(objectType.name),
                        vertexType: objectType.name,
                        vertexProperty: propertyOf<T>("Support"),
                    },
                ]}
                customChart={{
                    title: {
                        text: Trad("top_supports"),
                        visible: true,
                        align: "left",
                        font: "bold 14px Arial,Helvetica,sans-serif",
                    },
                    seriesItem: {
                        color: "#009BCE",
                        gap: 0.8,
                    },
                    max: {
                        nb: 5,
                        hide: true,
                    },
                    axis: {
                        kilo: modele.kilo,
                    }
                }}
                type="bar"
                disableCustomLegend
                filters={{ ...(filters as any), Currency: currency?.["@rid"] }}
                dimension={{
                    field: propertyOf<T>("Support"),
                    type: "@rid",
                    linkedClass: objectType.name,
                }}
                Indicateur={indicateur}
                KPIType={eKPIType.Price}
            />
        </div>
    );
};

export function MixMediasChart<T extends IMediaModel>({ objectType }: ChartProps<T>) {
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const modele = useSelector(
        (root: RootState) => root.project.dashboardModele
    );
    const currency = useSelector(
        (root: RootState) => root.project.dashboardModele.currency
    );
    const indicateur = useSelector(
        (root: RootState) => root.project.dashboardModele.indicateur
    );
    const filters = { ...tabFilters, ...modele?.filters };
    return (
        <div className="dashboard-block">
            <Title label={Trad("mix_medias")} />
            <ControllableChart
                key={"mix-medias-" + GetHashCode({ filters, currency })}
                multiple
                vertexAutocomplete={[
                    {
                        label: TradClassName(objectType.name),
                        vertexType: objectType.name,
                        vertexProperty: propertyOf<T>("Media"),
                    },
                ]}
                type="donut"
                disableCustomLegend
                customChart={{
                    legend: {
                        position: "right",
                        orientation: "vertical",
                        labels: {
                            margin: 4,
                            content: (e) => `${(e.percentage * 100).toFixed(2)}%\n${e.text}`,
                        },
                    },
                }}
                filters={{ ...(filters as any), Currency: currency?.["@rid"] }}
                dimension={{
                    field: propertyOf<T>("Media"),
                    type: "@rid",
                    linkedClass: objectType.name,
                }}
                Indicateur={indicateur}
                KPIType={eKPIType.Price}
            />
        </div>
    );
};

export function MonthlyBudgetChart<T extends IDateRangeModel>({ objectType }: ChartProps<T>) {
    const [indicateurs, setIndicateurs] = React.useState<Indicateur[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const modele = useSelector(
        (root: RootState) => root.project.dashboardModele
    );
    const { currency, indicateur } = modele;
    const getIndicateur = async () => {
        const provided = (await AggregatorFactory.GetInstance().Get(objectType).Provide()).map((i) => i.indicateur);
        const month = provided.find((i) => i.name === Trad("month"));
        const year = provided.find((i) => i.name === Trad("year"));
        setIndicateurs([month, year]);
        setLoaded(true);
    };

    const filters = { ...tabFilters, ...modele?.filters };

    let newDateFilters = null;

    if (modele.compareWithPreviewYear) {
        newDateFilters = clone(tabFilters);
        newDateFilters.Start = new Date(new Date(tabFilters.Start).getFullYear() - 1, 0, 1);
        filters.Start = newDateFilters.Start;
    }

    React.useEffect(() => {
        if (!loaded) getIndicateur();
    });

    if (!loaded)
        return (
            <div className="dashboard-block">
                <Loader />;
            </div>
        );

    return (
        <div className="dashboard-block">
            <ControllableChart
                key={"monthly-budget-" + GetHashCode({ filters, currency })}
                multiple
                vertexAutocomplete={[
                    {
                        label: TradClassName(objectType.name),
                        vertexType: objectType.name,
                        vertexProperty: propertyOf<T>("Start"),
                    },
                ]}
                start={newDateFilters ? newDateFilters?.Start : tabFilters?.Start}
                end={tabFilters?.End}
                customChart={{
                    title: {
                        text: `${Trad("monthly_budget")}${modele.compareWithPreviewYear ? " (n-1)" : ""}`,
                        visible: true,
                        align: "left",
                        font: "bold 14px Arial,Helvetica,sans-serif",
                    },
                    seriesItem: {
                        color: "#009BCE",
                    },
                    flatten: modele.compareWithPreviewYear ? false : true,
                    axis: {
                        kilo: modele.kilo,
                    }
                }}
                type="column"
                disableCustomLegend
                filters={{ ...(filters as any), Currency: currency?.["@rid"] }}
                dimension={indicateurs}
                Indicateur={indicateur}
                KPIType={eKPIType.Price}
            />
        </div>
    );
};

export function BudgetCompanyChart<T extends IMediaCompanyModel>({ objectType }: ChartProps<T>) {
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const [companyPropertyName, setCompanyPropertyName] = useState<string>(undefined);

    const modele = useSelector(
        (root: RootState) => root.project.dashboardModele
    );
    const currency = useSelector(
        (root: RootState) => root.project.dashboardModele.currency
    );
    const indicateur = useSelector(
        (root: RootState) => root.project.dashboardModele.indicateur
    );
    const filters = { ...tabFilters, ...modele?.filters };
    const companyDimensions = propertiesOf<T>("AdvCompany_Com", "Agency_Res") as string[];

    React.useEffect(() => {
        if (!companyPropertyName) {
            KPIsManagerCache.GetInstance(objectType.name).GetUserProperties().then(properties => {
                const property = properties.find(p => companyDimensions.includes(p.name));
                setCompanyPropertyName(property.name);
            })
        }
    }, [companyPropertyName]);

    return <div className="dashboard-block">
        {companyPropertyName &&
            <><Title label={TradComposed("budget_company", [TradProp<T>(companyPropertyName)])} />
                <ControllableChart
                    key={"budget-company-" + GetHashCode({ filters, currency })}
                    multiple
                    vertexAutocomplete={[
                        {
                            label: TradClassName(objectType.name),
                            vertexType: objectType.name,
                            vertexProperty: companyPropertyName,
                        },
                    ]}
                    type="donut"
                    disableCustomLegend
                    customChart={{
                        legend: {
                            position: "right",
                            orientation: "vertical",
                            labels: {
                                margin: 4,
                                content: (e) => `${(e.percentage * 100).toFixed(2)}%\n${e.text}`,
                            },
                        },
                        max: {
                            otherWhen: 0.1,
                        },
                    }}
                    filters={{ ...(filters as any), Currency: currency?.["@rid"] }}
                    dimension={{
                        field: companyPropertyName,
                        type: "@rid",
                        linkedClass: objectType.name,
                    }}
                    Indicateur={indicateur}
                    KPIType={eKPIType.Price}
                />
            </>}
    </div>
};

