import * as React from "react";
import { Trad, TradClassName } from 'trad-lib';
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { DateNoZone } from 'tools-lib'
import { DialogStyles, DicoCreateTrad, DicoProgressTrad, DicoTitleTrad, eDialogMode, GenericDialog, GenericDialogProps } from "../../ConfigurableComponents/GenericDialog.bin";
import { FormatDetails, MediaFamilyEditor, PeriodDetails, PeriodicityEditor, SupportDetails, TimeLimits } from '../Generic/ModelForm/ModelDetails'
import { ModelMetrics } from "../Generic/ModelForm/ModelMetrics";
import { Provider, useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../redux/store";
import { clearMessageEditor, setMessage, setMessageSync, toggleRepeat, updateOffer } from "../../../redux/messageEditorSlice";
import { DiscountEditorStep } from "./DiscountEditorStep";
import { Row, Title } from "../../Tools";
import { MessageEditorOverview } from "./MessageEditorOverview";
import { DiffusionValidator, MediaValidator, MetricsValidatior, OfferValidatior, PricingValidatior } from "./MessageEditorValidators";
import { DiffusionPicker } from "./DiffusionPicker";
import Loader from "../../layout/Loader";
import { Switch } from "@progress/kendo-react-inputs";
import { kpis } from "hub-lib/types";
import { Grid } from "@material-ui/core";
import { StartEndEditor } from "../Generic/ModelForm/StartEndEditor";
import { HierarchyAdvertiserPicker } from "../Generic/ModelForm/HierarchyAdvertiserPicker";
import { MandataireEditor } from "../Generic/ModelForm/MandataireEditor";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { CampaignPickerEditor } from "./MessageEditor/CampaignPickerEditor";
import { KPICategory, KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { KeyValidator } from "../../../utils/KeyValidator";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { getIcon, TooltipManager } from "adwone-lib";

export class TProps {
    open: boolean;
    selectedMedia: ref_Media['@rid'];
    handleClose?: () => void;
    canRepeat?: boolean;
    onValidate: (m: ref_Messages) => any;
    message?: ref_Messages;
    importRid?: string;
}

class TState {
    disableCreateButton: boolean = false
    validationMode: boolean = false;
    key?: number;
    needReload?: boolean;
}

type DialogRender = {
    title?: string | JSX.Element,
    children?: any,
    dialogProps?: Partial<GenericDialogProps>
}
function DialogRender({ title, children, dialogProps }: DialogRender) {
    const lockNext = useSelector((root: RootState) => root.messageEditor.lockNext);
    const validationIssues = useSelector((root: RootState) => root.messageEditor.validationIssues);

    return <GenericDialog
        open={dialogProps?.open}
        {...dialogProps}
        disablePrimaryButton={dialogProps?.disablePrimaryButton || lockNext || validationIssues?.length > 0}
        disableCancelButton={dialogProps?.disableCancelButton || lockNext}
        dialogTitle={title}
        actions={true}
        submitClass={'custom_btn_primary_validation'}
        titleClass='display-none'
        id="messages_dialog_createupdate"
        dialogClassname="messages"
        dialogContent={<Provider store={store}>
            {children}
        </Provider>
        }
    >
    </GenericDialog>
}

export function MessagesDialog({ message, selectedMedia, onValidate, importRid, open, handleClose, canRepeat }: TProps) {

    const [state, setState] = React.useState<TState>();
    const { validationMode, disableCreateButton, key, needReload } = state ?? {};
    const mode = useSelector((root: RootState) => root.messageEditor.mode);
    const messageState = useSelector((root: RootState) => Boolean(root.messageEditor.data));
    const repeat = useSelector((root: RootState) => root.messageEditor.repeat);

    const configurelement = (_message: ref_Messages) => {
        _message = _message ?? new ref_Messages();
        if (!_message.Media) _message.Media = selectedMedia;
        if (_message.Start) _message.Start = DateNoZone(_message.Start);
        if (_message.End) _message.End = DateNoZone(_message.End);
        if (_message.CancellationTimeLimit) _message.CancellationTimeLimit = DateNoZone(_message.CancellationTimeLimit);
        if (_message.TechDeliveryTimeLimit) _message.TechDeliveryTimeLimit = DateNoZone(_message.TechDeliveryTimeLimit);
        if (_message.ConfirmationTimeLimit) _message.ConfirmationTimeLimit = DateNoZone(_message.ConfirmationTimeLimit);
        if (!_message.KPIs) _message.KPIs = new kpis();
        return _message;
    }

    React.useEffect(() => {
        if (state) return;

        store.dispatch(clearMessageEditor());
        store.dispatch(setMessage(configurelement(message)));
        setState(new TState());
    }, [Boolean(state)])

    React.useEffect(() => {
        if (repeat && needReload) {
            setState({ ...state, key: (key || 0) + 1, needReload: false })
        }
    });

    if (!state)
        return <></>

    const handleNext = async () => {
        const message = store.getState().messageEditor.get();
        if (!validationMode)
            setState({ ...state, validationMode: true });
        setState({ ...state, disableCreateButton: true });
        if (await onValidate(message)) {
            if (repeat) {
                store.dispatch(setMessage(message));
                setState({ ...state, needReload: true });
            } else {
                setState({ ...state, disableCreateButton: false })
            }
        }
    };

    let otherMetrics: KPICategory[] = ["Pricing", "volumes", "Performance", "PlanPurchased", "Additional", "TechnicalSpecifications", "ExternalReferences"];

    return <DialogRender
        dialogProps={{
            open: open,
            handleClose: () => { handleClose(); store.dispatch(setMessageSync(null)); },
            submitAction: handleNext,
            disablePrimaryButton: disableCreateButton,
            disableCancelButton: disableCreateButton,
            submitTitle: disableCreateButton ? DicoProgressTrad()[mode] : DicoCreateTrad()[mode],
            beforeButton: canRepeat && mode === eDialogMode.create && <RepeatChecker repeat={repeat} handleRepeat={() => {
                store.dispatch(toggleRepeat());
            }} />
        }}>

        {!messageState && <Loader text={Trad('loading')} />}

        {messageState
            && <div className='message_dialog' style={DialogStyles.layout}>
                <div style={DialogStyles.columnOverview}>
                    <MessageEditorOverview >
                        <DialogTitle />
                    </MessageEditorOverview>
                </div>

                <div className='message_dialog_content' style={DialogStyles.columnContent} key={key}>

                    <div id='campaignpicker'>
                        <Title>{Trad('Campaign')}</Title>
                        <Row><CampaignPickerEditor importRid={importRid} validationMode={validationMode} /></Row>
                    </div>
                    <KeyValidator objectType="ref_Campaigns" propertyName="Advertiser" notExists={true}>
                        <div id='advertiserpicker'>
                            <Title>{TradClassName(ref_Advertisers.name)}</Title>
                            <Row>
                                <HierarchyAdvertiserPicker model="ref_Messages" multiAdvertisers={false} estimatedMode={false}
                                    onChange={() => {
                                        //TODO
                                    }} />
                                <MandataireEditor model="ref_Messages" />
                            </Row>
                        </div>
                    </KeyValidator>
                    <MediaValidator>
                        <div id='offerpicker'>
                            <OfferValidatior>
                                <Title>{Trad('Offer')}</Title>
                                <Row>
                                    <KeyValidator objectType="ref_Campaigns" propertyName="Support" notExists={true}>
                                        <Grid item xs={12} className="adw-title">{Trad("support_details")}</Grid>
                                        <Grid container className='block-container'>
                                            <SupportDetails model="ref_Messages" />
                                            <MediaValidator mediaType="PR">
                                                <PeriodicityEditor model="ref_Messages" />
                                            </MediaValidator>
                                            <MediaFamilyEditor model="ref_Messages" />
                                        </Grid>
                                    </KeyValidator>
                                    <Grid item xs={12} id='parutiondetailspicker' className="adw-title">{Trad("parution_details")}</Grid>
                                    <Grid container className='block-container'>
                                        {/* Si la campagne gère le support alors pas de saisie de la période */}
                                        <KeyValidator objectType="ref_Campaigns" propertyName="Support" notExists={true}>
                                            <MediaValidator mediaType="PR">
                                                <Grid item xs={6} className={"message_details_leftcombo"}>
                                                    <StartEndEditor model="ref_Messages" TSMCalendarEnable={true} />
                                                </Grid>
                                                <PeriodDetails model="ref_Messages" rightFirst={true} />
                                            </MediaValidator>
                                            <MediaValidator mediaType="PR" notEquals>
                                                <Grid item xs={12} className={"message_details_full"}>
                                                    <StartEndEditor model="ref_Messages" TSMCalendarEnable={false} />
                                                </Grid>
                                            </MediaValidator>
                                        </KeyValidator>
                                        <FormatDetails model="ref_Messages" />
                                        <ModelMetrics
                                            categories={["Format"]}
                                            model="ref_Messages" title={false} />
                                    </Grid>
                                    <TimeLimits model="ref_Messages" />
                                </Row>
                            </OfferValidatior>
                        </div>
                        <div id='pricingpicker'>
                            <PricingValidatior>
                                <Title><span>{Trad('Pricing')}<RefreshPricing style={{ marginLeft: 5 }} /></span></Title>
                                <Row><DiscountEditorStep /></Row>
                            </PricingValidatior>
                        </div>
                        <div id='metricspicker'>
                            <MetricsValidatior>
                                <Title>{Trad('Other_metrics')}</Title>
                                <Row><ModelMetrics
                                    categories={otherMetrics}
                                    model="ref_Messages" title={true} /></Row>
                            </MetricsValidatior>
                        </div>
                        <div id='diffusionpicker'>
                            <DiffusionValidator>
                                <Title>{Trad('Diffusion')}</Title>
                                <Row><DiffusionPicker /></Row>
                            </DiffusionValidator>
                        </div>
                    </MediaValidator>
                </div>
            </div>}
    </DialogRender>
}

function DialogTitle() {

    const mediaRid = useSelector((root: RootState) => root.messageEditor.data?.Media);
    const mode = useSelector((root: RootState) => root.messageEditor.mode);
    const [medias, setMedias] = React.useState<ref_Media[]>();

    React.useEffect(() => {
        KPIsManagerCache.GetMedias().then(setMedias);
    }, [Boolean(medias)])

    if (!medias) return <></>

    const mediaName = mediaRid ? medias.filter(m => m["@rid"] == mediaRid)[0].Name : undefined;
    //const presseRid = mediaRid ? medias.find(media => media?.Code == "PR")?.["@rid"] : undefined;

    return <Title>
        <span>{DicoTitleTrad("message")[mode]}
            {mediaName && <span className="message_pill_title">{Trad(mediaName)}</span>}
        </span>
    </Title>

}

type RefreshPricingProps = {
    style?: React.CSSProperties,
}
function RefreshPricing({ style }: RefreshPricingProps) {

    const pricingLocked = useSelector((root: RootState) => root.messageEditor.data.PricingLocked);
    const offerEmplGross = useSelector((root: RootState) => root.messageEditor.offer?.KPIs?.Gross ?? null);
    const offerFormatGross = useSelector((root: RootState) => root.messageEditor.offerFormat?.KPIs?.Gross ?? null);
    const messageGross = useSelector((root: RootState) => root.messageEditor.data.KPIs?.Gross ?? null);
    const messageGrossVal = useSelector((root: RootState) => root.messageEditor.data.KPIs?.GrossVal ?? null);
    const unpaidPlacement = useSelector((root: RootState) => !root.messageEditor.data.PaidPlacement);
    const dispatch = useDispatch();

    if (pricingLocked)
        return <></>;

    //console.log("[RefreshPricing]", offerEmplGross, offerFormatGross, messageGross, messageGrossVal);

    if (unpaidPlacement && (offerFormatGross === null || offerFormatGross == messageGross) && (offerEmplGross === null || offerEmplGross == messageGrossVal))
        return <></>;

    if (!unpaidPlacement
        && (offerEmplGross != null && offerEmplGross == messageGross && offerEmplGross == messageGrossVal)
        || (offerEmplGross == null && offerFormatGross != null && offerFormatGross == messageGross && offerFormatGross == messageGrossVal))
        return <></>;

    return <CustomIconButton
        style={style}
        onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("refresh_pricing") })}
        className="custom_btn_secondary popover_button"
        onClick={async () => {
            dispatch(updateOffer(store.getState().messageEditor.get()));
            // const message = store.getState().messageEditor.get();
            // await DiscountManager.initGlobalAgreement(message, { replaceUnlocked: true });
            // dispatch(setMessage(message));
        }}>
        {getIcon("cached")}
    </CustomIconButton>
}

function RepeatChecker({ repeat, handleRepeat }: { repeat: boolean, handleRepeat: () => void }) {
    return <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleRepeat}>
        <Switch
            size='small'
            checked={repeat}
            name={Trad("reapet_checker")} />
        <div>{Trad("reapet_checker")}</div>
    </div>;
}