
export const DefaultDevURLServer = 'http://localhost:5000';
export const DefaultDevURLFront = 'http://localhost:3000';
export const DefaultProdURLServer = 'https://server.adwone.com';
export const AdwoneEnvironments = [
    {
        NODE_ENV: 'development',
        ADWONE_ENV: 'local',
        front: DefaultDevURLFront,
        back: DefaultDevURLServer
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'production-us',
        front: ['https://adwone.us', 'https://www.adwone.us'],
        back: 'https://server.adwone.us'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'uat.production-us',
        front: ['https://uat.adwone.us', 'https://www.uat.adwone.us'],
        back: 'https://server-uat.adwone.us'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'development',
        front: 'https://dev.adwone.com',
        back: 'https://server.dev.adwone.com'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'demo',
        front: 'https://demo.adwone.com',
        back: 'https://server.demo.adwone.com'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'uat',
        front: 'https://uat.adwone.com',
        back: 'https://server.uat.adwone.com'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'production',
        front: ['https://adwone.com', 'https://adwone.fr', 'https://www.adwone.com', 'https://www.adwone.fr'],
        back: DefaultProdURLServer
    }
]

export function GetServerUrlFromFrontUrl(frontUrl: string) {
    return AdwoneEnvironments.find((item) => {
        if (Array.isArray(item.front))
            return item.front.includes(frontUrl)
        return item.front === frontUrl

    })?.back ?? DefaultDevURLServer;
}

export function GetServerUrlFromEnv(nodeEnv: string, adwoneEnv: string) {
    const backUrl = AdwoneEnvironments.find((item) => item.NODE_ENV === nodeEnv && item.ADWONE_ENV === adwoneEnv)?.back;
    return <string>backUrl ?? DefaultDevURLServer;
}

export function GetFrontUrlFromEnv(nodeEnv: string, adwoneEnv: string) {
    const frontUrl = AdwoneEnvironments.find((item) => item.NODE_ENV === nodeEnv && item.ADWONE_ENV === adwoneEnv)?.front;
    if (Array.isArray(frontUrl) && frontUrl.length > 0)
        return frontUrl[0];
    return <string>frontUrl ?? DefaultDevURLFront;
}