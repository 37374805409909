import * as React from "react";
import { compareObjects, duplicate, GetHashCode, hasConfig } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { Badge, BadgeProps } from "@progress/kendo-react-indicators";
import { FiltersComponent, FiltersComponentProps, hasAdvancedFilters } from "./FiltersComponent";
import { ElementArg } from "../Messages/ConfigurationPanel";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { SanitizeFilter } from "hub-lib/models/external.bin";
import { IAdvertiserHierarchy } from "hub-lib/types";

export function MenuItemFilters(objectType: string, options?: FiltersComponentProps): ElementArg {
    return {
        type: "icon",
        title: () => Trad("filters"),
        icon: "filterAlt",
        badge: <FilterBadge objectType={objectType} />,
        element: <FiltersComponent {...options} objectType={objectType} />
    }
}

export function CustomBadge(props?: Partial<BadgeProps>) {
    return <Badge cutoutBorder {...props} align={{ vertical: "top", horizontal: "start" }} />;
}

export function FilterBadge({objectType} : { objectType : string}) {
    const modeleFilters = useSelector((root: RootState) => root.project.filtersModele, compareObjects);
    const config = duplicate(useSelector((root: RootState) => root.project.filters, compareObjects));
    const [sanitizeFilters, setSanitizeFilters] = React.useState<Partial<IAdvertiserHierarchy>>();
    const [sanitizeConfig, setSanitizeConfig] = React.useState<Partial<Filter>>();

    const filters = modeleFilters?.Filters;

    React.useEffect(() => {
        SanitizeFilter(objectType, filters).then(f => setSanitizeFilters(f));
    }, [GetHashCode(filters)]);

    React.useEffect(() => {
        SanitizeFilter(objectType, config).then(f => setSanitizeConfig(f));
    }, [GetHashCode(config)]);

    return hasConfig(sanitizeFilters) || hasAdvancedFilters(sanitizeConfig, true) ? <CustomBadge /> : null;
}