
import Template from '../Template'
import { DataGridComponent } from '../../VertexGrid/Generic/ModeleGrid/DataGridComponent';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { MessagesDialog } from '../../VertexGrid/Messages/MessagesDialog.bin';
import { CreateGrid } from '../../VertexGrid/Generic/ModeleGrid/MessagesGridCreator';
import { KPIsManagerCache } from 'hub-lib/models/KPIsManager.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import React from 'react';


const Messages = () => {
    const [loadMedias, setLoadMedias] = React.useState<boolean>(null);

    React.useEffect(() => {
        if (loadMedias == null)
            KPIsManagerCache.GetInstance(ref_Campaigns.name).GetUserProperties()
                .then(properties => setLoadMedias(!properties.some(p => p.name == "Media")));
    })

    if (loadMedias == null)
        return <></>

    return <div className="grid_container">
    {/* <MessagesGrid/> */}
    <DataGridComponent
        objectType={ref_Messages}
        genre="male"

        loadMedias={loadMedias}
        createGrid={params => CreateGrid({ vertexParams: params })}
        getDialog={(props, state, onValidate, onClose) =>
            <MessagesDialog
                onValidate={onValidate}
                importRid={state?.importRid}
                selectedMedia={state.selectedMedia?.["@rid"]}
                open
                message={state.elementEdited}
                handleClose={onClose}
                canRepeat />
        } />
</div>
}
export default Template(Messages);