
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { db } from './db.bin';
import { Trad } from "../trad-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";

let dico: any = {}

Object.entries(db).forEach(e => {
    let value = {};
    e[1].mandatory.forEach(p => {
        value[p] = (obj: object) => {
            let valid: Validation = {
                isValid: obj.hasOwnProperty(p) && obj[p] !== "",
                errorText: Trad("required"),
                property: p
            }
            return valid;
        }
    });

    dico[e[0]] = value;
});

//SetValidation<ref_Campaigns>(ref_Campaigns, "Name", (c: ref_Campaigns) => { return { isValid: !isEmpty(c.Name), errorText: "Nom requis", property: "Name" } })
SetValidation<ref_Campaigns>(ref_Campaigns, "Advertiser", validAdvertiser);
SetValidation<ref_Campaigns>(ref_Campaigns, "AdvertiserGroup", validGroupAdvertiser);
SetValidation<ref_Campaigns>(ref_Campaigns, "Support", validSupport);

SetValidation<ref_Messages>(ref_Messages, "Advertiser", validAdvertiser);
SetValidation<ref_Messages>(ref_Messages, "AdvertiserGroup", validGroupAdvertiser);

function validGroupAdvertiser(c: ref_Messages | ref_Campaigns) {
    let isValid = (RightManager.GetUser()?.customer?.Activity == 'Selling')
        || !RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read)
        || (!isEmpty(c.Group) && isEmpty(c.Advertiser) && isEmpty(c.AdvertiserGroup))
        || (isEmpty(c.Group) && (!isEmpty(c.Advertiser) || !isEmpty(c.AdvertiserGroup)))
    return {
        isValid,
        errorText: !isValid ? Trad("required") : "",
        property: "AdvertiserGroup"
    }
}

function validAdvertiser(c: ref_Messages | ref_Campaigns, validated?: boolean) {
    let isValid = (RightManager.GetUser()?.customer?.Activity == 'Selling')
        || !RightManager.hasRight(ref_Advertisers.name, eRights.read)
        || (!isEmpty(c.Group) && isEmpty(c.Advertiser) && isEmpty(c.AdvertiserGroup))
        || (isEmpty(c.Group) && (!isEmpty(c.Advertiser) || !isEmpty(c.AdvertiserGroup)))
    return {
        isValid,
        errorText: !isValid ? Trad("required") : "",
        property: "Advertiser"
    }
}

function validSupport(c: ref_Campaigns, validated?: boolean) {
    let isValid = (RightManager.GetUser()?.customer?.Activity != 'Selling') || !isEmpty(c.Support)
    return {
        isValid,
        errorText: !isValid ? Trad("required") : "",
        property: "Support"
    }
}

function isEmpty(value) {
    return (value == null || value === '');
}

class Validation {
    isValid: boolean;
    errorText: string;
    property: string;
}

function SetValidation<TType>(prototype: new () => TType, property: (keyof TType), validator: (data: TType) => Validation) {
    if (!dico[prototype.name])
        dico[prototype.name] = {};

    dico[prototype.name][property] = validator;
}

export function IsValid<TType>(prototype: new () => TType, data: TType, property: (keyof TType)): Validation {
    return dico?.[prototype.name]?.[property]?.(data);
}

export function IsObjectValid<TType>(prototype: new () => TType, data: TType): Validation[] {

    let type = dico?.[prototype.name];
    if (!type) return [];

    return Object.entries(type).map((p: any) => p[1](data))?.filter(p => !p.isValid);
}

export function IsValidProperty(prototype: string, data: any, property: string): Validation {
    return dico?.[prototype]?.[property]?.(data);
}