
import { TextField } from '@material-ui/core';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { Trad } from 'trad-lib';
import { GetEditorState, RootState, SetModelSync, store } from '../../../../redux/store';

type ModelPropertiesEditorArgs = { property: string, label?: string, model: "ref_Messages" | "ref_Campaigns" }
export function ModelPropertiesEditor({ property, label, model }: ModelPropertiesEditorArgs) {

    const propertyValue = useSelector((root: RootState) => GetEditorState(model, root).data?.ModelProperties?.[property]);

    return <TextField
        autoComplete='off'
        style={{ width: '100%' }}
        label={label ?? Trad(`property_${property}`)}
        value={propertyValue ?? ""}
        variant="outlined"
        onChange={(e) => {
            const data = GetEditorState(model, store.getState()).get();
            if (!data.ModelProperties)
                data.ModelProperties = {};
            data.ModelProperties[property] = e.target.value;
            SetModelSync(model, data);
        }}
    />
}