import * as React from 'react'

import { Client } from 'hub-lib/client/client.bin'
import version from '../../../version';
import { Trad } from 'trad-lib';
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { getIcon } from "adwone-lib/index";
import { ref_ReleaseVersion } from 'hub-lib/dto/admin/ref_ReleaseVersion.bin';
import { ref_Attachments } from 'hub-lib/dto/client/ref_Attachments.bin';

export class AboutDialog extends React.Component<any, any>{
  constructor(props: any) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    try {
      const v = await Client.getHubVersion()
      this.setState({
        serverVersion: v?.data,
        clientVersion: version
      })
    } catch (error) {
      console.log(error)
    }


    const currentuser = (await Client.getUser()).user;
    if (currentuser?.customer?.Authorization === "MediaManager") {
      try {
        const v = await Client.getMMVersion()
        this.setState({
          mediaOceanVersion: v?.data?.build,
          mediaOceanLastBuild: v?.data?.buildDate
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  render() {
    let title = <div>{Trad("about_adwone")}<span onClick={this.props.onClose} style={{ float: 'right', margin: '0 5px', color: '#DDDDDD', cursor: 'pointer' }}>{getIcon("close")}</span></div>
    return (
      <GenericDialog
        dialogTitle={title}
        open={this.props.open}
        handleClose={this.props.onClose}
        id='about_dialog'
        dialogContent={
          <>
            <div style={{ width: '100%', minHeight: 30, borderBottom: "1px solid #DDDDDD" }}>
              <span style={{ width: "70%", float: 'left' }}>{Trad("client_version")}</span>
              <span style={{ width: "30%", float: 'right', textAlign: 'right' }}>{this.state.clientVersion}</span>
            </div>
            <div style={{ width: '100%', minHeight: 30, marginTop: 10, borderBottom: "1px solid #DDDDDD" }}>
              <span style={{ width: "70%", float: 'left' }}>{Trad("server_version")}</span>
              <span style={{ width: "30%", float: 'right', textAlign: 'right' }}>{this.state.serverVersion}</span>
            </div>
            {this.state.mediaOceanVersion && <div style={{ width: '100%', minHeight: 30, marginTop: 10 }}>
              <span style={{ width: "70%", float: 'left' }}>{Trad("mediaocean_version")}</span>
              <span style={{ width: "30%", float: 'right', textAlign: 'right' }}>{this.state.mediaOceanVersion}</span>
            </div>}
            {this.state.mediaOceanLastBuild && <div style={{ width: '100%', minHeight: 30, marginTop: 0 }}>
              <span style={{ width: "70%", float: 'left' }}>{Trad("mediaocean_lastBuild")}</span>
              <span style={{ width: "30%", float: 'right', textAlign: 'right' }}>{this.state.mediaOceanLastBuild}</span>
            </div>}
            <br />
            {/* <PatchNotes /> */}
          </>
        }
      ></GenericDialog>
    )
  }
}

const PatchNotes = () => {
  const [notes, setNotes] = React.useState<(ref_ReleaseVersion & { img: string })[]>();
  const getNotes = async () => {
    const notes = (await Client.searchVertex(ref_ReleaseVersion.name, { 'Active': true }))?.data?.results;
    for (const note of notes) {
      if (note.Image) {
        const [Image] = await Client.searchVertexTyped(ref_Attachments, { '@rid': note.Image });
        if (Image)
          note.img = await Client.getURLAttachmentFile({ _id: Image.Content })
      }
    }
    setNotes(notes.sort((a, b) => Date.parse(b.Date) - Date.parse(a.Date)))
  }
  React.useEffect(() => {
    getNotes();
  }, [])

  const handleDownload = async (filename, id) => {
    try {
      await Client.dowloadAttachmentFile({ filename, _id: id })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div >
      <div style={{ textAlign: 'center' }}>
        <h1 >NOTES DE PATCH</h1>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {notes?.map((note) => (
          <div className='patch_note' key={note['@rid']}>
            <div style={{ maxWidth: '30vh', maxHeight: '30vh', marginBottom: 12 }} className='patch_image'>
              <img style={{ maxWidth: 'inherit', maxHeight: 'inherit' }} src={note.img} alt="image" />
            </div>
            <div style={{ marginBottom: 12 }} className="patch_name">
              {note.Name}
            </div>
            <div style={{ marginBottom: 12 }} className="patch_date">
              {new Date(note.Date).toLocaleDateString()}
            </div>
            <div style={{ marginBottom: 12 }} className="patch_file">
              <button onClick={async () => {
                const [Note] = await Client.searchVertexTyped(ref_Attachments, { '@rid': note.Note });
                handleDownload(note.Name + '_patchnotes.pdf', Note?.Content)
              }}>Télécharger</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
