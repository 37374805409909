
import * as React from 'react'

import { Trad, TradProp } from 'trad-lib';
import { clone, compareObjects, memoizeAsyncBase, propertyOf } from 'hub-lib/tools.bin';
import { Row } from 'adwone-engine/types.bin';

import {
    GanttTextFilter
} from '@progress/kendo-react-gantt';
import { GanttColumnProps } from '@progress/kendo-react-gantt/dist/npm/interfaces/GanttColumnProps';
import { CustomGantt } from '../Gantt/CustomGantt.bin';
import { CrossedTableTelerikTree, minFrozenCell } from './CrossedTableTelerikTree.bin';
import { getWeekNumber } from 'tools-lib';
import Loader from '../layout/Loader';
import { ADWProperty } from 'hub-lib/types';
import { TreeListCell } from '@progress/kendo-react-treelist';
import { recurse } from 'tools-lib';
import { FooterRowSize, styleGridContainer } from '../../styles/theme';
import { FooterCellTotalElement } from '../VertexGrid/Generic/AdwTelerikGrid.bin';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../redux/store';
import { BadgeTemplate } from './scheduler/GroupComponent';
import { IRid } from 'hub-lib/models/IRid.bin';
import { Client } from 'hub-lib/client/client.bin';
import { Format } from 'format-lib/index.bin';
import { getIcon } from '../ConfigurableComponents/IconManager.bin';
import { setSchedulerTemplate } from '../../redux/gridSlice';
import { Indicateur } from 'adwone-engine/index.bin';
import { FilterStorage } from '../../utils/localstorage.bin';
import { customWidths } from './CrossedTable.bin';

let tooltipid = 0
type RowExtended<TData> = {
    id: any;
    baseRow: Row<TData>;
    level: number;
    [prop: string]: any;
}
type RowWrapper<TData> = Row<TData> & RowExtended<TData>;


type TProps<TData> = {
    autoResize?: boolean;
    viewMode?: "Day" | "Week";
    renderGroup?: (arg: RowWrapper<TData>) => JSX.Element
    renderMessage?: (arg: RowWrapper<TData>) => JSX.Element;
    height?: number | string;
    loaderHeight?: number | string;
}

export class GanttTelerik<TData> extends CrossedTableTelerikTree<TProps<TData>, TData> {

    gantt: CustomGantt<RowWrapper<TData>>;

    drawTr = (tr: Element) => {
        this.drawHeader(tr);
    }

    drawHeader = (tr: Element) => {
        let lastLine: any = tr.children[tr.childElementCount - 1];
        if (lastLine.className.includes('edit-column-cell'))
            lastLine = tr.children[tr.childElementCount - 2];

        lastLine.style.padding = '0px';
    }

    getComponentData = () => {
        return this.gantt?.props?.taskData;
    }

    /**
     * first columns, (frozen)
     * @param rowventils
     */
    private createGanttColumnsFrozen(rowventils: (ADWProperty | Indicateur)[]): (GanttColumnProps & { frozen: boolean })[] {
        return rowventils?.map(r => ({ name: (r as Indicateur)?.name, field: r.field })).map((row, i) => {
            const title = row?.name ?? TradProp(row.field as any);
            let col: GanttColumnProps & { frozen: boolean } = {
                field: this.frozenKey(i),
                title: title,
                width: minFrozenCell,
                expandable: true,
                filter: GanttTextFilter,
                frozen: true,
                cell: (props: any) => <td
                    className={props.className}
                    style={props.style}
                    colSpan={props.colSpan}
                    onClick={(e) => {
                        if ((e.target as any).tagName != "SPAN")
                            this.onRowClicked(props.dataItem)
                    }}>
                    <TreeListCell {...props} style={{ display: "contents" }} />
                </td>
            };
            return col;
        });
    }

    getTableComponent() {
        return document.getElementsByClassName('k-table-layout-fixed')[0];
    }

    getFlatElementIndex(n: number) {
        return super.getFlatElementIndex(n, this.gantt.Rows)
    }

    AdaptRows(rows: Row<TData>[]): RowWrapper<TData>[] {
        const adapted = super.AdaptRows(rows);
        recurse(adapted, "Children", (e) => {
            if (e.Start && new Date(e.Start).getTime() < new Date(this.state.crossedTable.Start).getTime()) {
                e.Start = this.state.crossedTable.Start;
            }
        });

        return adapted;
    }

    createTreeList = (rows: Row<TData>[], columns: any[], rowventils: (ADWProperty | Indicateur)[]) => {
        let { loaderHeight } = this.props;

        if (!this.state?.crossedTable)
            return <div className={`loader-container`} style={{ height: `${loaderHeight ?? ''}` }}>
                <Loader></Loader>
            </div>

        const colFrozen: any[] = this.createGanttColumnsFrozen(rowventils);
        let allCol: any[] = colFrozen?.concat(columns);

        allCol?.forEach(c => {
            if (typeof c.width === 'number')
                c.width = `${c.width}px`;
        });

        // re set width
        allCol?.forEach(c => {
            if (c?.field && customWidths?.[c.field])
                c.width = customWidths[c.field];
        });

        if (allCol && this.props.selectable) {
            allCol = [this.CreateSelectionColumn(), ...allCol];
        }
        let { viewMode, height } = this.props;

        const data = this.myFilterData(this.AdaptRows(this.sortData(rows)))

        let startDate = this.state.crossedTable.Start
        let endDate = this.state.crossedTable.End;

        if (this.props.autoResize) {
            const filters = FilterStorage.getAdvancedFilters();

            const filteredData = data.filter(d => {
                return new Date(d.Start).getTime() >= new Date(filters.Start).getTime() && new Date(d.Start).getTime() <= new Date(filters.End).getTime();
            });
            console.log({ filteredData })

            if (filteredData?.length > 0) {
                startDate = new Date((filteredData.reduce((a, b) => (a.Start < b.Start ? a : b))).Start);
                endDate = new Date((filteredData.reduce((a, b) => (a.End > b.End ? a : b))).End);
                if (endDate.getTime() > new Date(filters.End).getTime()) {
                    endDate = new Date(filters.End)
                }
            }
        }

        if (!viewMode) viewMode = "Day";
        const gantHeight = height ?? styleGridContainer.messages.height
        return (<div style={{ overflow: 'hidden' }}>
            <CustomGantt key={`gantttelerik-v-${this.state.version}`}

                ref={(gantt) => {
                    if (!gantt) return;
                    this.gantt = gantt;
                    if (this.props.editable) {
                        this.AddEditColumn();
                    }
                    this.customizeHeaderFilters();
                }}

                onColumnResize={(e) => this.onResizeColumns(e.columns)}
                selectable={this.props.selectable}

                start={startDate}
                end={endDate}

                renderEvent={this.props.renderMessage}
                renderGroup={this.props.renderGroup}
                viewMode={viewMode}

                style={{ ...styleGridContainer.messages, overflow: 'auto', height: gantHeight }}

                reorderable={true}
                resizable={true}
                sortable={true}

                sort={this.sort}
                filter={this.filter}
                onSortChange={(e) => this.onSortChange(rows, e)}
                onFilterChange={(e) => this.onFilterChange(rows, e)}
                onExpandChange={this.onExpandChange}

                taskData={data}
                taskModelFields={{
                    children: propertyOf<Row<TData>>("Children"),
                    isExpanded: 'expanded',
                    start: 'Start',
                    end: 'End',
                }}
                taskFomaters={{
                    month: (d: Date) => Trad(`month_${d.getMonth()}`),
                    day: (d: Date) => Trad(`day_${d.getDay()}`)[0] + (d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()),
                    week: (d: Date) => `${Trad('week_short')} ${getWeekNumber(d)}`
                }}

                columns={allCol}
                rowHeight={62}

            />

            <SchedulerLegend />
            <FooterCellTotalElement count={rows.map(r => r.CountData ?? 0).reduceRight((acc, cur) => acc + cur, 0)} />
        </div>
        );
    }
}

function SchedulerLegend() {

    const dataStyles = useSelector((root: RootState) => root.grid.dataStyles, compareObjects);
    return <div className='scheduler-legend' style={{ position: 'relative', height: FooterRowSize }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>

            <div
                className={`legend-group`}
                onClick={() => {
                    const template = clone(store.getState().grid.schedulerTemplate);
                    const someHidden = Object.values(dataStyles).some(v => v.textStyle.hidden);
                    const someVisible = Object.values(dataStyles).some(v => !v.textStyle.hidden);
                    const hide = someHidden ? false : someVisible;

                    Object.entries(dataStyles).forEach(([key, value]) => {
                        template.Style[key].textStyle.hidden = hide;
                    });

                    store.dispatch(setSchedulerTemplate(template));
                }}>
                <BadgeTemplate style={{ color: 'gray', background: 'gray' }} />
            </div>


            {Object.entries(dataStyles).map(([key, value]) => {
                const hidden = value?.textStyle?.hidden;
                return <div key={`legend-badge-${key}`}
                    className={`legend-group ${hidden ? 'hidden-scheduler-text-container' : ''}`}
                    onClick={() => {
                        const template = clone(store.getState().grid.schedulerTemplate);
                        template.Style[key].textStyle.hidden = !value?.textStyle?.hidden;
                        store.dispatch(setSchedulerTemplate(template));
                    }}>
                    <BadgeTemplate style={{ color: value?.textStyle?.color?.code, background: value?.textStyle?.color?.code }} />
                    {!hidden && <div className='legend-checked'> {getIcon('check')} </div>}
                    <LabelItem rid={key} type={value.type} />
                </div>
            }
            )}
        </div>
    </div>
}

const getItem = memoizeAsyncBase((linkClass: string, params: any) => Client.searchVertex(linkClass, params).then(res => res.data.results?.[0] as IRid));
type LabelItemProps = { rid: IRid['@rid'], type: string }
function LabelItem({ rid, type }: LabelItemProps) {
    const [item, setItem] = React.useState<IRid>();

    React.useEffect(() => {
        if (!rid || item) return;
        getItem(type, { '@rid': rid }).then(setItem);
    });

    const label = item ? Format(item) : '';
    return <span className='label-element-legend'>
        {label}
    </span>

}