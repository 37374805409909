import { eIndicateurType, Indicateur, IndicateurComputed, IndicateurInfo, IndicateurJoin, IndicateurKPI } from "adwone-engine/index.bin";
import { Sort } from "format-lib/index.bin";
import { eDiscountOptionType, eDiscountOptionValue } from "hub-lib/models/external.bin";
import { eKPI, eKPIType, KPIsManager, KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { distinct, ePropertyOptionFilter, Typed } from "hub-lib/tools.bin";
import { FormatPropertyName, GetCurrentLocale, SetCurrentLocale, Trad, TradProp } from "trad-lib";
import { eColumnType } from "../models/types.bin";
import { AggregatorBase } from "./AggregatorBase";
import { cumulIndicateurArgs, IndicateurOption } from "./AggregatorFactory";
import { GetCOFOIndicateurDiscounts, GetComputedKpiPriceIO, GetComputedIO, GetAdCreationII, GetPlacementCategoryII, GetEstimateIIs, GetDuplicatesIO, GetNumberElementsIO, GetAdvCompanyGroupIOs, GetTimeIOs, GetCampaignKPI_IOs, GetFiscalMonthIO } from "./IndicateurListing";
import { ref_Campaigns } from "../dto/client/ref_Campaigns.bin";

/**
 * Indicateurs possibles à mettre dans un tableau de ref_Messages
 */
export class MessageAggregator extends AggregatorBase<ref_Messages> {

    protected async GetDefaultColumnNames() {
        return [
            TradProp("Start", this._objectType),
            TradProp("End", this._objectType),
            TradProp("Status", this._objectType),
            TradProp("Media", this._objectType),
            TradProp("Support", this._objectType),
            TradProp("BroadcastArea", this._objectType),
            TradProp("AdvCompany_Com", this._objectType),
            TradProp("Campaign", this._objectType),
            TradProp("AdvertiserGroup", this._objectType),
            TradProp("Advertiser", this._objectType),
            TradProp("Brand", this._objectType),
            TradProp("Product", this._objectType),
            TradProp("Format", this._objectType),
            TradProp("Placement", this._objectType),
            TradProp("Currency", this._objectType),
            Trad("Brut"),
            Trad("Net"),
            Trad("netCoFTHono"),
            Trad("rate_net-bv"),
            TradProp("Created_by", this._objectType)
        ];
    }

    protected async GetDefaultVentilationNames() {
        return [
            TradProp("Campaign", this._objectType)]
    }

    constructor() {
        super(ref_Messages);
    }

    protected GetForcedIndicateurs() {
        return {
            'ModelProperties.MediaFamily': this.GetMediaFamilyII(),
            'ModelProperties.AdCreation': GetAdCreationII(),
            'ModelProperties.PlacementCategory': GetPlacementCategoryII(),
            'ModelProperties.Periodicity': this.GetPeriodicityII(),
            'Deversement.Estimate': GetEstimateIIs()
        }
    }

    public async MetadataToIndicateurOptions() {
        const dTypes = Sort(ref_DiscountTypes.name, await this.getDiscountTypes());
        const freeDiscountType = dTypes.find(t => t.Name == "Gracieux");

        let propertyIOs: IndicateurOption[] = await super.MetadataToIndicateurOptions();

        // Indicateur @rid
        propertyIOs.push(this.GetModelRidIO());
        // Indicateur: Type de gracieux
        propertyIOs.push({
            indicateur: {
                type: eIndicateurType.info,
                name: Trad(`Type de gracieux`),
                valueType: eKPIType.Rid,
                field: "Discounts.DiscountClass",
                options: {
                    match: [{
                        subProperty: "Discounts",
                        value: {
                            DiscountType: freeDiscountType["@rid"]
                        }
                    }, {
                        subProperty: "Discounts",
                        filter: ePropertyOptionFilter.DiscountNotEmpty
                    }]
                }
            },
            columnType: eColumnType.Property
        });
        // Format Couleur
        propertyIOs.push(Typed<IndicateurOption>({
            indicateur: Typed<IndicateurJoin>({
                name: `${Trad("Format")} ${Trad("Couleur")}`,
                valueType: eKPIType.String,
                type: eIndicateurType.join,
                indicateurs: propertyIOs.map(p => p.indicateur).filter(i => i.field == "Format" || i.field == "ModelProperties.Couleur")
            }),
            columnType: eColumnType.Property
        }));
        //Mois fiscal
        propertyIOs.push(GetFiscalMonthIO());
        // Groupes Régies
        if (propertyIOs.some(p => KPIsManager.AdvCompanyProperties.includes(p.indicateur.field)))
            propertyIOs = [...propertyIOs, ...GetAdvCompanyGroupIOs()];

        return [...propertyIOs, ...GetTimeIOs()];
    }

    protected async KPIToIndicateurOptions(kpiType?: eKPIType): Promise<IndicateurOption[]> {
        let kpiIOs: IndicateurOption[] = await super.KPIToIndicateurOptions(kpiType);
        if (!kpiType) {
            const campaignKpis = await KPIsManagerCache.GetInstance(ref_Campaigns.name).GetUserKPIs();
            if (campaignKpis.some(k => k.Name == eKPI.Budget))
                kpiIOs.push(GetCampaignKPI_IOs(eKPI.Budget, { valueType: eKPIType.Price, options: { aggregate: true, groupBy: true } }));
            if (campaignKpis.some(k => k.Name == eKPI.Pagination))
                kpiIOs.push(GetCampaignKPI_IOs(eKPI.Pagination, { options: { groupBy: true } }));
        }
        return kpiIOs;
    }

    protected async BarterToIndicateurOptions(barterIndicateur: Indicateur): Promise<IndicateurOption[]> {
        const [dtBarter] = await this.getDiscountTypes((t) => t.Name == "Barter");
        const discountsBarter = Sort(ref_DiscountClasses.name, await this.getDiscountClasses(c => c.DiscountType == dtBarter["@rid"]));

        const barter_IOs = discountsBarter.flatMap(d => {
            const rate_IO = {
                indicateur: {
                    type: eIndicateurType.discount,
                    valueType: eKPIType.Percent,
                    name: FormatPropertyName(`${Trad("Barter")} ${Trad("rate")} ${Trad(d.Name)}`),
                    options: { rid: d["@rid"], barter: true, value: eDiscountOptionValue.Rate, type: eDiscountOptionType.CO }
                },
                columnType: eColumnType.Barter
            };
            const amount_IO = {
                indicateur: Typed<IndicateurComputed>({
                    type: eIndicateurType.computed,
                    valueType: eKPIType.Price,
                    name: FormatPropertyName(`${Trad("Barter")} ${Trad("amount")} ${Trad(d.Name)}`),
                    operator: "*",
                    indicateurs: [
                        rate_IO.indicateur,
                        barterIndicateur
                    ]
                }),
                columnType: eColumnType.Barter
            }
            return [rate_IO, amount_IO];
        });

        return barter_IOs;
    }

    protected async GrossCPMToIndicateurOptions(grossCPM_I: Indicateur, gross_I: Indicateur): Promise<Indicateur> {
        const mgrMessage = KPIsManagerCache.GetInstance(ref_Messages.name);
        const mgrCampaign = KPIsManagerCache.GetInstance(ref_Campaigns.name);
        const hasPerformances = await mgrMessage.HasPerformances();
        let lnks = [];
        let indicateurKpi_field = "KPIs";
        let indicateurKpi_subProperty = undefined;
        if (hasPerformances)
            lnks = await mgrMessage.GetCategoryLnkHasKPIs("Performance");
        else {
            lnks = await mgrCampaign.GetCategoryLnkHasKPIs("Performance");
            indicateurKpi_field = "Campaign";
            indicateurKpi_subProperty = "KPIs";
        }

        return Typed<IndicateurComputed>({
            name: grossCPM_I.name,
            valueType: grossCPM_I.valueType,
            operator: "/",
            type: eIndicateurType.computed,
            options: {
                rate: 1000,
                match: [
                    { subProperty: "IsGrossCPM", value: true },
                    { subProperty: "KpiCPM", filter: ePropertyOptionFilter.DistinctIsUnique }]
            },
            indicateurs: [
                gross_I,
                Typed<IndicateurKPI>({
                    type: eIndicateurType.kpi,
                    valueType: eKPIType.Number,
                    name: "",
                    field: indicateurKpi_field,
                    options: {
                        subProperty: indicateurKpi_subProperty,
                        subPropertyValueAsKey: "KpiCPM",
                        mapValueTo: lnks.map(l => ({ key: l.KPI, value: l.Id })).reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})
                    }
                })
            ]
        })
    }

    Provide: () => Promise<IndicateurOption[]> = async () => {
        const kpis = await KPIsManagerCache.GetInstance(this._objectType.name).GetUserKPIs();
        const lnk_kpis = await KPIsManagerCache.GetInstance(this._objectType.name).GetLnkHasKPIs();
        const discounts = Sort(ref_DiscountClasses.name, await this.getDiscountClasses());

        /** Propriétés du message */
        const propertyIOs: IndicateurOption[] = await this.MetadataToIndicateurOptions();

        /** Model KPIs */
        const kpiIOs: IndicateurOption[] = (await this.KPIToIndicateurOptions())
            .filter(io => io.indicateur.field != "TotalCount");

        /** Remises */
        const discountIOs: IndicateurOption[] = await this.DiscountToIndicateurOptions();

        /** Barter */
        const kpiBarterIO = kpiIOs.find(io => io.indicateur.field == "Barter");
        const barterIOs: IndicateurOption[] = await this.BarterToIndicateurOptions(kpiBarterIO.indicateur);

        /** Indicateurs calculés */
        //Liste des Kpis pour le calcul du Ct/0000
        //IsGrossCPM
        //Gross
        //KpiCPM
        //KPIs[KpiCPM]


        //const kpiPerformances = kpiIOs.find(io =>  io.indicateur.field == "Gross");
        //const kpiGrossIO = kpiIOs.find(io => io.indicateur.field == "Gross");


        const totalTaxes: IndicateurOption = await this.DiscountToCumulIndicateurOption("Taxes", Trad("total_taxes"));
        const totalHonoCO: IndicateurOption = await this.DiscountToCumulIndicateurOption("Honoraires", Trad("total_honoraires"));
        const totalHonoFO: IndicateurOption = await this.DiscountToCumulIndicateurOption("Honoraires", Trad("total_honoraires"), { discountOptions: { type: eDiscountOptionType.FO } });
        const totalFrais: IndicateurOption = await this.DiscountToCumulIndicateurOption("Frais", Trad("total_frais"));
        const totalFraisFO: IndicateurOption = await this.DiscountToCumulIndicateurOption("Frais", Trad("total_frais"), { discountOptions: { type: eDiscountOptionType.FO } });

        const computedIOs: IndicateurOption[] = [totalTaxes, totalHonoCO, totalFrais];


        const kpiGrossCPMIO = kpiIOs.find(io => io.indicateur.field == "GrossCPM");
        const kpiGrossIO = kpiIOs.find(io => io.indicateur.field == "Gross");

        kpiGrossCPMIO.indicateur = await this.GrossCPMToIndicateurOptions(kpiGrossCPMIO.indicateur, kpiGrossIO.indicateur);
        // computedIOs.push(grossCPMComputeIO);

        const totalCOKpi = kpis.find(k => k.Name === "Total");
        const totalCOLnkKpi = lnk_kpis.find(lnk => lnk.KPI === totalCOKpi["@rid"]);

        const totalFOKpi = kpis.find(k => k.Name === "Total FO");
        const totalFOLnkKpi = lnk_kpis.find(lnk => lnk.KPI === totalFOKpi["@rid"]);

        const netKpi = kpis.find(k => k.Name === "Net");
        const netLnkKpi = lnk_kpis.find(lnk => lnk.KPI === netKpi["@rid"]);

        const netKpiFo = kpis.find(k => k.Name === "Net FO");
        const netLnkKpiFO = lnk_kpis.find(lnk => lnk.KPI === netKpiFo["@rid"]);

        const honoIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "Honoraires");
        const honoFTIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "Honoraires sur frais techniques");
        const tvaIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "TVA");
        const fpIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "Frais de production");
        const fcIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "Frais de création");

        // Total CO Hors Honos, Hors Frais de Production, Hors Frais de Création
        computedIOs.push(GetComputedKpiPriceIO("total", totalCOLnkKpi, "totalCOHonoFPFC", "-",
            [totalHonoCO.indicateur, fpIOs.CO, fcIOs.CO, tvaIOs.CO]));

        // Total FO Hors Honos, Hors Frais de Production, Hors Frais de Création
        computedIOs.push(GetComputedKpiPriceIO("totalFo", totalFOLnkKpi, "totalFOHonoFPFC", "-",
            [totalHonoFO.indicateur, fpIOs.FO, fcIOs.FO, tvaIOs.FO]));

        // Net CO + FT + hono
        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpi, "netCoFTHono", "+",
            [totalFrais.indicateur, honoIOs.CO, honoFTIOs.CO]));

        // Net FO + FT hors hono
        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpiFO, "netFoFT", "+",
            [totalFraisFO.indicateur]));

        // Net CO + FT hors hono
        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpi, "netCoFT", "+",
            [totalFrais.indicateur]));

        // Net CO + hono
        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpi, "netCoHono", "+",
            [honoIOs.CO]));

        // Net FO + hono
        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpiFO, "netFoHono", "+",
            [honoIOs.FO]));

        // Net FO + FT Hono inclus
        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpiFO, "netFoFTHono", "+",
            [totalFraisFO.indicateur, honoIOs.FO, honoFTIOs.FO]));

        // Net FO TTC hono inclus
        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpiFO, "netFoTTC", "+",
            [totalHonoFO.indicateur, tvaIOs.FO]));

        // Total Frais techniques Hono inclus
        computedIOs.push(GetComputedIO(eKPIType.Price, "ftHono", "+", [totalFrais.indicateur, honoIOs.CO, honoFTIOs.CO]));

        //Nombre Total
        const numberTotal = GetComputedIO(eKPIType.Decimal, "Nombre total", "+",
            [
                kpiIOs.find(p => p.indicateur.name == Trad("Nombre gracieux")).indicateur,
                kpiIOs.find(p => p.indicateur.name == Trad("Nombre payant")).indicateur
            ])
        computedIOs.push(numberTotal)

        computedIOs.push(GetComputedIO(eKPIType.Decimal, "PMP CO", "/", [
            kpiIOs.find(io => io.indicateur.field == "NetCO").indicateur,
            numberTotal.indicateur
        ]))

        computedIOs.push(GetComputedIO(eKPIType.Decimal, "PMP FO", "/", [
            kpiIOs.find(io => io.indicateur.field == "NetFO").indicateur,
            numberTotal.indicateur
        ]))

        //CPM
        let cpmIOs: IndicateurOption[] = await this.GetCPMIndicateurOptions();

        computedIOs.push({
            indicateur: await this.CreateCompareIndicateur(TradProp("compliance_placement_pige", ref_Messages), "ModelProperties.BroadcastPlacement", "Placement"),
            columnType: eColumnType.KPI
        });

        /** KPIs déversés */
        let boundIOs: IndicateurOption[] = [];
        if (propertyIOs.some(p => p.indicateur.field == "Deversement.Estimate"))
            boundIOs = [
                ...await this.KPIToIndicateurOptions(eKPIType.PriceBound),
                ...this.GetDiscountIO_Typed(discountIOs, eKPIType.PriceBound)];

        /** KPIs en devise restituée */
        let returnedIOs: IndicateurOption[] = [];
        if (propertyIOs.some(p => p.indicateur.field == "ReturnedCurrency")) {
            returnedIOs = [
                ...await this.KPIToIndicateurOptions(eKPIType.PriceReturned),
                ...this.GetDiscountIO_Typed(discountIOs, eKPIType.PriceReturned)];

            const optionsRet: cumulIndicateurArgs = {
                computedOptions: { isPriceReturned: true },
                discountOptions: { isPriceReturned: true }
            }
            returnedIOs.push(await this.DiscountToCumulIndicateurOption("Taxes", `${Trad("total_taxes")} ${Trad("returned")}`, optionsRet));
            returnedIOs.push(await this.DiscountToCumulIndicateurOption("Honoraires", `${Trad("total_honoraires")} ${Trad("returned")}`, optionsRet));
            returnedIOs.push(await this.DiscountToCumulIndicateurOption("Frais", `${Trad("total_frais")} ${Trad("returned")}`, optionsRet));

            cpmIOs = [...cpmIOs, ...await this.GetCPMIndicateurOptions(eKPIType.PriceReturned)];

            returnedIOs.push({
                columnType: eColumnType.Property,
                indicateur: Typed<IndicateurKPI>({
                    name: Trad("returnedCurrency_rate"),
                    valueType: eKPIType.FullDecimal,
                    type: eIndicateurType.kpi,
                    field: netLnkKpi.Id,
                    options: { rid: undefined, isPriceReturned: true, forceValue: 1 }
                })
            });
        }

        /** Taux global BBA/BV */
        const ratioIOs: IndicateurOption[] = [
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-b"), "Net", "Brut"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_bba-bv"), "Brut Base Achat", "Brut Valorisé"), columnType: eColumnType.KPI },

            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-bv"), "Net", "Brut Valorisé"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-bv_fo"), "Net FO", "Brut Valorisé"), columnType: eColumnType.KPI },

            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-bba"), "Net", "Brut Base Achat"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-bba_fo"), "Net FO", "Brut Base Achat"), columnType: eColumnType.KPI },

            { indicateur: await this.CreateRatioIndicateur(Trad("rate_total-bba"), "Total", "Brut Base Achat"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_total-bba_fo"), "Total FO", "Brut Base Achat"), columnType: eColumnType.KPI },

            { indicateur: await this.CreateRatioIndicateur(Trad("rate_total-bv"), "Total", "Brut Valorisé"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_total-bv_fo"), "Total FO", "Brut Valorisé"), columnType: eColumnType.KPI },
        ]

        let contents: IndicateurOption[] = [
            ...propertyIOs,
            ...kpiIOs,
            ...discountIOs,
            ...barterIOs,
            ...computedIOs,
            ...cpmIOs,
            ...boundIOs,
            ...returnedIOs,
            ...ratioIOs,
            GetDuplicatesIO(),
            GetNumberElementsIO()
        ];

        return this.Sort_IO(contents);
    }

    ProvideLEGOIndicateurs: () => Promise<Indicateur[]> = async () => {

        const localeSaved = GetCurrentLocale();
        await SetCurrentLocale("engine");

        const filterKpis = [eColumnType.KPI, eColumnType.Barter, eColumnType.PriceReturned, eColumnType.Property];
        const indicateurs = (await this.Provide()
            .then(inds => inds.filter(i => filterKpis.includes(i.columnType) && !i.indicateur.options?.["formater"])))
            .map(i => i.indicateur);

        // pour les indicateurs de type KPI on prend l'id en name
        indicateurs.forEach(i => {
            if (i.field) {
                const fullField = i.field + (i.options?.['subProperty'] ? ('.' + i.options?.['subProperty']) : '');
                i.name = FormatPropertyName(fullField, fullField.includes("ModelProperties"));
            }
        });

        const indicateurIds = distinct(indicateurs
            .filter(i => i.valueType == eKPIType.Rid)
            .map(i => ({
                ...i,
                valueType: eKPIType.String,
                name: (i.field ? FormatPropertyName(i.field, i.field.includes("ModelProperties")) : i.name) + '_id',
                options: null
            })), i => i.name);

        const indicateurId: Indicateur = {
            type: eIndicateurType.info,
            name: 'ID',
            field: '@rid',
            valueType: eKPIType.String
        }


        await SetCurrentLocale(localeSaved);
        const columns = [indicateurId, ...indicateurIds, ...indicateurs];
        return columns;
    }

    async CreateRatioIndicateur(title: string, kpi1: string, kpi2: string) {

        const kpis = await KPIsManagerCache.GetInstance(this._objectType.name).GetUserKPIs();
        const lnk_kpis = await KPIsManagerCache.GetInstance(this._objectType.name).GetLnkHasKPIs();

        const Kpi1 = kpis.find(k => k.Name === kpi1);
        const LnkKpi1 = Kpi1 && lnk_kpis.find(lnk => lnk.KPI === Kpi1["@rid"]);

        const Kpi2 = kpis.find(k => k.Name === kpi2);
        const LnkKpi2 = Kpi2 && lnk_kpis.find(lnk => lnk.KPI === Kpi2["@rid"]);

        return Typed<IndicateurComputed>({
            name: title,
            valueType: eKPIType.Percent,
            operator: "%",
            type: eIndicateurType.computed,
            indicateurs: [
                Typed<IndicateurKPI>({
                    name: "net",
                    valueType: eKPIType.Price,
                    type: eIndicateurType.kpi,
                    field: LnkKpi1?.Id ?? kpi1,
                    options: { rid: Kpi1?.["@rid"] }
                }),

                Typed<IndicateurKPI>({
                    name: "net",
                    valueType: eKPIType.Price,
                    type: eIndicateurType.kpi,
                    field: LnkKpi2?.Id ?? kpi2,
                    options: { rid: Kpi2?.["@rid"] }
                }),
            ]
        });
    }
}
