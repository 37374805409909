import * as React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Trad } from 'trad-lib';
import { CustomButton } from './CustomButton.bin';
import { Format } from 'format-lib/index.bin';
import { StyleFactory } from '../../utils/ToolsReact';

export enum eDialogMode {
    create = "create",
    modify = "modify",
    duplicate = "duplicate"
}

const overviewWidth = '350px';
/** Styles */
const StylesBase = StyleFactory.create({
    column: {
        flex: overviewWidth,
        position: 'relative',
        height: '100%',
        overflow: 'auto'
    },
});

export const DialogStyles = StyleFactory.create({
    layout: { display: 'flex', height: '100%' },
    columnOverview: {
        ...StylesBase.column,
        flex: overviewWidth,
        background: '#DBF1F8'
    },
    columnContent: {
        ...StylesBase.column,
        flex: `calc(100% - ${overviewWidth})`,
    }
});

/** Trad */
export function DicoTitleTrad(model: "campaign" | "message") {
    return {
        [eDialogMode.create]: Trad(`new_${model}`),
        [eDialogMode.modify]: Trad(`edit_${model}`),
        [eDialogMode.duplicate]: Trad(`dupli_${model}`)
    }
}

export function DicoCreateTrad() {
    return {
        [eDialogMode.create]: Trad("create"),
        [eDialogMode.modify]: Trad("modify"),
        [eDialogMode.duplicate]: Trad("duplicate")
    }
}

export function DicoProgressTrad() {
    return {
        [eDialogMode.create]: Trad("creation_progress"),
        [eDialogMode.modify]: Trad("modification_progress"),
        [eDialogMode.duplicate]: Trad("creation_progress")
    }
}

export class GenericDialogProps {
    id?: string;
    handleClose?: () => void;
    open: boolean;
    style?: React.CSSProperties;
    titleClass?: string;
    dialogTitle: any;
    iconsTitle?: JSX.Element;
    submitAction?: () => void;
    submitTitle?: string;
    submitClass?: string;
    startIcon?: any;
    dialogContent?: JSX.Element;
    children?: any;
    actions?: boolean;
    disableCancel?: boolean;
    additionalButtons?: JSX.Element | JSX.Element[];
    cancelAction?: any;
    disablePrimaryButton?: boolean;
    disableCancelButton?: boolean;
    beforeButton?: JSX.Element;
    closable?: boolean;
    dialogProps?: Partial<DialogProps>;
    dialogContainerStyle?: React.CSSProperties;
    dialogClassname?: string;
}

export function ConfirmContent(items: any[], action: "delete" | "modify" = "delete") {
    if (!items?.length)
        return null;
    if (items.length > 1)
        return `${Trad('do_you_want')} ${Trad(action).toLowerCase()} ${items.length} ${Trad('selected_elements')} ?`;
    return `${Trad('do_you_want')} ${Trad(action).toLowerCase()} ${Format(items[0], Trad('one_selected_element'))} ?`;
}

export function GenericDialog({
    id,
    handleClose,
    open,
    style,
    titleClass,
    dialogTitle,
    closable,
    iconsTitle,
    dialogContent,
    children,
    actions,
    additionalButtons,
    disableCancel,
    cancelAction,
    submitTitle,
    disablePrimaryButton,
    disableCancelButton,
    beforeButton,
    submitClass,
    startIcon,
    submitAction,
    dialogProps,
    dialogContainerStyle,
    dialogClassname
}: GenericDialogProps) {

    const isMounted = React.useRef(false)

    React.useEffect(() => {
      isMounted.current = true;
      return () => { isMounted.current = false }
    }, []);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    return <Dialog
        disableEscapeKeyDown
        scroll="body"
        PaperProps={{ className: "overflow-visible paper-container", id }}
        onClose={(e, reason) => {
            if (reason != "backdropClick")
                handleClose();
        }}
        open={open}
        maxWidth={'lg'}
        style={style ?? {}}
        aria-labelledby="dialog_title"
        {...dialogProps}
        disableEnforceFocus={true}
    >

        <DialogTitle id="dialog_title" className={`${titleClass ?? 'dialog_title'}`}>
            {dialogTitle}
            {closable && <span style={{ float: "right", color: "black", cursor: "pointer", marginLeft: 10, fontWeight: 300 }} onClick={() => handleClose()}>x</span>}
            {iconsTitle}
        </DialogTitle>

        <DialogContent className={`DialogContent-container ${dialogClassname}`} style={dialogContainerStyle ?? {}}>
            {dialogContent ?? children}
        </DialogContent>
        {actions &&
            <DialogActions className="dialog_actions">
                {beforeButton}
                {!disableCancel &&
                    <CustomButton
                        Label={Trad("cancel")}
                        disabled={isSubmitting || disableCancelButton}
                        className={`cancel custom_btn_primary_cancel`}
                        onClick={cancelAction ?? handleClose} />
                }
                {additionalButtons}
                <CustomButton
                    Label={submitTitle}
                    disabled={isSubmitting || disablePrimaryButton}
                    className={submitClass ?? "custom_btn_primary_validation"}
                    style={{ minWidth: 90 }}
                    startIcon={startIcon}
                    onClick={async () => {
                        setIsSubmitting(true);
                        await submitAction();
                        if (isMounted.current)
                            setIsSubmitting(false);
                    }} />
            </DialogActions>
        }
    </Dialog>


}