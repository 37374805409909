import { Grid } from '@material-ui/core';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import * as React from 'react'
import { useEffect, useState } from 'react';
import { DialogStyles, DicoTitleTrad, eDialogMode } from '../../ConfigurableComponents/GenericDialog.bin';
import Loader from '../../layout/Loader';
import { Trad } from 'trad-lib';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IsValid } from 'validation-lib/index.bin';
import { DateNoZone, GetToday } from 'tools-lib';
import { DuplicationCampaignArgs } from 'hub-lib/models/types.bin';
import { CustomTextField } from '../Generic/CustomTextField';
import { ref_Comments } from 'hub-lib/dto/client/ref_Attachments.bin';
import { CampaignPerformances } from './CampaignPerformance';
import { ref_Estimates } from 'hub-lib/dto/client/ref_Estimates.bin';
import { Row, Title } from '../../Tools';
import { CustomMultiSelect } from '../Generic/CustomMultiSelect';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { CampaignOverview } from './CampaignOverview';
import { RootState, SetModelSync, store } from '../../../redux/store';
import { AddNewEstimate, clearCampaignEditor, initAttachments, setCampaign, setCampaignSync } from '../../../redux/campaignEditorSlice';
import { useSelector } from 'react-redux';
import { CampaignEstimatePicker } from './CampaignEstimatePicker';
import { VisualsEditor } from './VisualsEditor';
import { CommentsEditor } from './CommentsEditor';
import { ModelMetrics } from '../Generic/ModelForm/ModelMetrics';
import { kpis } from 'hub-lib/types';
import { MediaFamilyEditor, PeriodDetails, PeriodicityEditor, SupportDetails, TimeLimits } from '../Generic/ModelForm/ModelDetails';
import { StartEndEditor } from '../Generic/ModelForm/StartEndEditor';
import { HierarchyAdvertiserPicker } from '../Generic/ModelForm/HierarchyAdvertiserPicker';
import { KPIsManagerCache } from 'hub-lib/models/KPIsManager.bin';
import { KeyValidator } from '../../../utils/KeyValidator';


class TProps {
    //submitEvent?: EventEmitter;
    campaign?: ref_Campaigns;
    selectedMedia: ref_Media['@rid'];
    multiAdvertisers?: boolean;
    estimatedMode?: boolean;
    validationMode: boolean;
    duplicateParams?: DuplicationCampaignArgs;
}

export function DuplicateMessages(props: { params: DuplicationCampaignArgs }) {
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const CheckBoxParams = (propertyName: (keyof DuplicationCampaignArgs), clearAll: boolean = false) => {
        return <Grid item xs={3}>
            <Checkbox
                checked={props.params[propertyName]}
                disabled={!clearAll && !props.params.messages}
                label={Trad(`duplicate_${propertyName}`)}
                onChange={(e) => {
                    props.params[propertyName] = e.value;
                    if (clearAll && !props.params[propertyName])
                        Object.keys(props.params).forEach(k => {
                            if (k != propertyName)
                                props.params[k] = e.value;
                        })
                    forceUpdate();
                }}
            />
        </Grid>
    }
    return <Grid container className="message_details_full duplicate-messages-container">
        {CheckBoxParams("messages", true)}
        {CheckBoxParams("messagesWithStatus")}
        {CheckBoxParams("messagesCanceled")}
        {CheckBoxParams("broadcastPlacement")}
        {CheckBoxParams("pricingLocked")}
    </Grid>
}

export function GetStoreCampaignWithComments() {
    const data = store.getState().campaignEditor.get();
    const setCampaignComments = (campaign: ref_Campaigns, key: 'External' | "Internal") => {
        let comment = campaign?.Attachments?.[key] as ref_Comments;
        if (!comment) {
            comment = new ref_Comments();
            comment.Type = key;
            if (!campaign.Attachments)
                campaign.Attachments = {};
            campaign.Attachments[key] = comment;
        }
        const attachments = store.getState().campaignEditor.attachments;
        comment.Text = attachments[key];
    }
    setCampaignComments(data, "Internal");
    setCampaignComments(data, "External");
    if (data.Name)
        data.Name = data.Name.trim();
    return data;
}

class TState {
    displayWarning: boolean = false;
    Focused: boolean = false;
}

export function CampaignForm(props: TProps) {
    const { validationMode, duplicateParams } = props;
    const [state, setState] = useState<TState>();
    const campaignState = useSelector((root: RootState) => root.campaignEditor.data);
    const [medias, setMedias] = React.useState<ref_Media[]>();
    const mode = useSelector((root: RootState) => root.campaignEditor.mode);

    React.useEffect(() => {
        KPIsManagerCache.GetMedias().then(setMedias);
    }, [Boolean(medias)])

    const PropertyValidationError = (property: keyof ref_Campaigns) => {
        if (!validationMode) return null;
        const data = store.getState().campaignEditor.get();
        let valid = IsValid<ref_Campaigns>(ref_Campaigns, data, property);
        if (!valid || valid.isValid) return null;
        return valid.errorText;
    }

    const ConfigureElement = () => {
        let campaign: ref_Campaigns = new ref_Campaigns();
        if (props.campaign && mode !== eDialogMode.create)
            campaign = props.campaign;

        let defaultEnd = GetToday();
        defaultEnd.setMonth(11);
        defaultEnd.setDate(31);
        campaign.Start = campaign.Start ? DateNoZone(campaign.Start) : GetToday();
        campaign.End = campaign.End ? DateNoZone(campaign.End) : defaultEnd;
        if (props.selectedMedia)
            campaign.Media = props.selectedMedia;
        if (mode == eDialogMode.duplicate) {
            campaign.Name += `(${Trad('copy')})`;
            campaign.Estimates = [];
        }
        if (props.multiAdvertisers) {
            campaign.AdvertiserGroup = null;
            campaign.Advertiser = null;
            campaign.Brand = null;
            campaign.Product = null;
        } else {
            campaign.Group = null;
        }
        if (!campaign.KPIs)
            campaign.KPIs = new kpis();
        return campaign;
    }

    const initStore = (campaign: ref_Campaigns) => {
        store.dispatch(clearCampaignEditor());
        store.dispatch(setCampaign(campaign));
        store.dispatch(initAttachments(campaign));
        setState(new TState())
    }

    React.useEffect(() => {
        if (state) return;
        initStore(ConfigureElement());
    }, [Boolean(state)])

    if (!state || !campaignState || !medias)
        return <Loader />

    const presseRid = medias?.find(media => media.Code == "PR")?.["@rid"];
    const showPerformances = true /*Object.keys(campaignState.Performances).length > 0 || campaignState.Media != null*/;

    return <div className='message_dialog' style={DialogStyles.layout}>
        <div style={DialogStyles.columnOverview}>
            <CampaignOverview
                showPerformances={showPerformances}
                mode={mode} >
                <Title>
                    <span>{DicoTitleTrad("campaign")[mode]}</span>
                </Title>
            </CampaignOverview>
        </div>

        <div className='message_dialog_content' style={DialogStyles.columnContent}>
            <div id='campaignpicker'>
                <Title>{Trad('Campaign')}</Title>
                <Row>
                    <Grid container className='block-container'>
                        {mode == eDialogMode.duplicate &&
                            <DuplicateMessages params={duplicateParams} />
                        }
                        <Grid item xs={12} className="message_details_full">
                            <CustomTextField
                                label={Trad("campaign_name") + " *"}
                                value={store.getState().campaignEditor.data?.Name}
                                textFieldProps={{
                                    inputRef: input => {
                                        if (state.Focused) return
                                        setState({ ...state, Focused: true });
                                        input?.focus();
                                    },
                                    helperText: PropertyValidationError("Name"),
                                    error: PropertyValidationError("Name") != null
                                }}
                                onChange={(e) => {
                                    const data = store.getState().campaignEditor.get();
                                    data.Name = e;
                                    SetModelSync("ref_Campaigns", data);
                                }}
                            />
                        </Grid>
                        <KeyValidator objectType="ref_Campaigns" propertyName="Support">
                            <SupportDetails model="ref_Campaigns" />
                        </KeyValidator>
                        <Grid item xs={6} className="message_details_leftcombo">
                            <StartEndEditor model="ref_Campaigns" TSMCalendarEnable={campaignState.Media == presseRid} />
                        </Grid>
                        <ModelMetrics categories={['Pricing']} model="ref_Campaigns" title={false} syncMetrics={true} />
                        <KeyValidator objectType="ref_Campaigns" propertyName="Estimates">
                            {props.estimatedMode && mode === eDialogMode.create
                                && !campaignState?.Estimates?.length &&
                                <Grid item xs={6} className="message_details_leftcombo">
                                    <CustomMultiSelect minimumFilterLength={3} vertex={ref_Estimates} searchKey={"Code"} title={Trad("estimate")}
                                        onChange={(e) => {

                                            const data = store.getState().campaignEditor.get();

                                            const [estimate] = e.value;
                                            data.Name = estimate.Lib;
                                            data.AdvertiserGroup = estimate.AdvertiserGroup;
                                            data.Advertiser = estimate.Advertiser;
                                            data.Brand = estimate.Brand;
                                            data.Product = estimate.Product;
                                            data.Start = estimate.Start;
                                            data.End = estimate.End;

                                            store.dispatch(setCampaignSync(data));
                                            store.dispatch(AddNewEstimate({ nb: 1, source: "MM", clear: true, createParams: { estimate: estimate["@rid"] } }));
                                        }}
                                    />
                                </Grid>}
                        </KeyValidator>
                        <HierarchyAdvertiserPicker model="ref_Campaigns" multiAdvertisers={props.multiAdvertisers} estimatedMode={props.estimatedMode}
                            onChange={() => {
                                setState({ ...state, displayWarning: true })
                            }} />
                        {campaignState.Media && <>
                            <Grid item xs={12} className="adw-title">{Trad("Plus d'informations")}</Grid>
                            <Grid container className='block-container'>
                                {campaignState.Media == presseRid && <PeriodicityEditor model="ref_Campaigns" />}
                                <MediaFamilyEditor model="ref_Campaigns" />
                                {campaignState.Media == presseRid && <PeriodDetails model="ref_Campaigns" />}
                            </Grid>
                            {/* time limits */}
                            <TimeLimits model="ref_Campaigns" />
                        </>}
                        <CommentsEditor />
                    </Grid>
                </Row>
            </div>
            <CampaignEstimatePicker mode={mode} />
            <KeyValidator objectType="ref_Campaigns" propertyName="Visuals">
                <VisualsEditor />
            </KeyValidator>
            {showPerformances &&
                <div id='performancespicker'>
                    <Title>{Trad('performances')}</Title>
                    <Grid container className='block-container'>
                        <Row>
                            <ModelMetrics categories={['Performance']} model="ref_Campaigns" title={false} syncMetrics={true} />
                            <CampaignPerformances medias={medias} />
                        </Row>
                    </Grid>
                </div>
            }

            {state.displayWarning && <span>{Trad("warning_campaign_changed")}</span>}
        </div>
    </div>
}
