import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { GetCurrentLocale, Trad } from "trad-lib";
import { Menu, MenuItemModel } from "@progress/kendo-react-layout";
import { clone, extractSub, GetHashCode, GetSubElement, JSONEqualityComparer, SetSubElement } from "hub-lib/tools.bin";
import { Grid, GridCellProps, GridColumn, GridCustomCellProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { GetEditorState, RootState, store } from "../../../redux/store";
import { ref_Campaigns, TimePerformanceKPI } from "hub-lib/dto/client/ref_Campaigns.bin";
import { useDispatch, useSelector } from "react-redux";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory";
import { CreateIndicateur, Indicateur } from "adwone-engine/index.bin";
import moment from "moment";
import { Renderers } from "../Adwone-admin/Referential/Renderers";
import {
    checkIcon
} from "@progress/kendo-svg-icons";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { getIcon, TooltipManager } from "adwone-lib";
import { FormatDDMMDate } from "format-lib/index.bin";

class PerformanceModeProps {
    mode?: string;
    onChange?: (mode: string) => void;
}

export function PerformanceModeContainer({ mode, onChange }: PerformanceModeProps) {
    return <RadioGroup
        row
        aria-label="position"
        name="position"
        value={mode}
        onChange={(e, value) => {
            onChange(value)
        }} >
        <FormControlLabel
            className="discount_mode"
            value="total"
            control={<Radio color="primary" />}
            label={Trad("total")}
            labelPlacement="end" />
        <FormControlLabel
            className="discount_mode"
            value="days"
            control={<Radio color="primary" />}
            label={Trad("day")}
            labelPlacement="end" />
        <FormControlLabel
            className="discount_mode"
            value="weeks"
            control={<Radio color="primary" />}
            label={Trad("week")}
            labelPlacement="end" />
    </RadioGroup>
}

class PerformanceAddButtonProps {
    kpis: string[];
    activeKpis: string[];
    onClick?: (data: string) => void;
}

export function PerformanceAddButton({ activeKpis, kpis, onClick }: PerformanceAddButtonProps) {
    const [items, setItems] = React.useState<MenuItemModel[]>();
    console.log("activeKPIs", activeKpis);
    React.useEffect(() => {
        Promise.resolve().then(async () => {
            const items: MenuItemModel[] = [{
                text: Trad("add"),
                items: kpis.map(i => ({ text: Trad(i), data: i, svgIcon: activeKpis.includes(i) ? checkIcon : null }))
            }];

            setItems(items);
        })
    }, [GetHashCode(kpis), GetHashCode(activeKpis)]);

    return <Menu className="menu_custom_btn_primary"
        items={items}
        onSelect={async (e) => {
            if (!e.item.data) return;
            onClick?.(e.item.data);
        }} />
}

export type propertyDescriptorType = { field: keyof ref_Campaigns['Performances'][0], type: "number" | "string", isEditable: boolean };
export class CampaignPerformanceArgs {
    kpis: ref_Campaigns['KPIs'];
    performances: ref_Campaigns['Performances'][0];
    campaignPropertiesFilter?: (property: propertyDescriptorType) => boolean;
    activeCGrp?: boolean = true;
    onChange: (performanceField: string, value: any) => void;
}

class PerformanceGridProps extends CampaignPerformanceArgs {
    timeKpis: string[];
}

export const WarningKPIs = ["GRP", "contacts", "coverage_thousands"];
export const NumberTemplate = (_value, decimal) => _value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: decimal, maximumFractionDigits: decimal })

export const TimeHeaderCell = (props: GridHeaderCellProps) => {
    return <th style={{ display: "none" }}></th>
}

export const TimeCell = (props: GridCellProps) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    const value = GetSubElement(dataItem, field) as string;
    const defaultRendering = (
        <td
            style={{ textAlign: "center", fontWeight: "500" }}
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {value.toUpperCase()}
        </td>
    );

    return render?.call(undefined, defaultRendering, props);

}

export const GetWarningTotal = (dataItem: any, field: string) => {
    const kpi = WarningKPIs.find(k => field.includes(`.${k}.`));
    if (!kpi)
        return false;
    if (field.includes(`.${kpi}.objective`))
        return GetSubElement(dataItem, `Performances.${kpi}.ObjectiveWarning`) === true;
    if (field.includes(`.${kpi}.done`))
        return GetSubElement(dataItem, `Performances.${kpi}.DoneWarning`) === true;
    return false;
}

export const PerformanceNumericCell = (props: GridCellProps) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, format, render } = props;
    const isInEdit = field === dataItem.inEdit;

    const factor = format?.startsWith("P") ? 100 : 1;
    const decimal = format?.substring(1) ?? "0";
    const value = (GetSubElement(dataItem, field) as number) * factor;

    let warning = false;
    let isTotal = false;
    const leftBorder = props.field.includes("objective");
    if (props.dataItem.time == "total") {
        isTotal = true;
        warning = GetWarningTotal(dataItem, props.field)
        /*if (props.field.includes(".GRP.objective"))
            warning = GetSubElement(dataItem, "Performances.GRP.ObjectiveWarning") === true;
        if (props.field.includes(".GRP.done"))
            warning = GetSubElement(dataItem, "Performances.GRP.DoneWarning") === true;*/
    }
    let formatedValue = "";
    if (!isInEdit && !isNaN(value)) {
        const strValue = NumberTemplate(value, decimal);
        if (factor == 100)
            formatedValue = `${strValue}%`;
        else
            formatedValue = strValue;
    }

    const onChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value / factor,
            });
        }
    };
    const defaultRendering = (
        <td
            className={leftBorder ? "border-left" : ""}
            style={{ textAlign: "center", paddingRight: "5px" }}
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                {isInEdit &&

                    <NumericTextBox
                        format={"n" + decimal}
                        className={'CellNumberComponent-editing-cell'}
                        spinners={true}
                        min={factor == 100 ? 0 : undefined}
                        max={factor == 100 ? 100 : undefined}
                        step={0.01}
                        value={value}
                        onChange={onChange} />
                }
                {!isInEdit &&
                    <>
                        {warning && <span className="display-center" style={{ paddingLeft: "5px", paddingRight: "5px" }}
                            onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("total_is_different") })}>
                            {getIcon('info', {}, "warning_color")}
                        </span>}
                        <span style={{ fontWeight: isTotal ? "500" : "normal" }}>{formatedValue}</span>
                    </>
                }
            </div>
        </td>
    );

    return render?.call(undefined, defaultRendering, props);
};

export function KpiToFormat(kpi: string) {
    switch (kpi) {
        case "coverage":
            return "P2";
        case "contacts":
        case "coverage_thousands":
            return "n0";
        case "repetition":
            return "n1";
        default:
            return "n2";
    }
}

export function TotalWarningData(data: Array<TimePerformanceKPI>, timeKpis : string[]) {
    WarningKPIs.filter(k => timeKpis.includes(k)).forEach(kpi => {
        const timeValues = data.filter(d => d.time != "total").map(d => d.Performances[kpi]);

        const sums = timeValues.reduce((partial: { sumObjective: number, sumDone: number }, value) => (
            {
                sumObjective: partial.sumObjective + value.objective,
                sumDone: partial.sumDone + value.done
            }), { sumObjective: 0, sumDone: 0 });
        const total = data.find(d => d.time == "total");

        total.Performances[kpi]["ObjectiveWarning"] = timeValues.length != 0 && sums.sumObjective != total.Performances[kpi].objective;
        total.Performances[kpi]["DoneWarning"] = timeValues.length != 0 && sums.sumDone != total.Performances[kpi].done;
    });
}

export function PerformanceGrid({ timeKpis, performances, onChange }: PerformanceGridProps) {
    const editField = "inEdit";
    const [oldItem, setOldItem] = React.useState<TimePerformanceKPI>(null);
    const [data, setData] = React.useState<Array<TimePerformanceKPI>>(performances.TimePerformance);
    const campaign = useSelector((root: RootState) => extractSub(GetEditorState(ref_Campaigns.name, root).get(), ['Start', 'End']), JSONEqualityComparer) ?? {};
    const [indicateur, setIndicateur] = React.useState<Indicateur>(null);
    const [value, setValue] = React.useState<number>(0);

    const GetIndicateurName = () => {
        if (performances.TimeMode == "days")
            return Trad("number_of_days");
        else if (performances.TimeMode == "weeks")
            return Trad("number_of_weeks");
        return null;
    }

    const TimeFormat = (iterator: number) => {
        if (!campaign.Start)
            return null;
        const prefixe = performances.TimeMode == "days" ? Trad("day_very_short") : Trad("week_very_short");
        const date = moment(campaign.Start).add(iterator, performances.TimeMode == "days" ? "days" : "weeks");
        return `${prefixe}${iterator + 1} - ${FormatDDMMDate(date.toDate(), GetCurrentLocale())}`;
    }

    React.useEffect(() => {
        const indicateurName = GetIndicateurName();
        if (indicateurName)
            AggregatorFactory.GetInstance().Get(ref_Campaigns).Provide()
                .then(indicateurs => indicateurs.find(i => i.indicateur.name == indicateurName)?.indicateur)
                .then(_indicateur => setIndicateur(CreateIndicateur(_indicateur)))
        else
            setIndicateur(null);
    }, [performances.TimeMode]);

    React.useEffect(() => {
        if (campaign) {
            if (indicateur)
                Promise.resolve(indicateur.Compute([campaign as any]))
                    .then(res => setValue(Math.round(res)));
            else
                setValue(0);
        }
    }, [indicateur?.name, GetHashCode(campaign)])

    const initTimeLine = (_data: TimePerformanceKPI[], keyTime: string, _oldData: TimePerformanceKPI[]) => {
        let line = { time: keyTime, Performances: {} };
        let oldLine = _oldData?.find(d => d.time == keyTime);

        timeKpis.forEach(kpi => {
            if (oldLine?.Performances?.[kpi])
                line.Performances[kpi] = clone(oldLine.Performances[kpi]);
            else
                line.Performances[kpi] = { objective: 0, done: 0 };
        })
        _data.push(line);
    };

    React.useEffect(() => {
        if (["days", "weeks"].includes(performances.TimeMode) && value == 0) //Is Loading
            return;
        console.log(`Reload Data (Indicateur: ${indicateur?.name} [${value}])`, timeKpis);
        const newData: TimePerformanceKPI[] = [];

        initTimeLine(newData, "total", performances.TimePerformance);
        for (let i = 0; i < value; i++) {
            const key = TimeFormat(i);
            initTimeLine(newData, key, performances.TimePerformance);
        }
        TotalWarningData(newData, timeKpis);
        if (!JSONEqualityComparer(newData, performances.TimePerformance)) {
            setData(newData);
            console.log("Reload Data (set Time Performances)");
            onChange("TimePerformance", newData);
        }
    }, [indicateur?.name, value, timeKpis?.join(",")]);

    const itemChange = (event) => {
        let field = event.field || "";

        SetSubElement(event.dataItem, field, event.value);
        let newData = data.map((item) => {
            if (item.time === event.dataItem.time) {
                //console.log(`${field}=${event.value}`);
                SetSubElement(item, field, event.value);
            }
            return item;
        });
        setData(newData);
    }

    const enterEdit = (dataItem: TimePerformanceKPI, field: string) => {
        const newData = data.map((item) => ({
            ...item,
            [editField]: item.time === dataItem.time ? field : undefined,
        }));
        setOldItem(clone(dataItem));
        setData(newData);
    };

    const exitEdit = () => {
        const newData = data.map((item) => ({ ...item, [editField]: undefined }));
        TotalWarningData(newData, timeKpis);
        setData(newData);
        onChange("TimePerformance", newData);
    };

    const cancelEdit = (dataItem) => {
        const newData = data.map((item) => {
            if (item.time === dataItem.time)
                return {
                    ...oldItem,
                    [editField]: undefined
                }
            return item;
        });
        setOldItem(undefined);
        console.log("CancelEdit");
        setData(newData);
    };

    let renderers;

    renderers = new Renderers(enterEdit, exitEdit, editField, cancelEdit);

    if (!data)
        return <></>
    return <Grid
        className="performance-grid"
        data={data}
        editField={editField}
        onItemChange={itemChange}
        cellRender={renderers.cellRender}
        rowRender={renderers.rowRender}>
        <GridColumn field="time" editable={false} cell={TimeCell} headerCell={TimeHeaderCell} />
        {timeKpis &&
            timeKpis.map(kpi => {
                const format = KpiToFormat(kpi);
                return <GridColumn field={`Performances.${kpi}`} title={Trad(kpi)} editable={false} headerClassName="border-left">
                    <GridColumn field={`Performances.${kpi}.objective`} title={Trad("objective")} editor="numeric" headerClassName="border-left" editable format={format}
                        cell={PerformanceNumericCell} />
                    <GridColumn field={`Performances.${kpi}.done`} title={Trad("done")} editor="numeric" editable format={format}
                        cell={PerformanceNumericCell} />
                </GridColumn>
            })
        }
    </Grid>
}