
import { CreateIndicateur, Indicateur } from "adwone-engine/index.bin";
import { GetCellTemplate } from "format-lib/index.bin";
import { Client } from "hub-lib/client/client.bin";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory"
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { clone, extractSub, JSONEqualityComparer } from "hub-lib/tools.bin";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trad, TradProp, TradValue } from "trad-lib";
import { RootState, store } from "../../../redux/store";
import { AnchorRow, Row } from "../../Tools";
import { SubTitle } from "../Generic/Common.bin";
import { DiffusionValidator, OfferValidatior, PricingValidatior } from "./MessageEditorValidators";
import { ref_Companies } from "hub-lib/models/orientdb/ref_Companies.bin";
import { KeyValidator } from "../../../utils/KeyValidator";
import { AdvertiserOverview, Field, StartEndOverview, SubOverviewArgs, SupportOverview } from "../Generic/ModelForm/ModelDetails";
import { getIcon } from "../../ConfigurableComponents/IconManager.bin";
import { setMessage, setMessageSync } from "../../../redux/messageEditorSlice";

type MessageEditorOverview = { children?: any }

export function MessageEditorOverview({ children }: MessageEditorOverview) {
    return <div className='MessageEditorOverview' >
        {children}
        <Row>
            <SubTitle anchor='#campaignpicker'>{Trad('Campaign')}</SubTitle>
            <CampaignOverview anchor='#campaignpicker' />
            <KeyValidator objectType="ref_Campaigns" propertyName="Advertiser">
                <AdvertiserOverview model="ref_Messages" anchor="#campaignpicker" />
            </KeyValidator>
            <KeyValidator objectType="ref_Campaigns" propertyName="Support">
                <SupportOverview model="ref_Messages" anchor='#campaignpicker' />
                <StartEndOverview model="ref_Messages" anchor='#campaignpicker' />
            </KeyValidator>
        </Row>
        <KeyValidator objectType="ref_Campaigns" propertyName="Advertiser" notExists={true}>
            <Row>
                <SubTitle anchor='#advertiserpicker'>{Trad(ref_Advertisers.name)}</SubTitle>
                <AdvertiserOverview model="ref_Messages" anchor='#advertiserpicker' />
                <MandataireOverview anchor='#advertiserpicker' />
            </Row>
        </KeyValidator>
        <OfferValidatior>
            <Row>
                <SubTitle anchor='#offerpicker'>{Trad('Offer')}</SubTitle>
                <KeyValidator objectType="ref_Campaigns" propertyName="Support" notExists={true}>
                    <SupportOverview model="ref_Messages" anchor='#offerpicker' />
                    <StartEndOverview model="ref_Messages" anchor='#parutiondetailspicker' />
                    <FormatOverview anchor='#parutiondetailspicker' />
                </KeyValidator>
                <KeyValidator objectType="ref_Campaigns" propertyName="Support">
                    <FormatOverview anchor='#offerpicker' />
                </KeyValidator>
            </Row>
        </OfferValidatior>
        <PricingOverview anchor='#pricingpicker' />
        <MetricsOverview />
        <DiffusionOverview />
    </div>
}

function CampaignOverview({ anchor }: SubOverviewArgs) {

    const [campaign, setCampaign] = React.useState<ref_Campaigns['Name']>('');
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ['Campaign']), JSONEqualityComparer) ?? {};

    const propCheck = [
        { type: ref_Campaigns, setter: setCampaign, value: message.Campaign },
    ]

    propCheck.forEach(p => {
        React.useEffect(() => {
            if (!p.value) return p.setter('');
            Client.searchVertex(p.type.name, { '@rid': p.value, properties: ['Name'] }).then(res => p.setter(res?.data?.results?.[0]?.Name))
        }, [p.value])
    })

    return <Field field='Campaign' value={campaign} anchor={anchor} />
}

function MandataireOverview({ anchor }: SubOverviewArgs) {

    const [mandataire, setMandataire] = React.useState<ref_Companies['Name']>('');
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ['Agency_Res']), JSONEqualityComparer) ?? {};

    const propCheck = [
        { type: ref_Companies, setter: setMandataire, value: message.Agency_Res },
    ]

    propCheck.forEach(p => {
        React.useEffect(() => {
            if (!p.value) return p.setter('');
            Client.searchVertex(p.type.name, { '@rid': p.value, properties: ['Name'] }).then(res => p.setter(res?.data?.results?.[0]?.Name))
        }, [p.value])
    })

    return <Field field='Agency_Res' value={mandataire} anchor={anchor} />
}

function FormatOverview({ anchor }: SubOverviewArgs) {

    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ['Format', 'Placement']), JSONEqualityComparer) ?? {};

    const [placement, setPlacement] = React.useState<ref_Property['Name']>('');
    const [format, setFormat] = React.useState<ref_Property['Name']>('');

    const propCheck = [
        { type: ref_Property, setter: setPlacement, value: message.Placement },
        { type: ref_Property, setter: setFormat, value: message.Format }
    ]

    propCheck.forEach(p => {
        React.useEffect(() => {
            if (!p.value) return p.setter('');
            Client.searchVertex(p.type.name, { '@rid': p.value, properties: ['Name'] }).then(res => p.setter(res?.data?.results?.[0]?.Name))
        }, [p.value])
    })

    return <>
        <Field field='Format' value={TradValue(ref_Property.name, "Name", format)} anchor={anchor} />
        <Field field='Placement' value={TradValue(ref_Property.name, "Name", placement)} anchor={anchor} />
    </>
}

const priceFormater = GetCellTemplate(ePropType.Double);

function PricingOverview({ anchor }: SubOverviewArgs) {

    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ['KPIs']), JSONEqualityComparer) ?? {};

    return <PricingValidatior>
        <Row>
            <span><SubTitle anchor='#pricingpicker'>{Trad('Pricing')}</SubTitle><PricingLocker /></span>
            <Field field={'KPIs.Gross' as any} model="ref_Messages" value={priceFormater(message.KPIs.Gross)} anchor={anchor} />
            <Field label={Trad("net_space")} field={'KPIs.NetCO' as any} value={priceFormater(message.KPIs.NetCO)} anchor={anchor} />
            <ComputedIndicatorField name="netCoFTHono" />
            <Field field={'KPIs.TotalCO' as any} value={priceFormater(message.KPIs.TotalCO)} anchor={anchor} />
            <ComputedIndicatorField name="total_frais" />
        </Row>
    </PricingValidatior>;
}

function PricingLocker() {
    const isLocked = useSelector((root: RootState) => root.messageEditor.data.PricingLocked);
    const dispatch = useDispatch();

    const onClick = () => {
        const message = clone(store.getState().messageEditor.get());
        message.PricingLocked = !isLocked;
        dispatch(setMessageSync(message));
    }

    return <span onClick={onClick} className="pricing-locker-icon" style={{ marginLeft: 3 }}>
        {getIcon(isLocked ? 'lock' : 'unlock', {
            position: 'absolute',
            top: -2
        }, "small-icon")}
    </span>
}

function ComputedIndicatorField({ name }: { name: string }) {
    const indicateurName = Trad(name);
    const message = useSelector((root: RootState) => root.messageEditor.get(), JSONEqualityComparer);
    const [indicateur, setIndicateur] = React.useState<Indicateur>(null);
    const [value, setValue] = React.useState<string>("");

    React.useEffect(() => {
        AggregatorFactory.GetInstance().Get(ref_Messages).Provide()
            .then(indicateurs => indicateurs.find(i => i.indicateur.name == indicateurName)?.indicateur)
            .then(_indicateur => setIndicateur(CreateIndicateur(_indicateur)))
    }, [Boolean(indicateur)]);


    React.useEffect(() => {
        if (indicateur && message)
            Promise.resolve(indicateur.Compute([message]))
                .then(res => setValue(priceFormater(res)));
    })

    // priceFormater((Math.round(message.KPIs.TotalCO * 100 - message.KPIs.NetCO * 100) / 100).toFixed(2))
    return <Field label={indicateurName} value={value} anchor='#pricingpicker' />;
}


function MetricsOverview() {
    return <PricingValidatior>
        <Row>
            <SubTitle anchor='#metricspicker'>{Trad('metrics')}</SubTitle>
        </Row>
    </PricingValidatior>;
}

function DiffusionOverview() {
    return <DiffusionValidator>
        <Row>
            <SubTitle anchor='#diffusionpicker'>{Trad('Diffusion')}</SubTitle>
        </Row>
    </DiffusionValidator>;
}
