import { ref_Functions, ref_FunctionsId } from "./ref_Functions.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_ModulesId = rid;

export class ref_Modules extends V {
	"@rid"?: ref_ModulesId;
	AllUsers: boolean;
	Functions: ref_FunctionsId[];
	Name: string;
	Category: string;
}