import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { rid } from 'hub-lib/models/orientdb/CommonTypes.bin';
import { AdvertiserHierarchy } from 'hub-lib/dto/client/ref_FilterConfigurations.bin';
import { duplicate } from 'hub-lib/tools.bin';

export class FilterAdvertiserReferentialTree extends AdvertiserHierarchy {
    AdvertiserGroup: rid[] = [];
    Advertiser: rid[] = [];
    Brand: rid[] = [];
    Product: rid[] = [];
    Start: Date = null;
    End: Date = null;
    children: never;
}

export class TreeProps {
    hierarchyClassName: string[];
    filterName?: keyof ReferentialTreeState;
    children?: any;
    extendedProperties?: string[];
}

export class FilterReferentialTree {
    "@rid": rid[] = [];
    children: rid[] = [];

    Start: Date = null;
    End: Date = null;
}

export interface ReferentialTreeState {
    advertiserFilter: FilterAdvertiserReferentialTree;
    agencyFilter: FilterReferentialTree;
    advertisingCompanyFilter: FilterReferentialTree;
}

const initialState: ReferentialTreeState = {
    advertiserFilter: duplicate(new FilterAdvertiserReferentialTree()),
    agencyFilter: duplicate(new FilterReferentialTree()),
    advertisingCompanyFilter: duplicate(new FilterReferentialTree())
}

export const referentialTreeSlice = createSlice({
    name: 'referentialTree',
    initialState: initialState as ReferentialTreeState,
    reducers: {
        onChange: (state, action: PayloadAction<Partial<Pick<ReferentialTreeState, "advertiserFilter" | "agencyFilter" | "advertisingCompanyFilter">>>) => {

            const { advertiserFilter, agencyFilter, advertisingCompanyFilter } = action.payload;
            if (advertiserFilter) {
                state.advertiserFilter = duplicate(advertiserFilter);
            }
            if (agencyFilter) {
                state.agencyFilter = duplicate(agencyFilter);
            }
            if (advertisingCompanyFilter) {
                state.advertisingCompanyFilter = duplicate(advertisingCompanyFilter);
            }
        }
    }
})
// Action creators are generated for each case reducer function
export const { onChange } = referentialTreeSlice.actions;

export const referentialTreeReducer = referentialTreeSlice.reducer;