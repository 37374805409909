
import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Grid, GridCellProps, GridColumn, GridHeaderCell } from "@progress/kendo-react-grid";
import { CellNumberComponent } from "../discounts/CellNumberComponent.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { propertyOf } from "hub-lib/tools.bin";
import { colWidth, lastCol } from "./DiscountEditor.bin";
import { RootState, store } from "../../../../redux/store";
import { Trad } from "trad-lib";
import { eKPIType, lnk_HasKPIExtended } from "hub-lib/models/KPIsManager.bin";
import { AdwAutocomplete } from "adwone-lib";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { GetKPITemplate } from "format-lib/index.bin";
import { ComboBox, ListItemProps } from "@progress/kendo-react-dropdowns";
import { kpis } from "hub-lib/types";

export class CPMFeesGridProps {
    discounts: ref_Discount[];
    classes: ref_DiscountClasses[];
    choices: lnk_HasKPIExtended[];
    discountType: ref_DiscountTypes;
    objectKpis: kpis;
    onChange?: (values: any) => void;
}

export function CPMFeesGridComponent({ discounts, choices, classes, discountType, objectKpis, onChange }: CPMFeesGridProps) {

    const computeItem = (d: ref_Discount) => {
        const netType = store.getState().messageEditor.currentNetType;

        if (d && d.KpiCPM && objectKpis) {
            d.IsCPM = true;
            d.IsRate = false;
            if (!d[netType])
                d[netType] = { Rate: 0, Value: 0 };
            if (!d.ValueCPM)
                d.ValueCPM = 0;

            onChange?.(discounts);
        }
    }

    const itemChange = (event) => {
        let elmnt = discounts.find(d => d.DiscountClass === event.dataItem.DiscountClass);
        elmnt.ValueCPM = event.value;
        computeItem(elmnt);
    }

    //cellPercent = (props: GridCellProps) => <CellPercentComponent {...props} editable={true} ptr={null} itemChange={this.itemChange} />;
    const cellNumberPrice = (props: any) => <CellNumberComponent {...props} ptr={null} editable={true} opt={{ type: ePropType.Double }} currency={null} itemChange={itemChange} />;

    const feeDiscounts = discounts.filter(d => d.DiscountType == discountType["@rid"]);
    const priceTmplt = GetKPITemplate(eKPIType.Price);

    const KpiLabel = (kpi: any) => {
        return <span style={{ lineHeight: "2" }}>
            {`${kpi.Name} - ${priceTmplt(objectKpis[kpi.Id] ?? 0)}`}
        </span>
    }
    const valueRender = (item: any) => {
        let kpi = null;
        if (item.props.value)
            kpi = choices.find(c => c.Id === item.props.value);
        if (!kpi)
            return <span style={{ lineHeight: "2" }}>{Trad("Performance")}</span>;
        return KpiLabel(kpi);
    }

    const itemRender = (li, itemProps: ListItemProps) => {
        const item = itemProps.dataItem;
        return React.cloneElement(li, li.props, KpiLabel(item));
    };

    if (!feeDiscounts?.length)
        return <></>;
    //this.computeItems();
    return <div className="discount-editor-array-container fees-container">
        <Grid
            className={"DiscountCategoryArray"}
            data={feeDiscounts}>
            <GridColumn title={Trad("Frais")} headerClassName="gray-back" cell={(props: GridCellProps) => {
                const c = classes.find(c => c["@rid"] == (props.dataItem as ref_Discount).DiscountClass);
                return <td>{Trad(c?.Name)}</td>
            }} />
            <GridColumn title={"Performance"} headerClassName="gray-back" width={lastCol + 2 * colWidth} cell={(props: GridCellProps) => {
                return <td>
                    <ComboBox
                        data={choices}
                        defaultValue={props.dataItem?.KpiCPM}
                        clearButton={false}
                        dataItemKey='KPI'
                        itemRender={itemRender}
                        valueRender={valueRender}
                        style={{ borderBottom: "none", justifyContent: "flex-start", backgroundColor: "transparent" }}
                        onChange={(event) => {
                            let elmnt = discounts.find(d => d.DiscountClass === props.dataItem.DiscountClass);
                            elmnt.KpiCPM = event.value["Id"];
                            computeItem(elmnt);
                        }}
                    />
                </td>
            }} />
            <GridColumn title={Trad("CPM")} field={propertyOf<ref_Discount>('ValueCPM')} headerClassName="numeric gray-back" width={colWidth} editor="numeric" cell={cellNumberPrice} />
        </Grid>
    </div>
}
