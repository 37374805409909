import * as React from 'react'

import { connect } from 'react-redux'
import { login } from '../../actions/authActions'

import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'

import Logo from '../../utils/adwone.png'
import { UrlParser } from 'tools-lib'
import { Client, GetUrlServer } from 'hub-lib/client/client.bin'
import { authRoute } from 'hub-lib/index.bin'

import history from '../../utils/history'
import Loader from '../layout/Loader'
import { Trad, TradProp } from 'trad-lib'
import { getIcon } from "adwone-lib/index"
import { CustomButton } from '../ConfigurableComponents/CustomButton.bin'
import { GenericDialog } from '../ConfigurableComponents/GenericDialog.bin'
import { ErrorMessage } from '../ConfigurableComponents/ErrorMessage.bin'
import { ErrorLogin } from './ErrorLogin'
import { Domain } from 'hub-lib/models/domain'
import { IsDebugMode } from '../../utils/localstorage.bin'
import { CustomIconButton } from '../VertexGrid/Generic/CustomIconButton'
// import ReCAPTCHA from 'react-google-recaptcha'

const crypto = window.crypto;
var array = new Uint32Array(1);
const nb1 = crypto.getRandomValues(array)[0]; // Compliant for security-sensitive use cases
const nb2 = crypto.getRandomValues(array)[0];

const randomKey = (nb1.toString(36).substring(2, 16) + nb2.toString(36).substring(2, 16)).toUpperCase();

/** Try to log with jwt if any in url */
function LogWithJWT() {
    let service = UrlParser("service");
    let jwt = UrlParser("jwt");
    if (jwt && service) {

        service = decodeURIComponent(service);
        jwt = decodeURIComponent(jwt);

        Client.Post(service, { jwt })
            .then(() => history.push("/"))
            .catch((err) => history.push("/authfailed"))
        return true;
    }
    return false;
}

/** auth code */
function OAuthCode(loginCallback: () => any) {
    let errorLogin = (arg: string) => () => history.push(`/login?success=false&${arg}`);
    let param = UrlParser("code");
    let errorCode = UrlParser("error_code");

    if (errorCode) {
        history.push(`/login?error_code=${errorCode}`);
        return false;
    }

    if (param) {
        Client.getToken(param)
            .then(res => {
                // SUCCESS LOG
                console.log('success token')
                Client.getUser().then(loginCallback).catch(errorLogin('status=false'));
            })
            .catch(errorLogin('token=false'));
        return true;
    }
    return false;
}

class Login extends React.Component<any, any> {
    recaptcha;
    static propTypes = {
        isAuthenticated: PropTypes.bool,
        login: PropTypes.func.isRequired
    }

    constructor(props: any) {
        super(props);

        let oauth = OAuthCode(this.props.login);
        let jwtLoading = LogWithJWT();
        this.state = {
            loading: oauth || jwtLoading,
            reset_pass: false,
            reset_mail: '',
            show_mail_error: false,
            error_mail_msg: Trad("mail_not_valid"),
            modaleError: false,
            showPassword: false,
            isConnecting: false
        }
        this.recaptcha = React.createRef();
    }

    redirections: Domain[] = undefined;
    checkRedirection = async (loginStr: string) => {

        if (!this.redirections)
            this.redirections = (await Client.getRedirectDomains())?.data?.results ?? [];

        const redirection = this.redirections?.find(domain => domain.mailDomains?.some(mailDomain => loginStr.includes(`@${mailDomain}`)));
        if (redirection) {
            const path = `/login${redirection.redirection ?? ""}`;
            if (IsDebugMode())
                console.log(`redirect to:`, path);
            this.setState({ redirection: `${GetUrlServer(path)}${path}` });
        } else if (this.state.redirection !== undefined)
            this.setState({ redirection: undefined })
    }

    generateState = () => {
        const redirect = UrlParser("redirect") ?? "";
        const objJsonStr = JSON.stringify({ redirect: decodeURIComponent(redirect), key: randomKey });

        return btoa(objJsonStr);
    }

    validateEmail = (email: string) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    componentDidMount() {
        let code = UrlParser("error_code");
        if (code) {
            this.setState({ modaleError: true, codeError: code })
        }
    }
    handleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }
    render() {
        let success = UrlParser("success");
        let { redirection, reset_pass, reset_pass_sent } = this.state;

        if (success !== "false" && this.state.loading)
            return <Loader></Loader>

        const { modaleError, codeError } = this.state;

        // const checkCaptcha = () => {
        //     const captchaValue = (this.recaptcha as any)?.current?.getValue();
        //     if (captchaValue)
        //         Client.Post("/captcha/verify", { token: captchaValue }).catch(e => console.error(e));
        //     return captchaValue;
        // }

        const redirectTo = () => {
            // if (!checkCaptcha()) {
            //     this.setState({ modaleError: true, codeError: "captcha_invalid" });
            //     return;
            // }
            window.location = this.state.redirection;
        }

        // const submitForm = async (event) => {
        //     if (!checkCaptcha()) {
        //         event.preventDefault();
        //         this.setState({ modaleError: true, codeError: "captcha_invalid" })
        //     }
        // }

        const submitForm = (event) => {
            // Empêche le comportement de soumission par défaut
            event.preventDefault();
            this.setState({ isConnecting: true }, () =>
                event.target.submit());
        };

        return (
            <Grid className="login" container justifyContent="center">

                {this.state.isConnecting
                    && <div style={{ zIndex: 100, position: 'absolute', height: '100%', width: '100%', background: '#808080c9' }}>
                        <Loader text={Trad('connecting')} />
                    </div>}

                <ErrorLogin opened={modaleError} code={codeError} onClose={() => this.setState({ modaleError: false })} />
                <Grid item className="loginCard_container">
                    <Card className="loginCard">
                        <CardContent className="loginCard_content">
                            <img className="loginCard_content_img"
                                alt="Adwanted"
                                src={Logo} />
                            <h5 className="loginCard_content_title">{Trad("loginCard_content_title")}</h5>
                            <p className="loginCard_content_description">
                                {Trad("loginCard_content_description")}
                            </p>
                            <form
                                /*onSubmit={onSubmit}*/
                                onSubmit={submitForm}
                                className="loginCard_content_form"
                                action={`${GetUrlServer(authRoute)}${authRoute}`}
                                method="post"
                                autoComplete="off">

                                <input type="hidden" id="client_id" name="client_id" value={Client.client_id} />
                                <input type="hidden" id="redirect_uri" name="redirect_uri" value={Client.redirect_uri} />
                                <input type="hidden" id="response_type" name="response_type" value="code" />
                                <input type="hidden" id="state" name="state" value={this.generateState()} />
                                <input type="hidden" id="grant_type" name="grant_type" value="authorization_code" />
                                <input type="hidden" id="redirect_on_error" name="redirect_on_error" value="true" />
                                <input type="hidden" id="token" name="token" />
                                <input type="hidden" id="tokenBrowser" name="tokenBrowser" value={localStorage.getItem('tokenBrowser')} />

                                <FormGroup row>
                                    <LoginTextField onChange={this.checkRedirection} />
                                </FormGroup>
                                {!redirection && <FormGroup row>
                                    <TextField
                                        className="loginCard_content_form_textfield"
                                        id="password"
                                        name="password"
                                        autoComplete='off'
                                        label="Password"
                                        placeholder="*******"
                                        fullWidth
                                        type={this.state.showPassword ? "text" : "password"}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            style: { fontSize: 14 },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {getIcon("lock")}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CustomIconButton onClick={this.handleShowPassword} >
                                                        {getIcon(this.state.showPassword ? "visibility" : "visibility_off")}
                                                    </CustomIconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        variant="outlined"
                                    />
                                </FormGroup>}
                                <FormGroup row>
                                    {success === "false" &&
                                        <ErrorMessage message={"Identifiants incorrects"} margin={'10px 0 0 0'} padding={'10px'} width={"100%"} />
                                    }
                                </FormGroup>
                                {/* <ReCAPTCHA ref={this.recaptcha} sitekey="6Ld6m90pAAAAAD-4vJtD1n_hJRjltVKlqjS9Am6N" /> */}

                                {!redirection &&
                                    <FormGroup className="loginCard_content_form_buttonContainer" row>
                                        <CustomButton
                                            Label={<span style={{ textDecoration: 'underline' }}>{Trad("forget_login")}</span>}
                                            className={"custom_btn_nostyle"}
                                            startIcon={getIcon("info")}
                                            onClick={() => {

                                                this.setState({ reset_pass: true })
                                            }} />


                                        <GenericDialog
                                            open={reset_pass ?? false}
                                            dialogTitle={Trad("reset_pass")}
                                            // submitClass={"custom_btn_primary"}
                                            actions
                                            cancelAction={() => { this.setState({ reset_pass: false }) }}
                                            submitAction={async () => {
                                                const { reset_mail } = this.state;
                                                Client.resetPassword(reset_mail)
                                                    .finally(() => this.setState({ reset_pass_sent: true, reset_pass: false }));
                                            }}
                                            submitTitle={Trad("reset_pass")}
                                            dialogContent={
                                                <form noValidate>
                                                    <div style={{ width: '100%', marginBottom: 20 }}>
                                                        <TextField id="reset_mail"
                                                            autoComplete='off'
                                                            type="email"
                                                            style={{ width: '100%' }}
                                                            label={TradProp("mail")}
                                                            variant="outlined"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    show_mail_error: false,
                                                                    reset_mail: e.target.value
                                                                }, () => {
                                                                    if (!this.validateEmail(this.state.reset_mail)) {
                                                                        this.setState({
                                                                            show_mail_error: true
                                                                        })
                                                                    }
                                                                })

                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.show_mail_error &&
                                                        <ErrorMessage message={this.state.error_mail_msg} margin={'20px 0'} padding={'10px'} />
                                                    }
                                                </form>
                                            } />

                                        <GenericDialog
                                            open={reset_pass_sent ?? false}
                                            dialogTitle={Trad("reset_pass")}
                                            // submitClass={"custom_btn_primary"}
                                            actions
                                            disableCancel
                                            submitAction={async () => this.setState({ reset_pass_sent: false })}
                                            submitTitle={Trad("ok")}
                                            dialogContent={
                                                <form noValidate>
                                                    {Trad("email_reset_sent")}
                                                </form>
                                            } />

                                        <Button variant="contained" endIcon={getIcon("send")} className="custom_btn_primary connexion_button" type="submit">
                                            {Trad('Connexion')}
                                        </Button>
                                    </FormGroup>}
                                {redirection && <FormGroup className="loginCard_content_form_buttonContainer sso" row>
                                    <CustomButton Label={Trad("connect_okta")} endIcon={getIcon("send")} className="custom_btn_primary" onClick={redirectTo} />
                                </FormGroup>}

                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

type LoginTextFieldProps = { onChange: (value: string) => void }
const LoginTextField = ({ onChange }: LoginTextFieldProps) => {


    const firstCall = React.useRef(true);

    const defaultValue = localStorage.getItem("lastUserName");
    if (defaultValue && firstCall.current) {
        firstCall.current = false;
        onChange(defaultValue);
    }

    return <TextField
        className="loginCard_content_form_textfield"
        id="username"
        autoComplete='off'
        name="username"
        label="Email"
        onChange={(e) => {
            localStorage.setItem("lastUserName", e.target.value);
            onChange(e.target.value)
        }}
        placeholder="john@doe.com"
        fullWidth
        margin="normal"
        InputLabelProps={{
            shrink: true
        }}
        {...(defaultValue && { defaultValue })}
        InputProps={{
            style: { fontSize: 14 },
            startAdornment: (
                <InputAdornment position="start">
                    {getIcon("username")}
                </InputAdornment>
            ),
        }}
        variant="outlined"
    />
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(
    mapStateToProps,
    { login }
)(Login)
