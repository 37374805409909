import * as React from "react";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { Trad } from "trad-lib";
import { getIcon } from "adwone-lib/index";
import { Grid } from "@material-ui/core";
import { HierarchyComboManager } from "../HierarchyComponent/HierarchyComponent.bin";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { lastCol } from "./IntervalsDialog.bin";
import { HierarchySupportComponent } from "./HierarchySupportComponent.bin";
import { Client } from "hub-lib/client/client.bin";
import { SimpleDatePicker } from "../../ConfigurableComponents/SimpleDatepicker.bin";
import { DiscountCategoryArray } from "../Messages/DiscountCategoryArray.bin";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { IsMMUser } from "../../../utils/localstorage.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { GetFirstDayOfYear } from "tools-lib";
import { DiscountManager, InitEditableDiscountsList, netType } from "hub-lib/business/DiscountManager.bin";
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import Loader from "../../layout/Loader";
import { GetHashCode } from "hub-lib/tools.bin";
import { eDialogMode } from "../../ConfigurableComponents/GenericDialog.bin";
import { AgreementDiscounts } from "./AgreementDiscounts";
import { MultiAdvertisersMode } from "../Campaigns/MultiAdvertisersMode";
import { AdvertisersGroupsPicker } from "../../AdvertisersGroupsPicker/AdvertisersGroupsPicker";
import { CustomTextFieldUpper } from "../Generic/CustomTextField";
import { removeCol } from "../Messages/DiscountEditor/DiscountEditor.bin";

export const IntervalsDesactivated: boolean = true;

export let numberCols = 119;
export let firstCol = (command: boolean, nbElements: number = 3) => {
    const computeWidth = numberCols * (nbElements - (IntervalsDesactivated ? 1 : 0)) + (command ? lastCol : 0) + removeCol;
    return `calc(100% - ${computeWidth}px)`;
}
export let firsColwithRank = (command: boolean) => firstCol(command, 4);
export let scrollSize = 0;

class TProps {
    mode: eDialogMode;
    agreement: ref_Agreements;
}

export class CustomDiscounts extends ref_Discount {
    CORate: number;
    COValue: number;
    FORate: number;
    FOValue: number;
    FOSRate: number;
    FOSValue: number;
}

class TState {
    config: Partial<Filter>;
    openIntervals: boolean = false;
    openIntervalsGracious: boolean = false;
    discountedited: ref_Discount = undefined;
    indexOfdiscountSelected: number = undefined;
    discounteditedGracious: any = undefined;
    indexOfdiscountSelectedGracious: number = undefined;
    cofo: netType;
    buttonDisabledGracious: boolean = false;
    buttonDisabledFinancial: boolean = false;
    currencyCode: string = "";
    dialogExist: ref_Agreements = null;
    multiAdvertisers: boolean;
}

class AgreementDialog extends React.Component<TProps, TState> {
    discountTab1: DiscountCategoryArray;
    discountTab2: DiscountCategoryArray;
    discountTab3: DiscountCategoryArray;
    freediscountTab: DiscountCategoryArray;

    constructor(props: TProps) {
        super(props);

        const { agreement } = this.props;
        agreement.Discounts = agreement.Discounts ?? [];
        agreement.Start = agreement.Start ? new Date(agreement.Start) : GetFirstDayOfYear();
        agreement.DiscountMode = agreement.DiscountMode ?? "cascade";
        agreement.AutoUpdate = agreement.AutoUpdate ?? false;
        agreement.IntervalsAutoUpdate = agreement.IntervalsAutoUpdate ?? false;
        agreement.End = agreement.End ? new Date(agreement.End) : null;

        let newState = new TState();
        newState.cofo = "CO";
        this.state = newState;
    }

    getCurrencyCode = async () => {
        return (
            await Client.get<ref_Currencies>(ref_Currencies, { "@rid": this.props.agreement.Currency })
        )?.data?.results?.[0]?.Code;
    };

    updateCurrencyCode = async () => {
        const currencyCode = await this.getCurrencyCode();
        this.setState({ currencyCode });
    };

    getConfig = (): Filter => {
        const { agreement } = this.props;
        const config: Filter = {
            Active: true,
            Source: ["ADWONE"],
            ViewMode: "Table",
            Agreement: [this.props.agreement["@rid"], null],
        } as any;

        if (!this.state) return config;
        if (agreement) config["currentAgr"] = agreement;
        if (agreement.AdvertiserGroup) config.AdvertiserGroup = [agreement.AdvertiserGroup];
        if (agreement.Advertisers?.length) config.Advertiser = agreement.Advertisers;
        if (agreement.Brands?.length) config.Brand = agreement.Brands;
        if (agreement.Products?.length) config.Product = agreement.Products;
        if (agreement.Start) config.Start = agreement.Start;
        if (agreement.End) config.End = agreement.End;
        if (agreement.Support) config.Support = [agreement.Support];
        if (agreement.BroadcastAreas?.length) config.BroadcastArea = agreement.BroadcastAreas;
        if (agreement.Formats?.length) config.Format = agreement.Formats;
        if (agreement.Placements?.length) config.Placement = agreement.Placements;
        if (agreement.Currency) config.Currency = [agreement.Currency];
        if (agreement.Group) config.Group = [agreement.Group];

        return config;
    };

    async componentDidMount() {
        await InitEditableDiscountsList();
        const { agreement } = this.props;
        if (!agreement.Messages) {
            // if (agreement["@rid"] && this.props.mode !== eDialogMode.duplicate) {
            //     const [agr] = await Client.searchVertexTyped(ref_Agreements, {
            //         ["@rid"]: agreement["@rid"],
            //         messages: true,
            //     });
            //     agreement.Messages = agr?.Messages;
            // } else {
                agreement.Messages = [];
            // }
        }

        let config = {
            Source: ["ADWONE"],
        };
        this.setState({ multiAdvertisers: Boolean(agreement.Group) }, () => {
            config = this.getConfig();
        });

        const currencyCode = await this.getCurrencyCode();

        this.setState({
            currencyCode,
            config,
        });
    }

    disableButtonFinancial = () => {
        this.setState({
            buttonDisabledFinancial: !this.state.buttonDisabledFinancial,
        });
    };

    enableButtonFinancial = () => {
        this.setState({
            buttonDisabledFinancial: false,
        });
    };

    disableButtonGracious = () => {
        this.setState({
            buttonDisabledGracious: !this.state.buttonDisabledGracious,
        });
    };

    onConfChange = async () => {
        let config = null;
        config = this.getConfig();
        this.setState({
            config,
        });
    };

    render() {
        const { agreement } = this.props;
        const { cofo, currencyCode, multiAdvertisers } = this.state;

        return (
            <>
                {!agreement ? (
                    <Loader />
                ) : (
                    <>
                        {!IsMMUser() && (
                            <div className="multiAdvertisersMode-component">
                                <MultiAdvertisersMode
                                    multiAdvertisers={multiAdvertisers}
                                    onChange={(value) => {
                                        if (!value) agreement.Group = null;
                                        this.setState({ multiAdvertisers: value });
                                    }}
                                />
                            </div>
                        )}
                        <Grid item xs={12} className="adw-title adw-form-title">
                            <div>{getIcon("folder")}</div>
                            {Trad("agreement_perimeter")}
                        </Grid>
                        <Grid container className="block-container" style={{ marginTop: "-8px", paddingTop: 8, overflow: "hidden" }}>
                            <Grid item xs={6} className="message_details_leftcombo">
                                <CustomTextFieldUpper
                                    label={Trad("property_Name")}
                                    required
                                    value={agreement.Name}
                                    onChange={(e) => {
                                        agreement.Name = e;
                                        this.forceUpdate();
                                    }}
                                    className="adw-row"
                                />
                                {multiAdvertisers ? (
                                    <AdvertisersGroupsPicker
                                        onChange={(group) => (agreement.Group = group)}
                                        defaultValue={agreement.Group}
                                    />
                                ) : (
                                    <HierarchyComboManager
                                        fullWidth
                                        key={`HierarchyComboManager`}
                                        multi={true}
                                        multiDetails={{ AdvertiserGroup: false }}
                                        nullDefault={{ AdvertiserGroup: true }}
                                        mandatory={{ AdvertiserGroup: true }}
                                        store={{
                                            AdvertiserGroup: agreement?.AdvertiserGroup,
                                            Advertiser: agreement?.Advertisers,
                                            Brand: agreement?.Brands,
                                            Product: agreement?.Products,
                                        }}
                                        onConfChanged={(conf) => {
                                            agreement.AdvertiserGroup = conf.AdvertiserGroup as string;
                                            agreement.Advertisers = conf.Advertiser as string[];
                                            agreement.Brands = conf.Brand as string[];
                                            agreement.Products = conf.Product as string[];
                                            this.onConfChange();
                                        }}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={6} className="message_details_rightcombo">
                                <HierarchySupportComponent
                                    key={`HierarchySupportComponent`}
                                    store={{
                                        Support: agreement?.Support,
                                        Media: agreement?.Media,
                                        BroadcastArea: agreement?.BroadcastAreas,
                                        Format: agreement?.Formats,
                                        Placement: agreement?.Placements,
                                        Currency: agreement?.Currency,
                                    }}
                                    mandatory={{
                                        Support: true,
                                        Currency: true,
                                    }}
                                    onConfChanged={(conf) => {
                                        agreement.Support = conf.Support as string;
                                        agreement.BroadcastAreas = conf.BroadcastArea as string[];
                                        agreement.Formats = conf.Format as string[];
                                        agreement.Placements = conf.Placement as string[];
                                        agreement.Currency = conf.Currency as string;
                                        agreement.Media = conf.Media as string;
                                        this.onConfChange();
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="adw-title adw-form-title">
                            <div>{getIcon("event")}</div>
                            {Trad("application_period")}
                        </Grid>
                        <Grid container className="block-container">
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={6} item className="message_details_leftcombo">
                                        <SimpleDatePicker
                                            label={`${Trad("Début")} *`}
                                            defaultValue={agreement.Start}
                                            onChange={(event) => {
                                                const oldStart = agreement.Start;
                                                agreement.Start = event.value;
                                                if (oldStart && agreement.End && agreement.Start > agreement.End) {
                                                    const diff = Math.abs(agreement.End.getTime() - oldStart.getTime());
                                                    agreement.End = new Date(agreement.Start.getTime() + diff);
                                                    this.forceUpdate();
                                                }
                                                this.onConfChange();
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={6} item className="message_details_rightcombo">
                                        <SimpleDatePicker
                                            key={`End_${GetHashCode(agreement.End)}`}
                                            label={Trad("Fin")}
                                            min={new Date(agreement.Start)}
                                            defaultValue={agreement.End}
                                            onChange={(event) => {
                                                if (event.value < new Date(agreement.Start))
                                                    agreement.End = new Date(agreement.Start);
                                                else agreement.End = event.value;
                                                this.onConfChange();
                                            }}
                                            resetDate={() => {
                                                agreement.End = null;
                                                this.onConfChange();
                                                this.forceUpdate();
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="adw-title adw-form-title">
                            <div>{getIcon("assessment")}</div>
                            {Trad("financial_agreement")}
                        </Grid>

                        <AgreementDiscounts
                            agreement={agreement}
                        // onOpenIntervalsClicked={(discount: ref_Discount) => {
                        //     this.setState({
                        //         openIntervals: true,
                        //         discountedited: JSON.parse(JSON.stringify(discount)),
                        //         indexOfdiscountSelected: agreement.Discounts.indexOf(discount),
                        //     });
                        // }}
                        // endEdit={() => this.onConfChange()}
                        />

                        {/* <div>
                            {this.state.openIntervals && (
                                <IntervalsDialog
                                    cofo={cofo}
                                    agreement={agreement}
                                    currencyCode={currencyCode}
                                    open={this.state.openIntervals}
                                    calculBase
                                    discount={this.state.discountedited}
                                    submitAction={async (discount: ref_Discount) => {
                                        const { indexOfdiscountSelected } = this.state;

                                        if (IsDebugMode()) {
                                            console.log(`submitAction indexOfdiscountSelected`, indexOfdiscountSelected);
                                        }
                                        console.log(`discount`, discount);
                                        if (indexOfdiscountSelected == -1) {
                                            // new one
                                            agreement.Discounts.unshift(discount);
                                        } else {
                                            // edition
                                            const base = agreement.Discounts[indexOfdiscountSelected];
                                            base.Base = discount.Base;
                                            base.Intervals = discount.Intervals;
                                        }

                                        if (IsDebugMode())
                                            console.log(
                                                `submitAction this.state.agreement.Discounts`,
                                                agreement.Discounts
                                            );

                                        this.setState(
                                            {
                                                openIntervals: false,
                                                discountedited: undefined,
                                                indexOfdiscountSelected: undefined,
                                            },
                                            () => EventChange.emit("changed")
                                        );
                                    }}
                                    cancelAction={() =>
                                        this.setState({
                                            openIntervals: false,
                                            discountedited: undefined,
                                            indexOfdiscountSelected: undefined,
                                        })
                                    }
                                />
                            )}
                        </div> */}
                        {/* <Grid container className="block-container">
                           <ApplicationConfigSelector  />
                        </Grid> */}
                    </>
                )}
            </>
        );
    }
}
export default AgreementDialog;
