import * as React from "react";

import { VertexGrid } from "../Generic/VertexGrid.bin";
import { ADWColumn, AdwRow } from "adwone-lib/index";
import { Trad, TradComposed, TradProp } from "trad-lib";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { Client } from "hub-lib/client/client.bin";
import { eDialogMode, GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";
import DefaultGrid, { NotifyConfig } from "../DefaultGrid.bin";
import { clone, propertyOf, SanitizeDuplication } from "hub-lib/tools.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { GetOrder } from "format-lib/index.bin";
import AgreementDialog from "./AgreementDialog";
import { GetFirstDayOfYear, GetLastDayOfYear } from "tools-lib";
import { eGroupCategories } from "hub-lib/dto/client/ref_Groups.bin";
import AgreementDialog2 from "./AgreementDialog2";
import { store } from "../../../redux/store";
import { setAgreementBeforeUpsert, setAgreement } from "../../../redux/agreementEditorSlice";
import { Notify } from "../../../utils/Notify.bin";

const defaultAgreement: ref_Agreements = {
    ["@rid"]: null,
    Active: true,
    Name: "",
    BroadcastAreas: null,
    Currency: null,
    Start: GetFirstDayOfYear(),
    End: GetLastDayOfYear(),
    Discounts: [],
    DiscountMode: "cascade",
    AutoUpdate: false,
    IntervalsAutoUpdate: false,
    Media: null,
    Support: null
};

export const AgreementsGrid = (props) => {

    const importId = props?.match?.params?.importId;

    const [grid, setGrid] = React.useState(null);

    const [existingAgreement, setExistingAgreement] = React.useState<ref_Agreements>(null);
    const [showExist, setShowExist] = React.useState<boolean>(false);
    const [showConfirm, setShowConfig] = React.useState<boolean>(false);

    const [resolver, setResolver] = React.useState({ resolve: null });

    const [name, setName] = React.useState<ref_Agreements["Name"]>("");

    React.useEffect(() => {
        if (!grid) {

            const columns: ADWColumn<ref_Agreements>[] = [];

            if (importId) {
                const importstatusColumn = new ADWColumn<ref_Agreements>(TradProp(("Import.Status" as any), ref_Agreements), "Import.Status", ePropType.Any, true)
                importstatusColumn.cellValue = (cellValue: any, dataItem: AdwRow<ref_Agreements>) => {
                    return <span className={`status_message_${(dataItem.dataItem as any)?.Import?.Status}`}>
                        {(dataItem.dataItem as any)?.Import?.Status ? Trad((dataItem.dataItem as any)?.Import?.Status) : ''}
                    </span>
                };
                importstatusColumn.width = 150
                columns.push(importstatusColumn);
            }

            const hiddenProperties: string[] = [];
            hiddenProperties.push(propertyOf<ref_Agreements>("Active"));
            hiddenProperties.push(propertyOf<ref_Agreements>("Currency"));
            hiddenProperties.push(propertyOf<ref_Agreements>("DiscountMode"));
            hiddenProperties.push(propertyOf<ref_Agreements>("Discounts"));
            hiddenProperties.push(propertyOf<ref_Agreements>("IntervalsAutoUpdate"));
            hiddenProperties.push(propertyOf<ref_Agreements>("AutoUpdate"));
            hiddenProperties.push(propertyOf<ref_Agreements>("Messages"));
            hiddenProperties.push(propertyOf<ref_Agreements>("Media"));
            const grid = new VertexGrid<ref_Agreements>({
                disableStore: true,
                objectPrototype: ref_Agreements,
                devMode: false,
                columns,
                order: GetOrder<ref_Agreements>(ref_Agreements),
                vertexParams: {
                    Active: true,
                    properties: ["*"],
                    ...(importId && { CacheInfos: { Key: importId, Type: "Import" } })
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });

    const submitAgreement = async (agr: ref_Agreements, curMode: eDialogMode) => {
        let updatedAgr: ref_Agreements = null;
        const customNotify = (agr?.Messages?.length && agr.Messages.length > 0);
        if (existingAgreement) {
            switch (curMode) {
                case eDialogMode.duplicate:
                case eDialogMode.create:
                    updatedAgr = (await Client.updateVertex(ref_Agreements.name, { ...existingAgreement, ...agr, "@rid": existingAgreement["@rid"] }, !customNotify)).data.results;
                    break;
                case eDialogMode.modify:
                    await Client.deleteVertex(ref_Agreements.name, [agr["@rid"]]);
                    updatedAgr = (await Client.updateVertex(ref_Agreements.name, { ...existingAgreement, ...agr, "@rid": existingAgreement["@rid"], "_id": existingAgreement["_id"] }, !customNotify)).data.results;
            }
        } else {
            switch (curMode) {
                case eDialogMode.create:
                    updatedAgr = (await Client.createVertex(ref_Agreements.name, agr, !customNotify)).data.results;
                    break;
                case eDialogMode.modify:
                    updatedAgr = (await Client.updateVertex(ref_Agreements.name, agr, !customNotify)).data.results;
                    break;
                case eDialogMode.duplicate:
                    const duplicatedAgreement = SanitizeDuplication(clone(agr));
                    updatedAgr = (await Client.createVertex(ref_Agreements.name, duplicatedAgreement, !customNotify)).data.results;
                    break;
            }
        }
        if (customNotify) {
            Notify(TradComposed("submit_agreement_success", [Trad(curMode), agr.Messages.length.toString(), agr.Messages.length > 1 ? Trad("messages") : Trad("messages")]), "success");
        }
        return updatedAgr;
    }

    const waitAnswer = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            setResolver({ resolve });
        });
    }

    const validateStep1 = async (item: ref_Agreements, mode: eDialogMode, notifyConfig: NotifyConfig) => {
        if (!item.Name || !item.AdvertiserGroup || !item.Support || !item.Currency || !item.Start) {
            return false;
        }
        // const exists = (await Client.searchVertex(ref_Agreements.name, { alreadyExist: { ...item, ["@rid"]: mode === eDialogMode.duplicate ? null : item["@rid"] } })).data.results as ref_Agreements[];
        // if (exists.length) {
        //     setExistingAgreement(exists[0]);
        //     setShowExist(true);
        //     const override = await waitAnswer();
        //     setShowExist(false);
        //     if (!override) {
        //         notifyConfig.message = null;
        //         return false;
        //     }
        // }

        return true;
    }

    return (<>
        <DefaultGrid
            filtersInToolbar
            filtersHideOptions={{ MapFilters: true }}
            filtersOrder={["Hierarchy", "Support"]}
            objectPrototype={ref_Agreements}
            grid={grid}
            defaultItem={defaultAgreement}
            dialogContent={[
                (item, mode) => <AgreementDialog agreement={item} mode={mode} />,
                (item, mode) => <AgreementDialog2 agreement={item} mode={mode} />
            ]}
            onDialogOpened={(item, mode) => {
                store.dispatch(setAgreementBeforeUpsert(item))
                store.dispatch(setAgreement(item))
            }}
            dialogProps={{ maxWidth: "lg" }}
            stepConfig={[
                {
                    submitTitle: Trad("see_messages"),
                    validation: validateStep1,
                    dialogProps: { maxWidth: "md" }
                }
            ]}
            submitDialog={async (item, mode) => {
                setName(item.Name);
                if (item?.Messages?.length && item?.["needConfirm"]) {
                    setShowConfig(true);
                    const confirm = await waitAnswer();
                    setShowConfig(false);
                    if (!confirm) {
                        throw new Error("notConfirmed");
                    }
                }
                return submitAgreement(item, mode);
            }}
            submitDelete={async (items) => {
                await Client.deleteVertex(ref_Agreements.name, items, true, importId ? { CacheInfos: { Key: importId, Type: "Import" } } : {});
            }}
            additionalBreadcrumb={importId ? [{ text: Trad("imports"), href: "/imports" }] : []}
            filtersParams={{ "Group": { "Category": eGroupCategories.Magasin } }}
        />
        <GenericDialog
            open={showExist}
            dialogTitle={`${Trad("attention")}`}
            actions
            dialogProps={{ maxWidth: "md", fullWidth: true }}
            dialogContainerStyle={{ overflow: "visible" }}
            dialogContent={<p>{TradComposed("overwrite_agreement", [existingAgreement?.Name !== name ? TradComposed("overwrite_agreement_area", [existingAgreement?.Name]) : TradComposed("overwrite_agreement_name", [existingAgreement?.Name])])}</p>}
            submitAction={() => resolver.resolve(true)}
            submitTitle={Trad("replace")}
            cancelAction={() => resolver.resolve(false)}
        />
        <GenericDialog
            open={showConfirm}
            dialogTitle={`${Trad("attention")}`}
            actions
            dialogProps={{ maxWidth: "md", fullWidth: true }}
            dialogContainerStyle={{ overflow: "visible" }}
            dialogContent={<p>{Trad("unfavorable_agreement")}</p>}
            submitAction={() => resolver.resolve(true)}
            submitTitle={Trad("yes")}
            cancelAction={() => resolver.resolve(false)}
        />
    </>);
};