
import * as React from 'react'
import { TextField } from '@material-ui/core';
import { Client } from 'hub-lib/client/client.bin';
import { Format } from 'format-lib/index.bin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AutocompleteProps, AutocompleteRenderGroupParams, AutocompleteRenderInputParams, AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Trad } from 'trad-lib';
import { IRid } from 'hub-lib/models/IRid.bin';

// import { TooltipManager } from '../../../../src/components/CustomTooltip';

const itemSize = 36;

export class TooltipManager {
    static Push({ target, text }) {

    }
}

export class VertexAutocompleteDecorator {

    private static decorators: ((option: any) => any)[] = [];

    static RegisterDecorator(decorator: (option: any) => any) {
        this.decorators.push(decorator);
    }

    static Decorate(option: any) {
        return <>{this.decorators.map(d => d(option))}</>
    }
}


import { getIcon } from "./IconManager";
import { GetHashCode, toArray, hasOwnProperty } from 'hub-lib/tools.bin';
import { AdwAutocomplete } from './AdwAutocomplete';

const LISTBOX_PADDING = 8; // px

export type TPropsBase = {

    autoCompleteProps?: Partial<AutocompleteProps<any, any, any, any>>

    key?: string | number;
    refreshOnPropChanged?: boolean;

    warning?: string;

    loading?: boolean;
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
    Active?: boolean;

    disabled?: boolean;
    enabledOnlyLoading?: boolean;
    /**
     * Params sent to Search
     */
    params?: any;
    needTrad?: any;

    /**
     * Vertex type, ex: "ref_Supports"
     */
    type?: string;

    label: string;
    customDisplay?: string;
    clearText?: string;
    disableClearable?: boolean;
    nullOnClear?: boolean;

    startAdornment?: React.ReactElement;
    multiple?: boolean;
}

export type TPropsVMulti<V extends IRid> = {

    multiple: true;

    sort?: (options: V[]) => V[];
    renderOption?: (option: V, state: AutocompleteRenderOptionState) => React.ReactNode;
    groupBy?: (option: V) => string;
    getOptionLabel?: (option: V) => string;

    onChange?: (values: V[]) => any;

    defaultValue?: (vertices: V[]) => V[] | Promise<V[]>;

    onResetValue?: (vertices: V[]) => V[];

    options?: V[];

    afterLoadFilter?: (options: V[]) => V[];
};


export type TPropsRidMulti = {

    multiple: true;

    sort?: (options: IRid[]) => IRid[];
    renderOption?: (option: IRid, state: AutocompleteRenderOptionState) => React.ReactNode;
    groupBy?: (option: IRid) => string;
    getOptionLabel?: (option: IRid) => string;

    onChange?: (value: IRid[]) => any;

    defaultValue?: (vertices: IRid[]) => IRid[] | Promise<IRid[]>;

    onResetValue?: (vertices: IRid[]) => IRid[];

    options?: IRid[];

    afterLoadFilter?: (options: IRid[]) => IRid[];
};

export type TPropsStrMulti = {

    multiple: true;

    sort?: (options: string[]) => string[];
    renderOption?: (option: string, state: AutocompleteRenderOptionState) => React.ReactNode;
    groupBy?: (option: string) => string;
    getOptionLabel?: (option: string) => string;

    onChange?: (value: string[]) => any;

    defaultValue?: (vertices: string[]) => string[] | Promise<String[]>;

    onResetValue?: (vertices: string[]) => string[];

    options?: string[];

    afterLoadFilter?: (options: string[]) => string[];

};

export type TPropsV<V> = {

    multiple?: false;

    sort?: (options: V[]) => V[];
    renderOption?: (option: V, state: AutocompleteRenderOptionState) => React.ReactNode;
    groupBy?: (option: V) => string;
    getOptionLabel?: (option: V) => string;

    onChange?: (value: V) => any;

    defaultValue?: (vertices: V[]) => V | Promise<V>;

    onResetValue?: (vertices: V[]) => V;

    options?: V[];

    afterLoadFilter?: (options: V[]) => V[];
};

export type TPropsRid = {

    multiple?: false;

    sort?: (options: IRid[]) => IRid[];
    renderOption?: (option: IRid, state: AutocompleteRenderOptionState) => React.ReactNode;
    groupBy?: (option: IRid) => string;
    getOptionLabel?: (option: IRid) => string;

    onChange?: (value: IRid) => any;

    defaultValue?: (vertices: IRid[]) => IRid | Promise<IRid>;

    onResetValue?: (vertices: IRid[]) => IRid;

    options?: IRid[];

    afterLoadFilter?: (options: IRid[]) => IRid[];
};

export type TPropsStr = {

    multiple?: false;

    sort?: (options: string[]) => string[];
    renderOption?: (option: string, state: AutocompleteRenderOptionState) => React.ReactNode;
    groupBy?: (option: string) => string;
    getOptionLabel?: (option: string) => string;

    onChange?: (value: string) => any;

    defaultValue?: (vertices: string[]) => string | Promise<string>;

    onResetValue?: (vertices: string[]) => string;

    options?: string[];

    afterLoadFilter?: (options: string[]) => string[];
};

export type TPropsVertexAuto<V> = TPropsBase & (TPropsVMulti<V> | TPropsV<V> | TPropsRid | TPropsRidMulti | TPropsStr | TPropsStrMulti);
export type TPropsRids<V> = TPropsBase & (TPropsVMulti<V> | TPropsV<V>);

type TStateBase = {
    loading: boolean;
    value: any;
}

type TStateRid<V extends IRid> = {
    vertices: IRid[] | string[];
}

type TState<V> = TStateBase & (TStateRid<V>)

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader style={{ height: itemSize }} key={params.key} component="div">
        <div style={{ height: itemSize }}>{params.group}</div>
    </ListSubheader>,
    params.children,
];

export class VertexAutocomplete<V> extends React.Component<TPropsVertexAuto<V>, any> {

    constructor(props: TPropsVertexAuto<V>) {
        super(props);
        this.state = {

        }
    }

    componentDidUpdate(prevProps: TPropsVertexAuto<V>) {
        if (this.props.refreshOnPropChanged) {
            const paramsSerialized = JSON.stringify(this.props.params ?? {});
            if (JSON.stringify(prevProps.params ?? {}) !== paramsSerialized) {
                this.setState({ key: GetHashCode(paramsSerialized) })
            }
        }
    }

    render() {
        const { key } = this.state;
        return <VertexAutocompleteComponent {...(key && { key })} {...this.props as any} />
    }
}

export class VertexAutocompleteComponent<V> extends React.Component<TPropsVertexAuto<V>, TState<V>> {

    mounted = false;
    constructor(props: TPropsVertexAuto<V>) {
        super(props);
    }

    async componentDidMount() {
        this.mounted = true;
        let state = this.state;
        if (!state || (!this.props.options && this.props.type)) {
            if (!state) {
                const newState: TState<V> = {
                    loading: true,
                    vertices: [],
                    value: undefined
                }

                if (this.props.options) {
                    newState.vertices = this.props.options;
                    newState.loading = false;
                    newState.value = await this.props.defaultValue?.(newState.vertices as any);
                }
                state = newState;
            }

            if (!this.props.options && this.props.type) {
                let params = { properties: ["@rid", "Name", "@class"], ...this.props.params };
                if (this.props.Active) params.Active = true;
                let vertices: any[] = [];
                if (!this.props.enabledOnlyLoading || !this.props.disabled) {
                    vertices = (await Client.searchVertex(this.props.type, params, false))?.data?.results ?? [];
                    vertices = vertices.filter(vertice => vertice.MasterEndpoint !== null);
                    //ConsoleDebug("load data ", this.props.type, ",count=", vertices.length);
                }
                if (this.props.afterLoadFilter) {
                    vertices = this.props.afterLoadFilter(vertices);
                }
                const value = await this.props.defaultValue?.(vertices);
                state = { ...state, vertices, loading: false, value }
            }

            if (this.mounted)
                this.setState(state);
        }
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    getOptionLabelCallBack = () => {
        const getTradOption = (option: IRid | string) => Trad(Format(option));
        const getValueOption = (option: IRid | string) => option[this.props.customDisplay];
        const formatOption = (option: IRid | string) => Format(option);
        const noTrad = this.props.customDisplay ? getValueOption : formatOption;
        const getTrad = this.props.needTrad ? getTradOption : noTrad;
        return this.props.getOptionLabel ?? getTrad;
    }

    render() {
        const { refreshOnPropChanged, params } = this.props;
        const { vertices, value } = this.state ?? { vertices: [] };
        const loading = this.props.loading || !this.state || this.state?.loading;

        const { renderInput, renderOption, groupBy, sort } = this.props;
        const warning = this.props.warning ?? value?.['warning'];
        let options = (vertices as any[])?.filter((v: any) => {
            if (Array.isArray(value))
                return !value.includes(v);
            return value != v;
        });

        if (options && sort) {
            options = sort(options);
        }

        function renderRow(props: ListChildComponentProps) {
            const { data, index, style } = props;
            return React.cloneElement(data[index], {
                children: <>{data[index].props.children}</>,
                onMouseOver: (e) => {
                    const children = data[index].props.children;
                    // if (typeof children === "string")
                    //     TooltipManager.Push({
                    //         target: e.target,
                    //         text: children
                    //     })
                },
                className: data[index].props.className + " vertexauto-option",
                style: {
                    ...style,
                    top: (style.top as number) + LISTBOX_PADDING,
                },
            });
        }

        const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
            const { children, ...other } = props;
            const itemData = React.Children.toArray(children);
            const theme = useTheme();
            const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
            const itemCount = itemData.length;
            // const itemSize = smUp ? 36 : 48;

            const getChildSize = (child: React.ReactNode) => {
                // if (React.isValidElement(child) && child.type === ListSubheader) {
                //     return 48;
                // }

                return itemSize;
            };

            const getHeight = () => {
                if (itemCount > 10) {
                    return 10 * itemSize;
                }
                return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
            };

            const gridRef = useResetCache(itemCount);

            return (
                <div ref={ref}>
                    <OuterElementContext.Provider value={other}>
                        <VariableSizeList
                            itemData={itemData}
                            height={getHeight() + 2 * LISTBOX_PADDING}
                            width="100%"
                            ref={gridRef}
                            outerElementType={OuterElementType}
                            innerElementType="ul"
                            itemSize={(index) => getChildSize(itemData[index])}
                            overscanCount={5}
                            itemCount={itemCount}
                        >
                            {renderRow}
                        </VariableSizeList>
                    </OuterElementContext.Provider>
                </div>
            );
        });

        return (
            <div style={{ position: "relative" }}>
                {warning && <div
                    className='warning-message'
                    onMouseOver={e => TooltipManager.Push({ target: e.target, text: `ID: ${toArray(value)?.[0]?.['@rid']}` })}>
                    {getIcon("warning")}<span>{typeof warning == 'string' ? warning : ''}</span>
                </div>}
                <AdwAutocomplete
                    multiple={this.props.multiple}
                    disabled={(options?.length === 0 && !value) || loading || this.props.disabled}
                    key={`AdwAutocomplete-${this.props.type}-${loading}-${value === undefined}`}
                    //defaultValue={this.props.defaultValue?.(vertices)}
                    options={options}
                    //open={true}
                    clearText={this.props.clearText}
                    disableClearable={this.props.disableClearable}
                    renderGroup={renderGroup}
                    ListboxComponent={ListboxComponent as any}
                    getOptionLabel={this.getOptionLabelCallBack()}
                    {...(groupBy && { groupBy })}
                    {...(renderOption && { renderOption } as any)}
                    {...(!renderOption && {
                        renderOption: (option: any) => {
                            const text = this.getOptionLabelCallBack()(option);
                            const decorator = VertexAutocompleteDecorator.Decorate(option);
                            return <>
                                {decorator}
                                <div
                                    className="vertexauto-option"
                                    onMouseOver={(e) =>
                                        TooltipManager.Push({ target: e.target, text })}>
                                    <span>
                                        {text}
                                    </span>
                                </div></>
                        }
                    })}
                    value={value}

                    /*renderInput={(params) => <TextField {...params} label={this.props.label} variant="outlined" />}*/

                    renderInput={renderInput ?? ((params: AutocompleteRenderInputParams) => (
                        <TextField
                            {...params}
                            label={this.props.label}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                ...(this.props.startAdornment ? { startAdornment: this.props.startAdornment } : {}),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    ))}

                    onChange={(event, v: any) => {

                        if (!this.props.disableClearable && v === null) {
                            if (this.props.onResetValue) v = this.props.onResetValue((vertices as any));
                            else v = (this.props.nullOnClear ? null : (vertices?.[0] ?? []));
                        }

                        this.setState({ value: v });
                        this.props.onChange?.(v);
                    }}
                    {...(this.props.autoCompleteProps ?? {})}
                />
            </div>
        )
    }
}


