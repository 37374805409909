import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { clone, compareObjects, propertyOf } from "hub-lib/tools.bin";
import * as React from "react";
import { connect } from "react-redux";
import { Trad } from "trad-lib";
import { RootState } from "../../../../redux/store";
import { CellNumberComponent } from "../discounts/CellNumberComponent.bin";
import { CellPercentComponent } from "../discounts/CellPercentComponent.bin";
import { colWidth, removeCol } from "./DiscountEditor.bin";
import { LockedPricing } from "./LockedPricing";

export class BarterGridProps {
    base: number = 0;
    classes: ref_DiscountClasses[];
    discounts: ref_Discount[];
    onChange?: (percents: ref_Discount[]) => void;
}

class BarterGridComponent extends React.Component<BarterGridProps, any> {

    computeItems = () => {
        if (this.props.discounts) {
            const copy = clone(this.props.discounts)
            for (const d of this.props.discounts) {
                if (d.IsRate)
                    d.Value = d.Rate * this.props.base;
                else {
                    if (this.props.base != 0)
                        d.Rate = d.Value / this.props.base;
                    else
                        d.Rate = 0;
                }
            }
            if (onchange && !compareObjects(copy, this.props.discounts)) {
                this.props?.onChange(this.props.discounts);
            }
        }
    }

    itemChange = (event) => {
        let elmnt = this.props.discounts.find(d => d.DiscountClass === event.dataItem.DiscountClass);
        if (elmnt && event.field == "Rate") {
            elmnt[event.field] = event.value;
            let other = this.props.discounts.find(d => d.DiscountClass !== event.dataItem.DiscountClass);
            other[event.field] = 1 - event.value;
            elmnt.IsRate = true;
            other.IsRate = true;
        }

        if (elmnt && event.field == "Value") {
            elmnt[event.field] = event.value;
            let other = this.props.discounts.find(d => d.DiscountClass !== event.dataItem.DiscountClass);
            other[event.field] = this.props.base - elmnt[event.field];
            elmnt.IsRate = false;
            other.IsRate = false;
        }

        this.computeItems();
        this.forceUpdate();
    }

    cellPercent = (props: GridCellProps) => <CellPercentComponent {...props} editable={true} ptr={null} itemChange={this.itemChange} />;
    cellNumberPrice = (props: any) => <CellNumberComponent {...props} ptr={null} editable={true} opt={{ type: ePropType.Double, min: 0, max: this.props.base }} currency={null} itemChange={this.itemChange} />;

    render() {
        if (!this.props.base)
            return <></>;
        this.computeItems();
        return <div className="discount-editor-array-container barter-container">
            <LockedPricing>
                <Grid
                    className={"DiscountCategoryArray"}
                    data={this.props.discounts}>
                    <GridColumn cell={(props: GridCellProps) => {
                        const c = this.props.classes.find(c => c["@rid"] == (props.dataItem as ref_Discount).DiscountClass);
                        return <td>{Trad(c?.Name)}</td>
                    }} />
                    <GridColumn field={propertyOf<ref_Discount>('Rate')} width={colWidth} editor="numeric" cell={this.cellPercent} />
                    <GridColumn field={propertyOf<ref_Discount>('Value')} width={colWidth} editor="numeric" cell={this.cellNumberPrice} />
                    <GridColumn width={removeCol * 2} cell={(props: GridCellProps) => { return <td></td> }} />
                </Grid>
            </LockedPricing>
        </div>
    }
}

const mapStateToProps = (state: RootState) => ({
    base: state.messageEditor.data?.KPIs?.Barter
})

export const BarterGrid = connect(
    mapStateToProps
)(BarterGridComponent)